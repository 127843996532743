/** @format */

import React from 'react';
import { Listbox } from '@headlessui/react';
import {
  Archive,
  Copy,
  type LucideIcon,
  EllipsisVertical,
  BookType,
} from 'lucide-react';
import classNames from 'classnames';
import Panel from '../../panel/Panel';

interface Action {
  id: string;
  label: string;
  icon: LucideIcon;
  onItemSelect: () => void;
  hasDivider?: boolean;
}

interface StoryboardOptionsMenuProps {
  onArchiveStoryboard: () => void;
  onDuplicateStoryboard: () => void;
  onWatchTutorial: () => void;
  onFormatting: () => void;
}

export const StoryboardOptionsMenu: React.FC<StoryboardOptionsMenuProps> = ({
  onArchiveStoryboard,
  onDuplicateStoryboard,
  onWatchTutorial,
  onFormatting,
}) => {
  const options: Action[] = React.useMemo(
    () => [
      {
        id: 'archive',
        label: 'Archive',
        icon: Archive,
        onItemSelect: onArchiveStoryboard,
      },
      {
        id: 'duplicate',
        label: 'Duplicate',
        icon: Copy,
        onItemSelect: onDuplicateStoryboard,
        hasDivider: true,
      },
      // {
      //   id: 'tutorial',
      //   label: 'Tutorial',
      //   icon: LifeBuoy,
      //   onItemSelect: onWatchTutorial,
      // },
      {
        id: 'formatting',
        label: 'Formatting',
        icon: BookType,
        onItemSelect: onFormatting,
      },
    ],
    [onArchiveStoryboard, onDuplicateStoryboard, onWatchTutorial, onFormatting],
  );

  const handleChange = (action: Action) => {
    action.onItemSelect();
  };

  return (
    <Listbox
      as="div"
      className="relative group/storyboardoptions"
      value={null}
      onChange={handleChange}
    >
      {() => (
        <>
          <Listbox.Button className="flex items-center justify-center flex-shrink-0 w-8 h-8 outline-none cursor-pointer rounded-md ring-none hover:bg-surface">
            <EllipsisVertical className="w-4 h-4" />
          </Listbox.Button>
          <Listbox.Options
            as={Panel}
            className="absolute right-0 flex-col w-40 mt-2 border shadow-md rounded-md border-border top-full focus:outline-none"
          >
            <div className="p-2 space-y-1">
              {options.map((option) => (
                <React.Fragment key={option.id}>
                  <Listbox.Option
                    value={option}
                    as="div"
                    className={({ active }) =>
                      classNames(
                        'cursor-pointer select-none relative pb-2 pt-1.5 px-2 rounded-md flex items-center space-x-2',
                        active ? 'bg-surface-light' : '',
                      )
                    }
                  >
                    {({ active }) => (
                      <>
                        <option.icon className="h-4 w-4 pt-0.5" />
                        <span className="text-sm">{option.label}</span>
                      </>
                    )}
                  </Listbox.Option>
                  {option.hasDivider && (
                    <div className="my-3 border-b border-border" />
                  )}
                </React.Fragment>
              ))}
            </div>
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
};
