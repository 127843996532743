/** @format */
const PlayerUI = require('./Player.jsx');
const Container = require('./Container2.jsx');

module.exports = Container(PlayerUI, [
  'adjustFrameInpoint',
  'setFrameInpoint',
  'setFrameOutpoint',
  'setTimingsLock',
  'toggleLooping',
  'togglePlay',
  'batchTimingChange',
  'toggleTimeline',
  'commitFrameTimings',
  'updateTime',
  'close',
]);
