/** @format */
import * as React from 'react';
import { MessagesSquare } from 'lucide-react';
import classNames from 'classnames';

interface Props {
  count: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  tabIndex?: number;
  allowZero?: boolean;
  className?: string;
}

export const FrameCommentCount = React.memo<Props>(
  ({ className, allowZero, count, onClick, ...buttonProps }) =>
    count > 0 || allowZero ? (
      <div
        onClick={(e) => onClick}
        className={classNames(
          'flex items-center pl-2 pr-2.5 py-0.5 text-white bg-black/80 rounded-md',
          className,
        )}
      >
        <MessagesSquare className="w-3.5 h-3.5 opacity-60" />
        <div className="text-sm pb-0.5 flex-auto ml-2">{count}</div>
      </div>
    ) : null,
);
FrameCommentCount.displayName = 'FrameCommentCount';
