/** @prettier */
import { type Placement } from '@popperjs/core';
import { type LucideIcon } from 'lucide-react';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import classNames from 'classnames';
import * as React from 'react';

interface FrameButtonContentProps {
  text?: string;
  icon: LucideIcon;
  buttonClassName?: string;
  disabled?: boolean;
  placement?: Placement;
}

export const FrameButtonContent: React.FC<FrameButtonContentProps> = (
  props,
) => {
  const {
    buttonClassName,
    text,
    disabled = false,
    placement = 'bottom',
    icon: Icon,
  } = props;

  const content = (
    <div
      className={classNames(
        buttonClassName,
        'flex items-center justify-center flex-shrink-0 w-7 h-7 bg-white outline-none rounded-md ring-none bg-opacity-80 transition-opacity',
        {
          'cursor-pointer hover:bg-opacity-100': !disabled,
          'cursor-not-allowed opacity-50': disabled,
        },
      )}
    >
      <Icon className={classNames('w-4 h-4')} />
    </div>
  );

  if (text) {
    return (
      <Tooltip title={text} placement={placement}>
        {content}
      </Tooltip>
    );
  }

  return content;
};
