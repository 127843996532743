import React from 'react';
import { type LucideIcon, ChevronRight } from 'lucide-react';
import classNames from 'classnames';
// Import the ArrowRight and Icon components
// Note: Update these imports based on your project structure
import Icon from 'blackbird/components/icon/Icon';

const fontSizes = {
  medium: 'f4',
  larger: 'f4',
  small: 'f5',
} as const;

type FontSizeKey = keyof typeof fontSizes;

interface OverlayListItemProps {
  onClick?: (e: React.MouseEvent) => void;
  showArrow?: boolean;
  showPopupIndicator?: boolean;
  text: string;
  description?: string;
  isActive?: boolean;
  showBorder: boolean;
  height?: string;
  href?: string;
  className?: string;
  value?: string;
  padding?: string;
  size?: FontSizeKey;
  center?: boolean;
  newWindow?: boolean;
  icon?: React.ReactNode;
  lucideIcon?: LucideIcon;
  onMouseEnter?: (e: React.MouseEvent) => void;
  animateIconOpacity?: boolean;
  disabled?: boolean;
}

const NewTabIcon: React.FC = () => {
  return <></>;
};

export const OverlayListItemNew: React.FC<OverlayListItemProps> = (props) => {
  const {
    showArrow = true,
    height = 'h3',
    padding = 'pa2',
    size = 'medium',
    center = false,
    className = '',
    animateIconOpacity = false,
    onClick,
    showPopupIndicator,
    text,
    description,
    isActive,
    showBorder,
    href,
    value,
    newWindow,
    icon,
    lucideIcon,
    onMouseEnter,
    disabled,
  } = props;

  const fontSize = fontSizes[size];
  const isInteractive = Boolean(onClick || href);

  const handleClick = (e: React.MouseEvent) => {
    if (!isInteractive) return;
    e.stopPropagation();
    onClick?.(e);
  };

  let Tag: 'button' | 'a' | 'div' = 'div';
  let itemProps: React.HTMLAttributes<HTMLElement> & {
    type?: 'button';
    disabled?: boolean;
    href?: string;
    target?: string;
    value?: string;
  } = {};

  if (disabled) {
    Tag = 'div';
    itemProps = {};
  } else if (href) {
    Tag = 'a';
    if (newWindow) {
      itemProps = {
        href,
        target: '_blank',
      };
    } else {
      itemProps = { href };
    }
  } else if (onClick) {
    Tag = 'button';
    itemProps = {
      onClick: handleClick,
      type: 'button',
      value,
    };
  }

  return (
    <Tag
      {...itemProps}
      className={classNames(
        'focus:outline-none flex items-center text-left w-full hover:bg-surface-light rounded-md pl-3 pb-2 pt-1.5',
        padding,
        fontSize,
        {
          'b--light-grey bb bw1': showBorder,
          'bg-surface-light': isActive,
          tc: center,
          'o-undim': animateIconOpacity,
          'text-type-disabled': disabled,
          'cursor-pointer': !disabled,
        },
        className,
      )}
      onMouseEnter={onMouseEnter}
    >
      {props.lucideIcon ? (
        <div className="mr-3">
          <props.lucideIcon className="w-4 h-4" />
        </div>
      ) : icon ? (
        <div className="mr-2 -ml-1">
          <div className="scale-75">
            <Icon
              icon={icon as any}
              className={classNames(
                'h-6 w-auto',
                disabled && 'opacity-50',
              )}
            />
          </div>
        </div>
      ) : null}
      <div className="flex-grow overflow-hidden pb-0.5">
        <div className="text-sm truncate">{text}</div>
        {description && (
          <div className="text-sm text-type-subdued">
            {description}
          </div>
        )}
      </div>
      {showArrow && isInteractive ? (
        <div className="pr-2">
          <ChevronRight className="w-4 h-4" />
        </div>
      ) : showPopupIndicator ? (
        <NewTabIcon />
      ) : null}
    </Tag>
  );
};
