/** @prettier */
import { IconButton } from 'blackbird/components/common/IconButton';
import classNames from 'classnames';
import * as React from 'react';
import ChevronIcon from 'blackbird/images/icons/arrow.svg';
import BackIcon from 'blackbird/images/icons/back.svg';
import { PopoverContext } from 'blackbird/components/common/popover/Popover';
import { first, isUndefined, map } from 'underscore';
import { ChevronLeft } from 'lucide-react';

const PropTypes = require('prop-types');

/** The props that will be passed along to every panel's Component */
export interface IPanelProps {
  goToPanelF: (newPanel: string) => () => void;
  goToPanel: (newPanel: string) => void;
  /** Triggers a re-measure of the content, and will animate height to fit */
  updateHeight: () => void;
  isActivePanel: boolean;
  storyboardId?: number;
  storyboardOwnerId?: number;
}

/** The result of the props parameter in a panel's configuration  */
interface IPanelConfigProps {
  [propName: string]: any;
  /** Padding for this specific pane */
  innerPadding?: string;
  /** The title style for this specific pane */
  titleStyle?: string;
}
interface IPanelDefinition {
  Component: any;
  /* Will add a title to the top of the panel */
  title?: string;
  props?: IPanelConfigProps | (() => IPanelConfigProps);
}

type stackType = string[];

interface IProps {
  panels: { [panelName: string]: IPanelDefinition };
  defaultPanel: string;
  innerPadding?: string;
  titleStyle?: string;
  width: number;
  useChevron?: boolean;
}

interface IState {
  panel: string;
  stack: stackType;
  height: number;
  shouldAnimate: boolean;
  currentIndex: number;
}

export class MultiPanel extends React.PureComponent<IProps, IState> {
  static propTypes = {
    panels: PropTypes.objectOf(
      PropTypes.shape({
        // Somehow, Function components using Memo aren't supported?
        // Component: PropTypes.element.isRequired,
        Component: PropTypes.any.isRequired,
        title: PropTypes.string,
        props: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      }),
    ),
    defaultPanel: PropTypes.string.isRequired,
    innerPadding: PropTypes.string,
    titleStyle: PropTypes.string,
    width: PropTypes.number.isRequired,
  };

  static defaultProps = {
    innerPadding: 'p-3',
    titleStyle: '',
    width: 400,
  };

  panelsRef: React.RefObject<HTMLDivElement>;
  static contextType = PopoverContext;

  constructor(props) {
    super(props);
    this.panelsRef = React.createRef();

    const stack: stackType = [];
    const panelKeys = Object.keys(this.props.panels);
    // Preparing a stack in case the defaultPanel is not the first one
    if (this.props.defaultPanel !== first(panelKeys)) {
      let finished;
      panelKeys.forEach((panelName) => {
        if (finished) return;

        if (panelName !== this.props.defaultPanel) {
          stack.push(panelName);
        } else {
          finished = true;
        }
      });
    }

    stack.push(this.props.defaultPanel);
    const stackIndex = stack.length - 1;

    this.state = {
      panel: this.props.defaultPanel,
      stack: stack,
      height: 0,
      shouldAnimate: false,
      currentIndex: stackIndex,
    };
  }

  goToPanel = (panelName) => {
    const newStack = this.state.stack
      .concat()
      .slice(0, this.state.currentIndex + 1)
      .concat(panelName);

    const newIndex = newStack.length - 1;

    this.setState({
      currentIndex: newIndex,
      panel: panelName,
      stack: newStack,
      shouldAnimate: true,
    });
  };

  // A factory function, generates a event handler for navigating to a panel
  goToPanelF = (panelName) => {
    if (!this.props.panels[panelName])
      throw new Error(
        `Panel with name "${panelName}" not found in ${Object.keys(
          this.props.panels,
        )}`,
      );

    return (e) => {
      e.preventDefault();
      this.goToPanel(panelName);
    };
  };
  componentDidMount() {
    this.setPanelHeight();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.panel !== prevState.panel) this.setPanelHeight();
  }

  setPanelHeight = () => {
    const oldHeight = this.state.height;
    const height =
      this.panelsRef.current!.children[this.state.currentIndex].clientHeight;

    this.setState({ height }, () => {
      // If we're in a popover, ask the position to update
      if (this.context && height !== oldHeight) {
        this.context?.update?.();
      }
    });
  };
  goBack = (e) => {
    e.stopPropagation();
    const currentIndex = Math.max(0, this.state.currentIndex - 1);

    this.setState({
      currentIndex,
      panel: this.state.stack[currentIndex],
    });
  };

  render() {
    const stack = this.state.stack;
    const currentIndex = this.state.currentIndex;
    const panelWidth = this.props.width;
    const useChevron = this.props.useChevron;
    const panelsStyles = {
      width: stack.length * this.props.width,
      transform: `translateX(-${panelWidth * currentIndex}px)`,
    };

    return (
      <div
        className={classNames(
          'overflow-hidden bg-white rounded-lg shadow-lg',
          this.state.shouldAnimate &&
            'transition-height duration-[250ms] ease-in-out',
        )}
        style={{ width: panelWidth, height: this.state.height }}
      >
        {currentIndex !== 0 && (
          <div
            className="transition-opacity duration-[250ms] ease-in-out w-4 h-4 absolute top-3.5 left-3 z-10 cursor-pointer"
            onClick={this.goBack}
          >
            <ChevronLeft className="w-4 h-4" />
          </div>
        )}

        <div className="w-full whitespace-nowrap">
          <div
            className="flex items-start duration-[250ms] transition-transform ease-in-out"
            style={panelsStyles}
            ref={this.panelsRef}
          >
            {map(stack, (panelName, i) => {
              const panel = this.props.panels[panelName];
              const props =
                typeof panel.props === 'function'
                  ? panel.props()
                  : panel.props || {};

              const innerPadding = isUndefined(props.innerPadding)
                ? this.props.innerPadding
                : props.innerPadding;

              return (
                <div
                  key={panelName + i}
                  className={classNames('whitespace-normal', {
                    'h-5': panelName !== this.state.panel,
                  })}
                  style={{ width: panelWidth, minHeight: 20 }}
                >
                  {panel.title ? (
                    <div
                      className={`border-b border-b-border bg-white font-semibold text-center pb-4 pt-3.5 leading-none ${
                        props.titleStyle || this.props.titleStyle
                      }`}
                    >
                      {panel.title}
                    </div>
                  ) : null}

                  <div className={classNames(innerPadding, 'relative')}>
                    <panel.Component
                      {...props}
                      goToPanelF={this.goToPanelF}
                      goToPanel={this.goToPanel}
                      updateHeight={this.setPanelHeight}
                      isActivePanel={panelName === this.state.panel}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
