/** @format */

import React from 'react';
import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from 'lucide-react';
import classNames from 'classnames';
import { TeamContext, type TeamRole } from './TeamContext';
import { TeamUpgradePill } from './TeamUpgradePill';

const invitableRoles: TeamRole[] = ['member', 'supermember', 'manager'];

const roleInfo: Record<TeamRole, { title: string; description: string }> = {
  member: {
    title: 'Restricted',
    description: 'Can view and edit assigned projects',
  },
  supermember: {
    title: 'Full access',
    description: 'Can view and edit all projects',
  },
  manager: {
    title: 'Admin',
    description: 'Can manage team and projects',
  },
  admin: {
    title: 'Owner',
    description: 'Full control over team and all projects',
  },
  pending: {
    title: 'Pending',
    description: 'Waiting for account activation',
  },
  invited: {
    title: 'Invited',
    description: 'Invitation sent, not yet accepted',
  },
  archived: {
    title: 'Archived',
    description: 'Former team member, no longer active',
  },
};

const buttonClasses = `inline-flex items-center justify-center w-full py-1 pl-3 text-xs rounded-full text-type-subdued bg-surface focus:outline-none`;

interface TeamRoleStaticProps {
  text: string;
}

export const TeamRoleStatic: React.FC<TeamRoleStaticProps> = ({ text }) => {
  return (
    <span className={classNames(buttonClasses, 'pr-3 cursor-default')}>
      <span className="pb-0.5">{text}</span>
    </span>
  );
};

interface TeamRoleSelectProps {
  value: TeamRole;
  onChange: (role: TeamRole) => void;
  position?: 'top' | 'bottom';
}

export const TeamRoleSelect: React.FC<TeamRoleSelectProps> = ({
  value,
  onChange,
  position = 'top',
}) => {
  const { teamData } = React.useContext(TeamContext);

  const isRoleDisabled = (role: TeamRole) => {
    return role === 'member' && !teamData?.has_role_based_access;
  };

  const optionsPositionClasses =
    position === 'top'
      ? 'bottom-full mb-2 origin-bottom-right'
      : 'top-full mt-2 origin-top-right';

  return (
    <Listbox value={value} onChange={onChange}>
      <div className="relative">
        <div
          className={classNames(
            'absolute right-0 z-40 w-64',
            optionsPositionClasses,
          )}
        >
          <Listbox.Options className="bg-white border shadow-lg rounded-md focus:outline-none border-border">
            <div className="p-2 space-y-1">
              {invitableRoles.map((role) => (
                <Listbox.Option
                  key={role}
                  value={role}
                  disabled={isRoleDisabled(role)}
                  className={({ active }) =>
                    classNames(
                      'relative p-2 rounded-md',
                      active ? 'bg-surface-light' : '',
                      'cursor-pointer',
                    )
                  }
                >
                  {({ selected, disabled }) => (
                    <div className="flex flex-col">
                      <div
                        className={classNames(
                          'flex items-center',
                          disabled && 'opacity-50',
                        )}
                      >
                        <span className="flex-auto text-sm">
                          {roleInfo[role].title}
                        </span>
                        {selected && (
                          <CheckIcon className="flex-shrink-0 w-4 h-4" />
                        )}
                      </div>
                      <span
                        className={classNames(
                          'text-xs text-type-subdued',
                          disabled && 'opacity-50',
                        )}
                      >
                        {roleInfo[role].description}
                      </span>
                      {disabled && (
                        <div className="mt-1">
                          <TeamUpgradePill />
                        </div>
                      )}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </div>
          </Listbox.Options>
        </div>
        <Listbox.Button
          className={classNames(
            buttonClasses,
            'pr-2 hover:bg-surface-add_frame',
          )}
        >
          <span className="pb-0.5">{roleInfo[value].title}</span>
          <ChevronDownIcon className="w-3 h-3 ml-1" />
        </Listbox.Button>
      </div>
    </Listbox>
  );
};
