/**@prettier */
/* eslint indent: 0 */

import { GeneratorCharacterProvider } from 'blackbird/components/generator/guidelines/GeneratorCharacterContext';
import { WizardProvider } from 'blackbird/components/wizard/WizardContext';
import { posthogClient } from 'javascripts/helpers/posthog.ts';
import { PostHogProvider } from 'posthog-js/react';
import { FlyoverInlinePricing } from './FlyoverInlinePricing';
import { ComponentComposer } from '../shared/ComponentComposer';
import * as memoize from 'memoizee';
import { VideoImportFlyover } from '../videoImport/videoImportFlyover';
import { InpaintProvider } from 'blackbird/components/inpaint/InpaintContext';

const {
  default: DialogContextProvider,
} = require('blackbird/components/dialog/DialogContext');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const FrameFocus = require('./FrameFocusFlyover');
const { Cancelation } = require('../payment/Cancelation');
const navigateToRoute = require('../../helpers/router/navigate-to-route');
const { ConnectedEditProjectAccess } = require('../settings/EditProjectAccess');
const {
  StoryboardShareFlyover,
} = require('../storyboard/ShareFlyover/StoryboardShareFlyover');
const { BoordsDndProvider } = require('../shared/BoordsDndProvider');
const {
  StoryboardExportRolloutGate,
} = require('../storyboard_export/StoryboardExportRolloutGate');
const { FrameLimitFlyover } = require('../frames/FrameLimitFlyover');
const { UserPermissionFlyover } = require('../flyover/UserPermissionFlyover');
const {
  PresentationExportFlyover,
} = require('../storyboard/PresentationExport/PresentationExportFlyover.tsx');
const { FlyoverContext } = require('./flyoverContext');
const {
  DefaultStoreContextProvider,
} = require('../../flux/DefaultStoreContextProvider');
const { focusModeLocalState } = require('../../helpers/local-state');
const {
  BoordsAiProvider,
} = require('blackbird/helpers/contexts/BoordsAiContext');
const {
  BillingProvider,
} = require('blackbird/helpers/contexts/BillingContext');
const {
  default: PaymentCheckoutContainer,
} = require('../payment/PaymentCheckoutContainer');
const {
  ArchivedContainer,
} = require('javascripts/components/archive/ArchivedContainer');
const {
  GeneratorProvider,
} = require('blackbird/components/generator/GeneratorContext');

const ToursDebug = React.lazy(() => import('../tours/ToursDebug'));

const flyoversWithRoute = BoordsConfig.HasV3
  ? ['newProject']
  : ['newStoryboard', 'newProject'];

window.FlyoverRouter = createReactClass({
  propTypes: {
    type: PropTypes.string,
  },

  getInitialState: function () {
    return {
      open: FlyoverStore.getState().open,
    };
  },

  componentDidMount: function () {
    FlyoverStore.listen(this._onFlyoverChange);
    if (this.props.type) {
      FlyoverActions.open.defer({
        type: this.props.type,
      });
      this._animateIn();
    }
  },

  componentWillUnmount: function () {
    FlyoverStore.unlisten(this._onFlyoverChange);
  },

  bodyRef: React.createRef(),

  _speed: 0.125,
  _distance: 50,

  _onFlyoverChange: function (state) {
    // If flyover is opening, close the sidebar
    if (state.open && !this.state.open) {
      // PanelbarActions.close.defer();
      this._animateIn();
    } else if (this.state.open && !state.open) {
      this._animateOut();
    }
    // If flyover already open and another one needs to open,
    // close current one and open new one
    var newFlyoverType = state.type !== this.state.type;
    var newFrame =
      this.state.frame && state.frame
        ? state.frame.id !== this.state.frame.id
        : false;
    var alreadyOpen = state.open && this.state.open;
    if ((alreadyOpen && newFlyoverType) || (alreadyOpen && newFrame)) {
      this._animateToNext(state);
      return false;
    }
    this.setState(state);

    const hasRoute = flyoversWithRoute.includes(state.type);
    if (newFlyoverType && hasRoute) {
      navigateToRoute(state.type);
    }
  },

  _animateToNext: function (nextState) {
    var self = this;
    var flyoverWindows = ReactDOM.findDOMNode(this.refs.windows);

    TweenLite.to(flyoverWindows, this._speed, {
      y: this._distance,
      opacity: 0,
      onComplete: function () {
        self.setState(
          {
            type: '',
            projectId: nextState.projectId,
            frame: nextState.frame,
          },
          function () {
            self.setState(nextState, self._animateWindowsIn);
          },
        );
      },
    });
  },

  _animateWindowsIn: function () {
    var flyoverWindows = ReactDOM.findDOMNode(this.refs.windows);
    TweenLite.fromTo(
      flyoverWindows,
      this._speed,
      { y: this._distance, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        onComplete: function () {
          flyoverWindows.style.transform = '';
        },
      },
    );
  },

  _animateIn: function () {
    if (this.state.type === 'frameEditor' || this.state.type === 'player') {
      this._animateFrameFocusIn();
      return;
    }

    window.requestAnimationFrame(() => {
      var self = this;
      document.body.classList.add('locked');
      document.body.classList.add('has--flyover');
      var flyoverBg = ReactDOM.findDOMNode(this);
      TweenLite.fromTo(
        flyoverBg,
        this._speed,
        { opacity: 0 },
        {
          opacity: 1,
          onComplete: self._animateWindowsIn,
        },
      );
    });
  },

  _animateOut: function () {
    var flyoverWindows = ReactDOM.findDOMNode(this.refs.windows);
    var flyoverBg = ReactDOM.findDOMNode(this);
    TweenLite.to([flyoverBg], this._speed, {
      opacity: 0,
      onComplete: function () {
        document.body.classList.remove('locked');
        document.body.classList.remove('has--flyover');
        flyoverWindows.style.transform = 'translateY(100%)';
      },
    });
  },

  _animateFrameFocusIn() {
    var flyoverBg = ReactDOM.findDOMNode(this);
    var flyoverWindows = ReactDOM.findDOMNode(this.refs.windows);

    window.requestAnimationFrame(() => {
      document.body.classList.add('locked');
      document.body.classList.add('has--flyover');
      flyoverWindows.transform = null;

      TweenLite.fromTo(
        flyoverBg,
        this._speed,
        { ease: TweenLite.Quad, y: this._distance, opacity: 1 },
        {
          y: 0,
          opacity: 1,
        },
      );
    });
  },

  render: function () {
    var containerClass = ' items-center ',
      backgroundClass = ' bg-marl-gray ',
      innerClass = 'pa3 pa4-ns overflow-y-auto ',
      flyoverContent;

    containerClass += this.state.open ? ' flex z-flyover' : ' flex z-[-1]';

    switch (this.state.type) {
      case 'archivedItems':
        flyoverContent = <ArchivedContainer />;
        backgroundClass = ' bg-translucent ';
        break;
      case 'archiveUpgradePrompt':
        backgroundClass = ' bg-translucent ';
        flyoverContent = <ArchiveUpgradePrompt {...this.state.props} />;
        break;
      case 'confirm':
      case 'checkout':
        flyoverContent = (
          <PaymentCheckoutContainer
            plan_name={this.state.plan_name}
            interval={this.state.props.interval}
          />
        );
        break;
      case 'newProject':
        backgroundClass = ' bg-translucent ';
        flyoverContent = (
          <NewProjectFormContainer team_id={this.state.teamId} />
        );
        break;
      case 'aiScript':
        flyoverContent = (
          <FlyoverAiScriptGenerator storyboard={this.state.storyboard} />
        );
        break;
      case 'moveStoryboard':
        backgroundClass = ' bg-translucent ';
        flyoverContent = (
          <MoveStoryboardFormContainer storyboard={this.state.storyboard} />
        );
        break;
      case 'newStoryboard':
        backgroundClass = ' bg-translucent ';
        flyoverContent = (
          <NewStoryboardFormContainer
            projectId={this.state.projectId}
            teamId={this.state.teamId}
            defaultTemplateId={this.state.props.defaultTemplateId}
            actions={this.state.props.actions}
          />
        );
        break;
      case 'googleTerms':
        flyoverContent = <FlyoverGoogleTerms {...this.state.props} />;
        break;
      case 'cancel':
        flyoverContent = <Cancelation />;
        break;
      case 'storyboardExport':
        backgroundClass = ' bg-translucent ';
        flyoverContent = <StoryboardExportRolloutGate />;
        break;
      case 'presentationExport':
        backgroundClass = ' bg-translucent ';
        flyoverContent = <PresentationExportFlyover />;
        break;
      case 'storyboardShare':
        backgroundClass = ' bg-translucent ';
        flyoverContent = (
          <StoryboardShareFlyover
            {...this.state.props}
            teamId={this.state.teamId}
          />
        );
        break;
      case 'teamOnboardingName':
        flyoverContent = <TeamOnboardingName team_id={this.state.teamId} />;
        backgroundClass = ' bg-translucent ';
        // containerClass += ' compulsory';
        break;
      case 'editProjectAccess':
        backgroundClass = ' bg-translucent ';
        flyoverContent = <ConnectedEditProjectAccess {...this.state.props} />;
        break;
      case 'importVideo':
        backgroundClass = ' bg-translucent ';
        flyoverContent = <VideoImportFlyover {...this.state} />;
        break;

      case 'inlinePricing':
        flyoverContent = <FlyoverInlinePricing {...this.state.props} />;
        break;
      case 'upgrade':
        flyoverContent = <FlyoverUpgrade {...this.state.props} />;
        break;
      case 'imageGenerator':
        flyoverContent = <FlyoverImageGenerator {...this.state.props} />;
        break;
      case 'workflowPreview':
        flyoverContent = <FlyoverWorkflowPreview {...this.state.props} />;
        break;
      case 'characterEditor':
        flyoverContent = <FlyoverCharacterEditor {...this.state.props} />;
        break;
      case 'frameEditor':
      case 'player':
      case 'frameFocus':
        flyoverContent = (
          <FrameFocus
            key="frameFocus"
            frame={this.state.frame}
            mode={
              this.state.type === 'frameFocus'
                ? focusModeLocalState.getValue() || 'frameEditor'
                : this.state.type
            }
            frameIndex={this.state.index}
          />
        );

        innerClass = 'h-full overflow-y-hidden ';
        // Move down a bit when there's a banner, and make sure the
        // background layer doesn't overlap the banner
        containerClass += ' top-banner';
        backgroundClass = ' top-banner';
        break;
      case 'frameLimit':
        backgroundClass = 'bg-translucent';
        flyoverContent = <FrameLimitFlyover {...this.state.props} />;
        break;
      case 'userPermission':
        backgroundClass = 'bg-translucent';
        flyoverContent = <UserPermissionFlyover {...this.state.props} />;
        break;
      default:
        flyoverContent = '';
    }

    const providers = memoize(() => [
      [PostHogProvider, { client: posthogClient() }],
      BoordsAiProvider,
      WizardProvider,
      GeneratorProvider,
      GeneratorCharacterProvider,
      InpaintProvider,
      BillingProvider,
      [
        FlyoverContext.Provider,
        { value: this.state.type ? this.bodyRef : undefined },
      ],
      DefaultStoreContextProvider,
      BoordsDndProvider,
      DialogContextProvider,
      // a change in this.state.type will update this memoized thing
    ])(this.state.type);

    return (
      <ComponentComposer items={providers}>
        <div
          className={
            'fixed inset-0 overflow-x-hidden overflow-y-auto justify-center' +
            containerClass
          }
          style={{ minHeight: '100dvh', opacity: 0 }}
          ref={this.bodyRef}
        >
          <div
            className={
              'fixed top-0 left-0 w-100 h-100 w-full h-full' + backgroundClass
            }
          />
          <div
            className={'relative w-100 w-full overflow-x-hidden ' + innerClass}
            ref="windows"
            style={{
              maxHeight: '100%',
              transform: 'translateY(100%)',
            }}
          >
            {flyoverContent}
          </div>

          {process.env.NODE_ENV === 'development' && (
            <React.Suspense fallback={null}>
              <ToursDebug />
            </React.Suspense>
          )}
        </div>
      </ComponentComposer>
    );
  },
});
