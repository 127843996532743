/** @prettier */
import { DetailedFrame } from 'javascripts/types/frame';
import { IStoryboard } from 'javascripts/types/storyboard';
import React, { useContext } from 'react';
import Button from '../button/Button';
import { GeneratorContext } from './GeneratorContext';
import { ToursActions } from 'javascripts/flux/actions/tours';

interface GeneratorButtonProps {
  children: React.ReactElement;
  disabled: boolean;
  frame: DetailedFrame;
  storyboard: IStoryboard;
}

export const GeneratorButton: React.FC<GeneratorButtonProps> = ({
  children,
  disabled,
  frame,
  storyboard,
}): React.ReactElement => {
  const { setStoryboard, setFrame, setIsOpen } = useContext(GeneratorContext);

  const openFlyover = () => {
    setFrame(frame);
    setStoryboard(storyboard);
    setIsOpen(true);
    if (
      ToursStore.state.currentSteps.length > 0 &&
      ToursStore.state.currentSteps[0].name === 'generatorOpen'
    ) {
      ToursActions.advanceTour.defer('generator');
    }
  };

  return (
    <div
      // disabled={disabled}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        openFlyover();
      }}
    >
      {children}
    </div>
  );
};
