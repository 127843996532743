/** @format */
import {
  Building,
  ImageUp,
  Layers,
  ListChecks,
  ThumbsDown,
  ThumbsUp,
  User,
  Users,
  Wallpaper,
  WandSparkles,
} from 'lucide-react';

import React from 'react';
import { QuizCard } from './QuizCard';
import { QuizProof } from './QuizProof';
import { QuizGenerate } from './QuizGenerate';
import { QuizPricing } from './QuizPricing';
import { LoadingIndicator } from '../common/loading-indicator/LoadingIndicator';
import { QuizOffer } from './QuizOffer';

export interface QuizOption {
  id: OptionId;
  text: React.ReactNode;
  nextStepId: StepId | null;
  callback?: () => void;
}

export interface QuizStep {
  id: StepId;
  component?: React.ReactNode;
  title: string;
  description?: React.ReactNode;
  containerClassName?: string;
  backStepId?: StepId;
  options?: QuizOption[];
  preselectedOptionId?: OptionId;
  buttonText?: string;
  nextStepId?: StepId;
}

export type StepId =
  | 'step-role'
  | 'step-goals'
  | 'step-frequency'
  | 'step-team'
  | 'step-newsletter'
  | 'step-proof'
  | 'step-generate'
  | 'step-loading'
  | 'step-offer'
  | 'step-pricing';

export type OptionId =
  | 'option-role-advertising'
  | 'option-role-video'
  | 'option-role-animation'
  | 'option-role-film-tv'
  | 'option-role-comics'
  | 'option-role-social'
  | 'option-role-training'
  | 'option-role-school'
  | 'option-role-other'
  | 'option-goals-ai'
  | 'option-goals-organize'
  | 'option-frequency-daily'
  | 'option-frequency-weekly'
  | 'option-frequency-monthly'
  | 'option-frequency-onetime'
  | 'option-newsletter-yes'
  | 'option-newsletter-no'
  | 'option-collaboration-yes'
  | 'option-collaboration-no';

export const quizSteps: QuizStep[] = [
  {
    id: 'step-role',
    title: 'What are you creating storyboards for?',
    containerClassName: 'max-w-3xl',
    description: `Your answer helps personalize your experience.`,
    options: [
      {
        id: 'option-role-advertising',
        text: <QuizCard title="Advertising" compact />,
        nextStepId: 'step-frequency',
      },
      {
        id: 'option-role-video',
        text: <QuizCard title="Video Production" compact />,
        nextStepId: 'step-frequency',
      },
      {
        id: 'option-role-training',
        text: <QuizCard title="Training" compact />,
        nextStepId: 'step-frequency',
      },
      {
        id: 'option-role-social',
        text: <QuizCard title="Social Media" compact />,
        nextStepId: 'step-frequency',
      },
      {
        id: 'option-role-film-tv',
        text: <QuizCard title="Film & TV" compact />,
        nextStepId: 'step-frequency',
      },
      {
        id: 'option-role-animation',
        text: <QuizCard title="Animation" compact />,
        nextStepId: 'step-frequency',
      },
      {
        id: 'option-role-comics',
        text: <QuizCard title="Comics" compact />,
        nextStepId: 'step-frequency',
      },
      {
        id: 'option-role-school',
        text: <QuizCard title="School" compact />,
        nextStepId: 'step-frequency',
      },
      {
        id: 'option-role-other',
        text: <QuizCard title="Other" compact />,
        nextStepId: 'step-frequency',
      },
    ],
  },
  {
    id: 'step-frequency',
    title: 'How often do you use storyboards?',
    // description: 'This helps us recommend the right plan for your needs.',
    // preselectedOptionId: 'option-frequency-monthly',
    options: [
      {
        id: 'option-frequency-onetime',
        text: <QuizCard title="One-time project" compact />,
        nextStepId: 'step-goals',
      },
      {
        id: 'option-frequency-monthly',
        text: <QuizCard title="Monthly" compact />,
        nextStepId: 'step-goals',
      },
      {
        id: 'option-frequency-weekly',
        text: <QuizCard title="Weekly" compact />,
        nextStepId: 'step-goals',
      },
      {
        id: 'option-frequency-daily',
        text: <QuizCard title="Daily" compact />,
        nextStepId: 'step-goals',
      },
    ],
  },
  {
    id: 'step-goals',
    title: 'Pick your path',
    description: `Both options are available during your trial. Try either approach to see what works best.`,
    preselectedOptionId: 'option-goals-ai',
    options: [
      {
        id: 'option-goals-ai',
        text: (
          <QuizCard
            title={`Create with AI`}
            description={`Use AI to generate storyboards.`}
            icon={<WandSparkles />}
          />
        ),
        nextStepId: 'step-newsletter',
      },
      {
        id: 'option-goals-organize',
        text: (
          <QuizCard
            title={`Use my images`}
            description={`Create storyboards with my own assets.`}
            icon={<ImageUp />}
          />
        ),
        nextStepId: 'step-newsletter',
      },
    ],
  },
  {
    id: 'step-team',
    title: 'Do you work on a team?',
    description: `Boords helps creative teams collaborate on storyboards.`,
    preselectedOptionId: 'option-collaboration-no',
    options: [
      {
        id: 'option-collaboration-no',
        text: <QuizCard title={`No, I work solo`} icon={<User />} />,
        nextStepId: 'step-proof',
      },
      {
        id: 'option-collaboration-yes',
        text: <QuizCard title={`Yes, I work on a team`} icon={<Users />} />,
        nextStepId: 'step-proof',
      },
    ],
  },
  {
    id: 'step-proof',
    title: 'We can help!',
    containerClassName: 'max-w-2xl',
    component: <QuizProof />,
    backStepId: 'step-goals',
    buttonText: "Let's go →",
    nextStepId: 'step-newsletter',
  },
  {
    id: 'step-newsletter',
    title: 'Want pro pre-production tips?',
    description: `Join 10,000+ other creatives getting weekly insights to maximize your trial.`,
    preselectedOptionId: 'option-newsletter-no',
    buttonText: 'Finish setup →',
    options: [
      {
        id: 'option-newsletter-no',
        text: <QuizCard title={`No thanks`} icon={<ThumbsDown />} />,
        nextStepId: 'step-loading',
      },
      {
        id: 'option-newsletter-yes',
        text: <QuizCard title={`Yes please!`} icon={<ThumbsUp />} />,
        nextStepId: 'step-loading',
      },
    ],
  },
  {
    id: 'step-loading',
    title: 'Creating account....',
    description: `You'll be redirected in a few seconds`,
    containerClassName: 'max-w-2xl',
    component: <LoadingIndicator />,
    backStepId: 'step-goals',
  },
  {
    id: 'step-generate',
    title: 'Generate your first storyboard',
    description: `Choose an example prompt...`,
    containerClassName: 'max-w-2xl',
    component: <QuizGenerate />,
    backStepId: 'step-goals',
    buttonText: "Let's go →",
    nextStepId: 'step-loading',
  },
  {
    id: 'step-offer',
    title: '',
    containerClassName: 'max-w-2xl',
    component: <QuizOffer />,
    backStepId: 'step-goals',
    buttonText: "Let's go →",
    nextStepId: 'step-pricing',
  },
  {
    id: 'step-pricing',
    title: 'Choose where to start',
    description: 'Last step! You can change or cancel your plan at any time.',
    containerClassName: 'max-w-screen',
    component: <QuizPricing />,
    backStepId: 'step-proof',
  },
];
