/** @format */
import React, { useEffect, useContext } from 'react';
import Button from '../button/Button';
import { TextArea } from '../form/text-input/TextInput';
import { useInpaint } from './InpaintContext';
import format from 'date-fns/format';
import Slider from 'blackbird/components/slider/Slider';
import { type IStoryboard } from 'javascripts/types/storyboard';
import { type DetailedFrame } from 'javascripts/types/frame';
import Img from 'javascripts/components/shared/Img';
import { getFrameImage } from 'javascripts/helpers/getFrameImage';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { FrameImageStatusOverlay } from 'javascripts/components/frames/editor/FrameImageStatus';
import { LoadingIndicator } from '../common/loading-indicator/LoadingIndicator';
import { ModalDivider } from '../dialog/Dialog';
import { GeneratorTimer } from '../generator/GeneratorTimer';
import { ButtonWithCreditIndicator } from '../generator/GeneratorForm';
import Tooltip from '../feedback/tooltip/Tooltip';
import { Brush, Eraser, GraduationCap, Redo, Undo } from 'lucide-react';
import classNames from 'classnames';
import { MagnifiedPreview } from './MagnifiedPreview';
import { GeneratorContext } from '../generator/GeneratorContext';
import Toast from '../feedback/toast/Toast';
import { InpaintHelp } from './InpaintHelp';

interface InpaintContainerProps {
  storyboard: IStoryboard;
  frame: DetailedFrame;
}

interface InpaintMetadataProps {
  title: string;
  children: React.ReactElement;
}

const InpaintMetadata: React.FC<InpaintMetadataProps> = ({
  title,
  children,
}) => (
  <div className={'flex items-center space-x-2 text-xs'}>
    <div className="text-type-disabled">{title}</div>
    <div className="text-type-subdued">{children}</div>
  </div>
);

export const InpaintContainer: React.FC<InpaintContainerProps> = ({
  frame,
  storyboard,
}) => {
  const {
    isAvailable,
    setIsVisible,
    brushSize,
    setBrushSize,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
    canvas,
    setFrame,
    startWorker,
    setStoryboard,
    prompt,
    setPrompt,
    isProcessing,
    inpaintPreview,
    PollInpaint,
    handleDiscard,
    handleCancel,
    handleInsert,
    isCanvasEmpty,
    setIsCanvasEmpty,
    mask,
    errorMessage,
    drawingMode,
    toggleDrawingMode,
    setIsAvailable,
    setDrawingMode,
    frame: contextFrame,
    storyboard: contextStoryboard,
  } = useInpaint();

  const { hasCredits, GetTokenCount } = useContext(GeneratorContext);

  const isDisabled = isCanvasEmpty || prompt.length < 2 || !hasCredits;

  useEffect(() => {
    setFrame(frame);
    setStoryboard(storyboard);
  }, [frame, storyboard]);

  useEffect(() => {
    if (contextFrame && contextStoryboard) {
      if (contextFrame.large_image_url.includes('assets/missing')) {
        setIsAvailable(false);
      } else {
        setIsAvailable(true);
      }
      PollInpaint({ firstRun: true });
      GetTokenCount();
    }
  }, [contextFrame, contextStoryboard]);

  const Prompt: React.FC = () =>
    !prompt ? (
      <div className="mt-4" />
    ) : (
      <div className="mt-6 mb-8 text-sm">
        <div className="mb-0.5 font-semibold">{`Prompt`}</div>
        <div className="text-type-subdued">{prompt}</div>
      </div>
    );

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, [setIsVisible]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      e.preventDefault();
      if (!isDisabled) {
        startWorker();
      }
    }
  };

  const handleUndo = () => {
    if (!canvas || undoStack.length <= 1) return;
    const currentState = undoStack[undoStack.length - 1];
    setRedoStack((prev) => [...prev, currentState]);
    const newStack = undoStack.slice(0, -1);
    const previousState = newStack[newStack.length - 1];

    canvas.loadFromJSON(
      JSON.parse(previousState),
      canvas.renderAll.bind(canvas),
    );
    setUndoStack(newStack);

    setTimeout(() => {
      if (canvas.getObjects().length === 0) {
        setIsCanvasEmpty(true);
      }
    }, 100);
  };

  const handleRedo = () => {
    if (!canvas || redoStack.length === 0) return;
    const stateToRedo = redoStack[redoStack.length - 1];
    const newRedoStack = redoStack.slice(0, -1);
    canvas.loadFromJSON(JSON.parse(stateToRedo), canvas.renderAll.bind(canvas));
    setUndoStack((prev) => [...prev, stateToRedo]);
    setRedoStack(newRedoStack);
    setIsCanvasEmpty(false);
  };

  return (
    <div className="p-6 space-y-4">
      {!isAvailable && !isProcessing ? (
        <div>
          <Toast
            message={`Magic Fill can only be used on frames with images.`}
            kind="warning"
            size="full"
            hideIcon
          />
        </div>
      ) : isProcessing ? (
        <>
          <div className="relative">
            <FrameImageStatusOverlay title="Generating...">
              <LoadingIndicator className="w-4 h-4 text-white" />
            </FrameImageStatusOverlay>

            {mask && (
              <div className="absolute inset-0 z-10 opacity-80">
                <Img
                  width={
                    BoordsFrameSizeHelper(storyboard.frame_aspect_ratio).width
                  }
                  height={
                    BoordsFrameSizeHelper(storyboard.frame_aspect_ratio).height
                  }
                  src={mask}
                />
              </div>
            )}
            <Img
              width={BoordsFrameSizeHelper(storyboard.frame_aspect_ratio).width}
              height={
                BoordsFrameSizeHelper(storyboard.frame_aspect_ratio).height
              }
              src={getFrameImage(
                frame.thumbnail_image_url,
                storyboard.frame_aspect_ratio,
              )}
            />
          </div>
          <Prompt />
          <div className="space-y-2">
            <InpaintMetadata title={`Timer`}>
              <GeneratorTimer />
            </InpaintMetadata>
          </div>
          <div className="my-8">
            <ModalDivider />
          </div>
          <Button
            className="w-full"
            type="secondary"
            size={'md'}
            onClick={handleCancel}
          >{`Cancel`}</Button>
        </>
      ) : inpaintPreview ? (
        <div>
          <MagnifiedPreview
            thumbnail_url={inpaintPreview.thumbnail_url}
            aspect_ratio={storyboard.frame_aspect_ratio}
          />

          <Prompt />

          <div className="space-y-2">
            <InpaintMetadata title={`Created`}>
              <>
                {`${format(
                  new Date(inpaintPreview.created_at),
                  `d MMM yyyy, HH:mm`,
                )}`}
              </>
            </InpaintMetadata>
          </div>

          <div className="my-8">
            <ModalDivider />
          </div>

          <div className="space-y-4">
            <Button
              className="w-full"
              type="fancy"
              size={'md'}
              onClick={handleInsert}
            >
              <ButtonWithCreditIndicator>
                {`Insert Image`}
              </ButtonWithCreditIndicator>
            </Button>
            <Button
              className="w-full"
              type="secondary"
              size={'md'}
              onClick={handleDiscard}
            >{`Back`}</Button>
          </div>
        </div>
      ) : (
        <div className="relative space-y-6">
          {!hasCredits && (
            <div className="absolute inset-0 z-20 flex items-center justify-center bg-white/70">
              <div className="mx-2">
                <div className="text-sm rounded-lg shadow-lg">
                  <FeatureUnavailable featureName="ai_inpaint_credits" />
                </div>
              </div>
            </div>
          )}
          {errorMessage && (
            <Toast hideIcon kind="error" size="full" message={errorMessage} />
          )}
          <div className="flex items-center border border-border rounded-md bg-surface-light divide-x divide-border">
            <Tooltip placement="top-start" title={`Draw`}>
              <div
                className={classNames(
                  'grow-0 flex-shrink-0 pb-2 pt-1.5 px-2 cursor-pointer',
                  drawingMode === 'brush'
                    ? 'text-pink-500'
                    : 'text-type-disabled',
                )}
                onClick={() => setDrawingMode('brush')}
              >
                <Brush className="w-4 h-4" />
              </div>
            </Tooltip>

            <Tooltip placement="top" title={`Erase`}>
              <div
                className={classNames(
                  'grow-0 flex-shrink-0 pb-2 pt-1.5 px-2 cursor-pointer',
                  drawingMode === 'eraser'
                    ? 'text-pink-500'
                    : 'text-type-disabled',
                )}
                onClick={() => setDrawingMode('eraser')}
              >
                <Eraser className="w-4 h-4" />
              </div>
            </Tooltip>
            <div className="flex-auto px-2 py-2">
              <Slider
                fullWidth
                sliderOnly
                min={10}
                max={100}
                value={brushSize}
                onChange={(value) => setBrushSize(value as number)}
              />
            </div>

            <div
              className={classNames(
                'grow-0 flex-shrink-0 pb-2 pt-1.5 px-2 text-sm font-semibold',
                undoStack.length <= 1
                  ? 'cursor-not-allowed text-type-disabled'
                  : 'cursor-pointer',
              )}
              onClick={() => {
                if (undoStack.length > 0) handleUndo();
              }}
            >
              <Undo className="w-4 h-4" />
            </div>
            <div
              className={classNames(
                'grow-0 flex-shrink-0 pb-2 pt-1.5 px-2 text-sm font-semibold',
                redoStack.length === 0
                  ? 'cursor-not-allowed text-type-disabled'
                  : 'cursor-pointer',
              )}
              onClick={() => {
                if (redoStack.length > 0) handleRedo();
              }}
            >
              <Redo className="w-4 h-4" />
            </div>
          </div>

          <TextArea
            plainText
            placeholder={`Describe your changes`}
            inputSize="md"
            minRows={3}
            immediate
            value={prompt}
            onChange={setPrompt}
            onKeyDown={handleKeyDown}
          />

          <Tooltip
            title={`Draw the area you'd like to change and add a prompt to continue`}
            placement={`bottom`}
            disabled={!isDisabled}
          >
            <Button
              type="fancy"
              onClick={() => startWorker()}
              className="w-full"
              disabled={isDisabled}
            >
              {`Modify Image`}
            </Button>
          </Tooltip>

          <InpaintHelp />
        </div>
      )}
    </div>
  );
};
