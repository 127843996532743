/** @prettier */
import {
  type IconFinderStyleOrCategory,
  type IconSet,
} from 'javascripts/components/frame_editor/toolbar/image_search/IconFinderContainer';
import { type frameEditorMode } from 'javascripts/components/frame_editor/types';
import type {
  AnnotationID as CommentAnnotationID,
  AnnotationToolSettings,
} from 'blackbird/components/comments/annotations/AnnotationToolEngine';
import {
  contains,
  each,
  isArray,
  isUndefined,
  uniq,
  without,
} from 'underscore';
import { type AccordionSortOption } from 'blackbird/components/projects/ProjectsContext';
import type { XLSXOutputProps } from 'javascripts/components/storyboard/ExportSpreadsheet/generateXlsx';
import { type AspectRatio } from 'javascripts/components/new_project/NewStoryboardAspectRatio';
import { type imageEditorSidebarMode } from 'javascripts/components/panelbars/PanelbarImageLibrary';
import { type panelView } from 'javascripts/components/panelbars/FrameFocusPanelbar';
var store = require('store');
require('store/plugins/all');

class LocalState {
  keyName = 'boords-state';

  constructor() {
    if (isUndefined(store.get(this.keyName))) {
      store.set(this.keyName, {});
    }

    each(
      [
        'currentDashboardTab',
        'editableComments',
        'expandedDashboardProjects',
        'expandedDashboardProjects',
        'singleEvents',
        'iconFinderSavedIconsetsLocalState',
        'unlockedShareableStoryboards',
      ],
      (k) => {
        if (isUndefined(store.get(this.keyName)[k])) {
          var s = store.get(this.keyName);
          s[k] = [];
          store.set(this.keyName, s);
        }
      },
    );
  }

  getValue<T = unknown>(key: string): T | undefined {
    return store.get(this.keyName) ? store.get(this.keyName)[key] : undefined;
  }

  /** Sets a value under the key provided. You do not have to stringify JSON */
  setValue(key: string, value: unknown): unknown {
    var s = store.get(this.keyName);
    if (s) {
      s[key] = value;
      return store.set(this.keyName, s);
    }
  }

  removeFromArray<T = any>(id: T, arr: string) {
    const existing = this.getValue<T[]>(arr) ?? [];
    this.setValue(arr, without(existing, id));
  }

  addToArray<T>(id: T, arr: string) {
    var ids = this.getValue<T[]>(arr) ?? [];
    ids.push(id);
    this.setValue(arr, uniq(ids));
  }

  idIsInArray<T = unknown>(id: T, arr: string) {
    const existing = this.getValue<T[]>(arr);
    if (!existing) return false;
    return contains(existing, id);
  }
}

/**
 * Boords' abstractions over localStorage. The entire storage is stringified,
 * so you can store JS objects without having to stringify them.
 */
const instance = new LocalState();
export { instance as LocalState };

/**
 * A special typesafe version of localState which allows you to create a
 * typesafe setter and getter for the same key. You must pass the returned type
 * as a generic, e.g. `const localState = typedLocalState<string[]>('key')`.
 * Like with the main class, Objects are accepted as values
 */
export const typedLocalState = <T = never>(key: string) => {
  return {
    getValue: () => instance.getValue<T | undefined>(key),
    /** Sets a value. Note: you do not have to stringify JSON */
    setValue: (value: T) => instance.setValue(key, value),
    addToArray: (value: T extends Array<unknown> ? T[number] : T) => {
      const existing = instance.getValue<T>(key);
      if (isArray(existing)) {
        instance.setValue(key, [...existing, value]);
      } else if (isUndefined(existing)) {
        instance.setValue(key, [value]);
      } else {
        throw new Error('existing value must be an array');
      }
    },
  };
};

export const focusModeLocalState = typedLocalState<'frameEditor' | 'player'>(
  'focusMode',
);

export const frameEditorModeLocalState =
  typedLocalState<frameEditorMode>('frameEditorMode');

export const commentNameLocalState = typedLocalState<string | undefined>(
  'commentName',
);

export const editableCommentsLocalState =
  typedLocalState<number[]>('editableComments');

export const versionNotificationsDismissedLocalState = typedLocalState<
  number[]
>('versionNotificationsDismissedForStoryboard');

export const preSignupInvitationDismissedLocalState = typedLocalState<boolean>(
  'preSignupInvitationDismissedLocalState',
);

export const generatorStyleLocalState = typedLocalState<string>(
  'generatorStyleLocalState',
);

export const offerCheckoutSessionIdLocalState = typedLocalState<string>(
  'offerCheckoutSessionIdLocalState',
);

export const generatorLastRunLocalState = typedLocalState<string>(
  'generatorLastRunLocalState',
);

export const generatorCharacterLocalState = typedLocalState<string | undefined>(
  'generatorCharacterLocalState',
);

export const iconFinderTabLocalState = typedLocalState<string>(
  'iconFinderTabLocalState',
);

export const iconFinderCategoryLocalState = typedLocalState<
  IconFinderStyleOrCategory | undefined
>('iconFinderCategoryLocalState');

export const iconFinderStyleLocalState = typedLocalState<
  IconFinderStyleOrCategory | undefined
>('iconFinderStyleLocalState');

export const iconFinderIconsetLocalState = typedLocalState<IconSet | undefined>(
  'iconFinderIconsetLocalState',
);

export const iconFinderSavedIconsetsLocalState = typedLocalState<
  IconSet[] | undefined
>('iconFinderSavedIconsetsLocalState');

export const annualUpgradeDismissedLocalState = typedLocalState<boolean>(
  'annualUpgradeDismissedLocalState',
);

export const sunsetNotificationLocalState = typedLocalState<boolean>(
  'sunsetNotificationLocalState',
);

export const userColoursLocalState = typedLocalState<string[]>('userColors');
export const gridViewColumnsLocalState =
  typedLocalState<number>('gridViewColumns');

export const sunsetPendingNotificationLocalState = typedLocalState<boolean>(
  'sunsetPendingNotificationLocalState',
);

export const annotationToolLocalState = typedLocalState<AnnotationToolSettings>(
  'annotationToolLocalState',
);

export const lastActiveProjectLocalState = typedLocalState<number | null>(
  'lastActiveProjectId',
);

export const activeAnnotationLocalState =
  typedLocalState<CommentAnnotationID | null>('activeAnnotation');

export const expandedProjectsLocalState =
  typedLocalState<number[]>('expandedProjects');

export const unlockedShareableProjectsLocalState = typedLocalState<number[]>(
  'unlockedShareableProjects',
);

export const generatorColoursLocalState =
  typedLocalState<string[]>('generatorColours');

// this key is also referenced in PanelbarImageLibrary.tsx
export const imageEditorSidebarModeLocalState =
  typedLocalState<imageEditorSidebarMode>('imageLibraryTabSelected');

export const panelbarFocusLocalState =
  typedLocalState<panelView>('panelbarFocusState');

export const sidebarWidthLocalState = typedLocalState<number>('sidebarWidth');

export const sidebarMinimizedLocalState =
  typedLocalState<boolean>('sidebarMinimized');

export type SortField = 'name' | 'created_at' | 'updated_at';
export type SortOrder = 'asc' | 'desc';

export const projectContentsSortFieldLocalState = typedLocalState<SortField>(
  'projectContentsSortField',
);
export const projectContentsSortOrderLocalState = typedLocalState<SortOrder>(
  'projectContentsSortOrder',
);

export const newStoryboardAspectRatioLocalState = typedLocalState<AspectRatio>(
  'newStoryboardAspectRatio',
);

export const inpaintBrushSizeLocalState =
  typedLocalState<number>('inpaintBrushSize');

export const teamOnlyCommentLocalState =
  typedLocalState<boolean>('teamOnlyComment');

export const hideWorkflowButtonLocalState =
  typedLocalState<boolean>('hideWorkflowButton');

export const XLSSettingsLocalState =
  typedLocalState<XLSXOutputProps>('xlsxSettings');
export const projectsAccordionSortLocalState =
  typedLocalState<AccordionSortOption>('projectContentsSortOrder');

(window as any).LocalState = instance;
