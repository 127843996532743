/** @prettier */

import React, { createContext, useState, useCallback, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { rollbar, rollbar as Rollbar } from 'javascripts/helpers/rollbar';
import i18n from 'i18next';
import { endOfMonth, isAfter } from 'date-fns';
import logger from 'javascripts/helpers/logger';
import { apiRequest } from '../apiRequestHelper';
import type { UserResponse } from 'javascripts/types/user';
import { type FeatureName } from 'blackbird/components/features/Feature';

export type IBillingAddonSlug =
  | '500-ai-credits'
  | '1000-ai-credits'
  | '1500-ai-credits'
  | 'free-ai-credits';

export type IBillingPlanSlug =
  | 'company'
  | 'companyplus'
  | 'studio'
  | 'professional_starter'
  | 'professional_medium'
  | 'professional_large'
  | 'professional_extra_large'
  | 'professional_trial'
  | 'free'
  | 'individual'
  | 'group'
  | 'agency'
  | 'powerhouse'
  | 'lite'
  | 'standard'
  | 'business'
  | 'workflow';

export type IBillingLegacyPlanSlug = 'company' | 'companyplus' | 'studio';

export interface IBillingDefaultSource {
  id: string;
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
}

export type IBillingPlanCurrency = 'gbp' | 'usd' | 'eur';
export type IBilllingPlanInterval = 'monthly' | 'yearly' | 'year' | 'month';

interface StripeCustomer {
  id: number;
  currency: string;
  stripe_subscription_id: string | null;
  subscription_status: IBillingSubscriptionStatus;
  stripe_customer_id: string;
  default_source: null | IBillingDefaultSource;
  current_plan_slug: any; // Accounts for legacy
  current_interval: IBilllingPlanInterval;
  billable_user_count: number;
}

interface StripeCoupon {
  amount_off: number | null;
  created: number;
  currency: string | null;
  duration: 'forever' | 'once' | 'repeating';
  duration_in_months: number | null;
  id: string;
  livemode: boolean;
  max_redemptions: number | null;
  metadata: Record<string, any>;
  name: string;
  percent_off: number | null;
  redeem_by: number | null;
  times_redeemed: number;
  valid: boolean;
}

type IBillingSubscriptionStatus =
  | 'incomplete'
  | 'incomplete_expired'
  | 'trialing'
  | 'active'
  | 'past_due'
  | 'canceled'
  | 'unpaid'
  | undefined;

interface StripeTotalDiscountAmount {
  amount: number;
  discount: {
    coupon: StripeCoupon;
    customer: string;
    id: string;
    promotion_code: string | null;
  };
}

interface StripeInvoicePreview {
  amount_due: number;
  id: string | null;
  subtotal: number;
  tax: number | null;
  total: number;
  starting_balance?: number;
  total_discount_amounts: StripeTotalDiscountAmount[];
}

interface BillingProviderProps {
  children: React.ReactElement;
}

interface CustomerAPIResponseAttrs {
  attributes: {
    customer: StripeCustomer;
  };
}

export interface AddOnProduct {
  name: string;
  description: string;
  slug: IBillingAddonSlug;
  quantity: number;
  prices: Price[];
}

interface ServerPlanData {
  name: string;
  slug: IBillingPlanSlug;
  currency: IBillingPlanCurrency;
  interval: IBilllingPlanInterval;
  stripe_price_id: string;
  unit_amount: number;
  status?: IBillingSubscriptionStatus;
}

export interface Price {
  interval: IBilllingPlanInterval;
  currency: IBillingPlanCurrency;
  amount: number;
  additional_user_amount?: number;
}

export interface AdditonalFeature {
  title: string;
  tooltip: string;
}

export interface LocalPlanData {
  name: string;
  slug: IBillingPlanSlug;
  maxStoryboards: number;
  maxImageCredits: number;
  maxUsers: number;
  description: string;
  descriptionShort?: string;
  subtitle: string;
  featured?: boolean;
  extendedFeaturesSubtitle?: string;
  additionalFeatures?: FeatureName[];
  extendedFeatures?: FeatureName[];
  summaryFeatures?: FeatureName[];
  prices: Price[];
}

type CompletePlanData = ServerPlanData & LocalPlanData;

type ErrorType =
  | 'current_plan'
  | 'invalid_plan'
  | 'generic'
  | 'payment_error'
  | null;

interface PlanAPIResponseAttrs {
  attributes: {
    plan: ServerPlanData;
    stripe_subscription_status: IBillingSubscriptionStatus;
  };
}

interface ErrorAPIResponse {
  source: {
    pointer: string;
  };
  status: string;
  title: string;
}

interface InvoicePreviewAPIResponseAttrs {
  attributes: StripeInvoicePreview;
}

interface CustomerAPIResponse {
  data: CustomerAPIResponseAttrs[];
  errors?: ErrorAPIResponse[];
}

interface PlanAPIResponse {
  data: PlanAPIResponseAttrs[];
  errors?: ErrorAPIResponse[];
}

interface InvoicePreviewAPIResponse {
  data: InvoicePreviewAPIResponseAttrs[];
  errors?: ErrorAPIResponse[];
}

export type CheckoutUiMode = 'embedded' | 'hosted';

interface BillingContextProps {
  CheckoutSessionAsync: () => void;
  LoadStripeAsync: () => void;
  handleClose: () => void;
  handleReload: () => void;
  errorMessage: string | undefined;
  customer: StripeCustomer | undefined;
  dialogSubtitle: string | undefined;
  dialogTitle: string | undefined;
  fetchCustomer: () => void;
  fetchInvoicePreview: () => void;
  fetchNewPlan: () => void;
  trackCheckoutEvent: (eventName: string, args?: object) => void;
  user: UserResponse | undefined;
  hasValidCard: boolean;
  annualUpgrade: boolean;
  hasActiveSubscription: boolean | undefined;
  newCustomerProceed: boolean | undefined;
  clientSecret?: string;
  setClientSecret: React.Dispatch<React.SetStateAction<string>>;
  setAnnualUpgrade: React.Dispatch<React.SetStateAction<boolean>>;
  setNewCustomerProceed: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  complete: boolean;
  invoicePreview: StripeInvoicePreview | undefined;
  loadStripeCheckoutSession: (ui_mode?: CheckoutUiMode) => void;
  newPlan: CompletePlanData | undefined;
  localPlans: LocalPlanData[];
  newPlanInterval: IBilllingPlanInterval | undefined;
  newPlanSlug: IBillingPlanSlug | undefined;
  processing: boolean;
  errorType: ErrorType;
  setUser: React.Dispatch<React.SetStateAction<UserResponse | undefined>>;
  setErrorType: React.Dispatch<React.SetStateAction<ErrorType>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setCustomer: React.Dispatch<React.SetStateAction<StripeCustomer>>;
  setDialogSubtitle: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDialogTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
  setHasValidCard: React.Dispatch<React.SetStateAction<boolean>>;
  setHasActiveSubscription: React.Dispatch<React.SetStateAction<boolean>>;
  setComplete: React.Dispatch<React.SetStateAction<boolean>>;
  setInvoicePreview: React.Dispatch<React.SetStateAction<StripeInvoicePreview>>;
  setNewPlan: React.Dispatch<React.SetStateAction<CompletePlanData>>;
  setNewPlanInterval: React.Dispatch<
    React.SetStateAction<IBilllingPlanInterval>
  >;
  setNewPlanSlug: React.Dispatch<React.SetStateAction<IBillingPlanSlug>>;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  setStripe: React.Dispatch<React.SetStateAction<any>>;
  stripe: any;
  updateSubscription: () => void;
}

const initLocalPlans = i18n.t('billing:plans', {
  returnObjects: true,
}) as LocalPlanData[];

const initialContext = {
  CheckoutSessionAsync: () => {},
  LoadStripeAsync: () => {},
  setClientSecret: () => {},
  clientSecret: undefined,
  errorMessage: undefined,
  customer: undefined,
  dialogSubtitle: undefined,
  dialogTitle: undefined,
  fetchCustomer: () => {},
  setUser: () => {},
  fetchInvoicePreview: () => {},
  fetchNewPlan: () => {},
  hasValidCard: false,
  hasActiveSubscription: undefined,
  invoicePreview: undefined,
  loadStripeCheckoutSession: () => {},
  errorType: null as ErrorType,
  setErrorType: () => {},
  setHasActiveSubscription: () => {},
  newPlan: undefined,
  user: undefined,
  newPlanInterval: undefined,
  newCustomerProceed: undefined,
  setNewCustomerProceed: () => {},
  newPlanSlug: undefined,
  processing: false,
  annualUpgrade: false,
  complete: false,
  localPlans: initLocalPlans,
  setAnnualUpgrade: () => {},
  trackCheckoutEvent: () => {},
  handleClose: () => {},
  handleReload: () => {},
  setErrorMessage: () => {},
  setComplete: () => {},
  setCustomer: () => {},
  setDialogSubtitle: () => {},
  setDialogTitle: () => {},
  setHasValidCard: () => {},
  setInvoicePreview: () => {},
  setNewPlan: () => {},
  setNewPlanInterval: () => {},
  setNewPlanSlug: () => {},
  setProcessing: () => {},
  setStripe: () => {},
  stripe: undefined,
  updateSubscription: () => {},
};

export const BillingContext =
  createContext<BillingContextProps>(initialContext);

export const BillingProvider: React.FC<BillingProviderProps> = ({
  children,
}) => {
  const [user, setUser] = useState<UserResponse | undefined>(
    initialContext.user,
  );
  const [newCustomerProceed, setNewCustomerProceed] = useState<
    boolean | undefined
  >(initialContext.newCustomerProceed);

  const [customer, setCustomer] = useState<StripeCustomer | undefined>(
    initialContext.customer,
  );
  const [stripe, setStripe] = useState<any>(initialContext.stripe);

  const [clientSecret, setClientSecret] = useState<string | undefined>(
    initialContext.clientSecret,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    initialContext.errorMessage,
  );
  const [errorType, setErrorType] = useState<ErrorType>(null);

  const [invoicePreview, setInvoicePreview] = useState<
    StripeInvoicePreview | undefined
  >(initialContext.invoicePreview);

  const [localPlans] = useState<LocalPlanData[]>(initialContext.localPlans);

  const [newPlan, setNewPlan] = useState<any>(initialContext.newPlan);
  const [annualUpgrade, setAnnualUpgrade] = useState<boolean>(
    initialContext.annualUpgrade,
  );
  const [hasValidCard, setHasValidCard] = useState<boolean>(
    initialContext.hasValidCard,
  );
  const [processing, setProcessing] = useState<boolean>(
    initialContext.processing,
  );
  const [hasActiveSubscription, setHasActiveSubscription] = useState<
    boolean | undefined
  >(initialContext.hasActiveSubscription);
  const [complete, setComplete] = useState<boolean>(initialContext.complete);

  const [dialogTitle, setDialogTitle] = useState<string | undefined>(undefined);
  const [dialogSubtitle, setDialogSubtitle] = useState<string | undefined>(
    undefined,
  );

  const [newPlanInterval, setNewPlanInterval] = useState<
    IBilllingPlanInterval | undefined
  >(initialContext.newPlanInterval);

  const [newPlanSlug, setNewPlanSlug] = useState<IBillingPlanSlug | undefined>(
    initialContext.newPlanSlug,
  );

  const handleClose = () => {
    FlyoverActions.close.defer();
    history.pushState({}, 'Dashboard', '/');
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleError = (error: string) => {
    logger.error(error);
    setProcessing(false);
    setErrorType('generic');
    setErrorMessage(error);

    Track.event.defer('checkout_error', {
      category: 'Checkout',
      errorType: error,
    });
    rollbar.error('checkout_error', {
      errorType: error,
      location: 'BillingContext.tsx',
    });
  };

  const isValidSource = (source: IBillingDefaultSource | null): boolean => {
    if (!source) {
      return false;
    } else {
      return isAfter(
        endOfMonth(new Date(source.exp_year, source.exp_month - 1)),
        new Date(),
      );
    }
  };

  const trackCheckoutEvent = useCallback(
    (eventName: string, args?: object) => {
      Track.event.defer(eventName, {
        hasValidCard: hasValidCard,
        newPlanSlug: newPlanSlug,
        newPlanInterval: newPlanInterval,
        category: 'Checkout',
        ...args,
      });
    },
    [hasValidCard, newPlanSlug, newPlanInterval],
  );

  const queryString = (name: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(name);
  };

  const newPlanQuantity = useMemo(() => {
    if (
      customer &&
      newPlanSlug &&
      ['workflow', 'standard'].includes(newPlanSlug)
    ) {
      return customer.billable_user_count;
    } else {
      return 1;
    }
  }, [newPlanSlug, customer]);

  const CheckoutSessionAsync = useCallback(
    async (ui_mode: CheckoutUiMode = 'hosted') => {
      try {
        const response = await fetch('/checkout', {
          method: 'post',
          body: JSON.stringify({
            data: {
              attributes: {
                plan: newPlan.slug,
                interval: newPlan.interval,
                coupon: queryString('coupon'),
                addon: queryString('addon'),
                ui_mode: ui_mode,
              },
            },
          }),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        const res = await response.json();

        if (response.ok && stripe) {
          trackCheckoutEvent('stripe_checkout_session');

          if (ui_mode === 'embedded') {
            setClientSecret(res.data.attributes.client_secret);
            return;
          } else {
            stripe
              .redirectToCheckout({
                sessionId: res.data.attributes.session_id,
              })
              .then((res: string) => {
                logger.log(res);
              })
              .catch((err: string) => {
                handleError(err);
              });
          }
        } else {
          if (res.data.errors[0].status === '303') {
            handleError('Cannot use checkout');
          } else {
            handleError(res.data.errors[0].detail);
          }
        }
      } catch (err) {
        handleError(err.message);
      }
    },
    [stripe, newPlan],
  );

  const LoadStripeAsync = async () => {
    const stripe = await loadStripe(BoordsConfig.stripePubKey);
    if (stripe) {
      setStripe(stripe);
    }
  };

  const loadStripeCheckoutSession = useCallback(
    async (ui_mode?: CheckoutUiMode) => {
      if (typeof stripe !== 'undefined') {
        CheckoutSessionAsync(ui_mode);
      } else {
        LoadStripeAsync();
      }
    },
    [stripe],
  );

  const fetchCustomer = async () => {
    try {
      const request = await apiRequest({
        path: 'billing/customer',
        method: 'get',
      });
      const response: CustomerAPIResponse = await request.json();
      if (request.ok) {
        const customerResponse = response.data[0].attributes.customer;
        setCustomer(customerResponse);
        if (customerResponse.stripe_subscription_id) {
          setHasActiveSubscription(true);
        } else {
          setHasActiveSubscription(false);
        }
      } else if (response.errors) {
        handleError(response.errors[0].title);
      } else {
        handleError('Unknown error fetching customer');
      }
    } catch (error) {
      handleError(error);
    }
  };

  const legacyPlanSlugs: string[] = ['company', 'companyplus', 'studio'];

  const fetchNewPlan = async () => {
    if (newPlanSlug && newPlanInterval && customer) {
      try {
        if (
          newPlanSlug === customer.current_plan_slug &&
          newPlanInterval === `${customer.current_interval}ly` &&
          customer.subscription_status === 'active'
        ) {
          setErrorType('current_plan');
        } else {
          const localPlan = localPlans.find(
            (plan) => plan.slug === newPlanSlug,
          );

          if (!localPlan) {
            setErrorType('invalid_plan');
          } else {
            const request = await apiRequest({
              path: `billing/plan/${newPlanSlug}/${newPlanInterval}`,
              method: 'get',
            });
            const response: PlanAPIResponse = await request.json();
            if (request.ok) {
              const { plan } = response.data[0].attributes;

              setNewPlan({ ...plan, ...localPlan });
              if (
                isValidSource(customer.default_source) &&
                hasActiveSubscription
              ) {
                setHasValidCard(true);
                trackCheckoutEvent('InitiateCheckout', {
                  hasValidCard: true,
                  coupon: queryString('coupon'),
                });
              } else {
                loadStripeCheckoutSession();
                trackCheckoutEvent('InitiateCheckout', {
                  hasValidCard: false,
                  coupon: queryString('coupon'),
                });
              }

              // Don't allow customers on non-legacy plans
              // to choose them, or legacy plans to subscribe
              // to monthly
              if (
                (legacyPlanSlugs.includes(newPlanSlug) &&
                  !legacyPlanSlugs.includes(customer.current_plan_slug)) ||
                (legacyPlanSlugs.includes(newPlanSlug) &&
                  newPlanInterval !== 'yearly')
              ) {
                setErrorType('invalid_plan');
              } else {
                if (
                  newPlanInterval === 'yearly' &&
                  customer.current_interval === 'month'
                ) {
                  setAnnualUpgrade(true);
                }
              }
            } else if (response.errors) {
              handleError(response.errors[0].title);
            } else {
              handleError('Unknown error fetching plan');
            }
          }
        }
      } catch (error) {
        handleError(error);
      }
    }
  };

  const fetchInvoicePreview = async () => {
    if (newPlanSlug && newPlanInterval && customer && !errorMessage) {
      const request = await apiRequest({
        path: `billing/invoice_preview`,
        method: 'post',
        payload: {
          customer_id: customer.stripe_customer_id,
          price_id: newPlan.stripe_price_id,
          quantity: newPlanQuantity,
          automatic_tax: false,
        },
      });
      const response: InvoicePreviewAPIResponse = await request.json();
      if (request.ok) {
        setInvoicePreview(response.data[0].attributes);
      } else {
        handleError('Error fetching invoice preview');
      }
    }
  };

  const updateSubscription = async () => {
    if (customer && newPlan) {
      setProcessing(true);
      try {
        const request = await apiRequest({
          path: `billing/subscriptions/${customer.stripe_subscription_id}`,
          method: 'put',
          payload: {
            plan_slug: newPlan.slug,
            interval: newPlan.interval,
            price_id: newPlan.stripe_price_id,
            quantity: newPlanQuantity,
          },
        });
        const response: PlanAPIResponse = await request.json();
        if (request.ok) {
          if (
            response.data[0].attributes.stripe_subscription_status === 'active'
          ) {
            setComplete(true);
            trackCheckoutEvent('Purchase', {
              revenue: (newPlan.unit_amount / 100).toFixed(2),
              value: (newPlan.unit_amount / 100).toFixed(2),
            });
          } else {
            setErrorType('payment_error');
          }
        } else if (response.errors) {
          handleError(response.errors[0].title);
        } else {
          handleError('Unknown error updating subscription');
        }
      } catch (error) {
        handleError(error);
      }
    } else if (!customer) {
      handleError('Customer not set');
    } else if (!newPlan) {
      handleError('New plan not set');
    }
  };

  const value: BillingContextProps = {
    complete,
    setComplete,
    processing,
    setProcessing,
    updateSubscription,
    dialogTitle,
    setDialogTitle,
    dialogSubtitle,
    setDialogSubtitle,
    CheckoutSessionAsync,
    LoadStripeAsync,
    trackCheckoutEvent,
    loadStripeCheckoutSession,
    hasActiveSubscription,
    setHasActiveSubscription,
    user,
    setUser,
    stripe,
    setStripe,
    localPlans,
    errorType,
    setErrorType,
    errorMessage,
    setErrorMessage,
    hasValidCard,
    setHasValidCard,
    invoicePreview,
    setInvoicePreview,
    handleReload,
    newPlan,
    handleClose,
    setNewPlan,
    newPlanSlug,
    setNewPlanSlug,
    newPlanInterval,
    fetchInvoicePreview,
    setNewPlanInterval,
    fetchNewPlan,
    fetchCustomer,
    annualUpgrade,
    setAnnualUpgrade,
    customer,
    setCustomer,
    newCustomerProceed,
    setNewCustomerProceed,
    clientSecret,
    setClientSecret,
  };

  return (
    <BillingContext.Provider value={value}>{children}</BillingContext.Provider>
  );
};
