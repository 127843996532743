/** @format */

import React from 'react';
import { ProjectsContext, type Project } from './ProjectsContext';
import { EditedDate } from './ProjectStoryboard';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';
import {
  type DashboardDragItem,
  DRAGGABLE_TYPES,
} from 'javascripts/components/shared/dragAndDropUtils';
import { ProjectDropZone } from './modals/ProjectDropZone';
import { FolderIcon } from 'lucide-react';

interface ProjectSubProjectProps {
  project: Project;
}
export const ProjectSubProject: React.FC<ProjectSubProjectProps> = ({
  project,
}) => {
  const {
    activeGroup,
    selectedProjects,
    toggleProjectSelection,
    setActiveProject,
  } = React.useContext(ProjectsContext);

  const isSelected = selectedProjects.includes(project.id);

  const canMoveProject = activeGroup?.actions.includes('projects.move');
  const canDeleteProject = activeGroup?.actions.includes('projects.delete');

  const [{ isDragging }, ref] = useDrag(() => ({
    type: DRAGGABLE_TYPES.project,
    item: {
      type: DRAGGABLE_TYPES.project,
      id: project.id,
      parentProjectId: project.parent_id,
    } as DashboardDragItem,
    canDrag: canMoveProject,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      className={classNames(
        'rounded-lg p-1.5 transition-opacity relative',
        canMoveProject || canDeleteProject
          ? 'hover:bg-black/10'
          : 'cursor-pointer',
        isDragging
          ? 'opacity-50'
          : isSelected
          ? 'ring-2 ring-brand-pink cursor-pointer'
          : undefined,
      )}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isSelected) {
          setActiveProject(project);
        } else {
          if (canMoveProject || canDeleteProject) {
            toggleProjectSelection(project.id);
          } else {
            setActiveProject(project);
          }
        }
      }}
    >
      <div ref={ref}>
        <ProjectDropZone
          className="p-4 pt-3 group bg-surface"
          projectId={project.id}
          border
        >
          <div className="flex items-center mb-0.5">
            <div className="flex-shrink-0 mr-1.5">
              <div className="flex items-center pt-1 opacity-20 group-hover:opacity-50">
                <FolderIcon className="w-4 h" />
              </div>
            </div>
            <div className="flex-auto">
              <div
                className="inline-block text-base cursor-pointer hover:underline"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setActiveProject(project);
                }}
              >
                {project.name}
              </div>
            </div>
          </div>
          <EditedDate editedAt={project.updated_at} />
        </ProjectDropZone>
      </div>
    </div>
  );
};
