/** @prettier */
import * as React from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { AssetsActions } from '../../../flux/actions/assets';
import { FrameActions } from '../../../flux/actions/frame';
import type { IFrame } from '../../../types/frame';
import { canDropImages } from '../../shared/canDropImages';
import type {
  fileDropResult,
  imageLibraryDragItem,
} from '../../shared/dragAndDropUtils';
import {
  allAcceptedDropTypes,
  DRAGGABLE_TYPES,
} from '../../shared/dragAndDropUtils';
import { FileUploadActions } from '../../../flux/actions/file_upload';
import { without } from 'underscore';
import { BoordsDropzone } from 'javascripts/components/shared/Dropzone';

interface DropParams {
  isOver?: boolean;
  canDrop?: boolean;
}
interface OuterProps {
  frame: IFrame;
  team_id: number;
  onDropParamsChange: ({ isOver, canDrop }: DropParams) => void;
  className?: string;
}

const accept = without(
  allAcceptedDropTypes,
  DRAGGABLE_TYPES.image,
  DRAGGABLE_TYPES.iconfinder,
);

const FrameImageDropZone = React.memo<OuterProps>((props) => {
  const [{ isInvalidDrag, isOver, canDrop }, ref] = useDrop({
    accept,
    canDrop: canDropImages(true),
    drop(_, monitor) {
      const type = monitor.getItemType() as typeof allAcceptedDropTypes[number];

      if (type === NativeTypes.FILE) {
        const item: fileDropResult = monitor.getItem();
        const file = item.items?.[0]?.getAsFile();

        if (file) {
          FrameActions.uploadImage.defer({
            id: props.frame.id,
            image_file_type: file.type,
            image_file_size: file.size,
            storyboard_id: props.frame.storyboard_id,
            background_image_url: null,
            file,
          });
        }
      } else if (type === DRAGGABLE_TYPES.frame) {
        const item: imageLibraryDragItem = monitor.getItem();
        AssetsActions.replaceExistingFrame({
          targetFrameId: props.frame.id,
          assetId: item.id,
          storyboardId: props.frame.storyboard_id,
          teamId: props.team_id,
        });
      } else if (type === DRAGGABLE_TYPES.unsplash) {
        const item: imageLibraryDragItem = monitor.getItem();
        FrameActions.updateImageStatus({
          frameId: props.frame.id,
          status: 'fetching',
        });

        // We fetch the image from unsplash, then
        fetch(item.image)
          .then((response) => response.blob())
          .then((blob) => {
            const file = new File([blob], item.image, {
              type: blob.type,
            });

            FileUploadActions.updateFrame({
              file: file,
              frame_aspect_ratio:
                StoryboardStore.getState().storyboard.frame_aspect_ratio,
              frame: props.frame,
              team_id: props.team_id,
            });
          });
      }
    },
    collect: (monitor) => ({
      // Are we currently dragging anything, anywhere in the app?
      isInvalidDrag:
        monitor.isOver() &&
        (monitor.getItemType() ? !monitor.canDrop() : false),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const className = `flex items-center justify-center absolute inset-0 ${
    props.className || ''
  }`;

  const onDropParamsChange = props.onDropParamsChange;
  React.useEffect(() => {
    onDropParamsChange({ isOver: isOver, canDrop: canDrop });
  }, [canDrop, isOver, onDropParamsChange]);

  return (
    <BoordsDropzone
      accept={accept}
      ref={ref}
      isOver={isOver}
      hasItem={false}
      multiple={false}
      isInvalid={isInvalidDrag}
      className={className}
      acceptClick={false}
      box={false}
      bg={false}
      showInvalidDropMessage
      border={false}
      showInvalidDropOverlay
    >
      {canDrop ? props.children : null}
    </BoordsDropzone>
  );
});

FrameImageDropZone.displayName = 'FrameImageDropZone';

export default FrameImageDropZone;
