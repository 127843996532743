/** @format */
import { isObject } from 'underscore';
import { createTypedActions } from '../stores/AltStore';

/** Either a message to display or an object with the
 * translation key and optional `data` object for i18next */
export type translateableNotification = {
  key: string;
  /** @deprecated in favour of `key' **/
  message?: string;
  data?: Record<string, unknown>;
  link?: string;
};

export const isTranslateableNotification = (
  props: unknown,
): props is translateableNotification => {
  return isObject(props) && Object.prototype.hasOwnProperty.call(props, 'key');
};
export interface TranslateableNotificationFunc {
  (msg: translateableNotification): void;

  /** @deprecated Please use translateable format (i.e. {@link translateableNotification}) */
  (string): void;
}

class RequestActions {
  redirect = function (url) {
    window.location.href = url;
  };

  start = function () {
    this.dispatch();
  };

  success: TranslateableNotificationFunc = function (message) {
    this.dispatch(message);
  };

  ajaxError = function (obj) {
    this.dispatch(obj);
  };

  error: TranslateableNotificationFunc = function (message) {
    this.dispatch(message);
  };

  complete = function () {
    this.dispatch();
  };
}

const actions = createTypedActions<RequestActions>(RequestActions);
export { actions as RequestActions };

(window as any).RequestActions = actions;
