/** @prettier */
/* eslint-disable react/display-name */
import classNames from 'classnames';
import * as React from 'react';
import type { DetailedFrame } from '../../types/frame';
import type { IStoryboard } from '../../types/storyboard';
import { FrameInfo } from '../frames/editor/FrameInfo';
import { type GridSize } from '../frames/FrameGrid';

export const ScriptEditorStoryboardItem = React.memo<{
  frame: DetailedFrame;
  storyboard: IStoryboard;
  isMinimal: boolean;
  gridSize: GridSize;
}>((props) => (
  <div
    className={classNames(
      'flex flex-col opacity-60 pointer-events-none frame__wrapper',
      props.gridSize === 'xs' && 'gap-2 p-1.5',
      props.gridSize === 'sm' && 'gap-2 p-2',
      props.gridSize === 'md' && 'gap-3 p-2.5',
      props.gridSize === 'lg' && 'gap-4 p-3',
      props.gridSize === 'xl' && 'gap-4 p-4',
    )}
  >
    <FrameImage
      frame={props.frame}
      storyboard={props.storyboard}
      isMultipleSelected={false}
      isCommenting={false}
      index={props.frame.number}
      isInteractive={false}
      isMinimal={props.isMinimal}
    />
    <FrameInfo
      frame={props.frame}
      isCommenting={false}
      storyboardId={props.storyboard.id}
      storyboardSlug={props.storyboard.slug}
      frame_fields={props.storyboard.frame_fields!}
      isInteractive={false}
      index={props.frame.number as any}
      isMinimal={props.isMinimal}
      gridSize={props.gridSize}
    />
  </div>
));

ScriptEditorStoryboardItem.displayName = 'ScriptEditorStoryboardItem';
