/** @prettier */
import * as React from 'react';
import type { IPanelProps } from '../../shared/MultiPanel';
import {
  FrameLabelsContainer,
  type IFrameLabelProps,
} from './FrameLabelsContainer';
import { ExportBulkDownload } from './ExportBulkDownload';
import Button from 'blackbird/components/button/Button';
import { useTranslation } from 'react-i18next';
import { IFPermissionPanel } from 'javascripts/helpers/has-permission';

interface IProps extends IPanelProps {
  storyboardOwnerId: number;
  storyboardSlug: string;
  storyboardId: number;
}

interface IHydratedProps extends IProps, IFrameLabelProps {}

const ExportAfterEffects: React.FunctionComponent<IProps> = (
  props: IHydratedProps,
) => {
  const { t } = useTranslation();
  return (
    <IFPermissionPanel
      featureName="bulk_export"
      storyboardOwnerId={props.storyboardOwnerId}
    >
      <>
        <Button
          size="sm"
          link="https://github.com/boords/Boords-Tools"
          target="_blank"
          className="w-full mb-2"
        >
          {t('export.after_effects.info_button_text')}
        </Button>
        <ExportBulkDownload {...props} />
      </>
    </IFPermissionPanel>
  );
};

ExportAfterEffects.displayName = 'StoryboardExportAfterEffects';

export const withContainer = FrameLabelsContainer(ExportAfterEffects);
