/** @prettier */
import {
  Comment,
  CommentAnnotationData,
} from '../../../javascripts/flux/stores/comment';

interface User {
  id: number;
  name: string;
  avatar?: string;
}
interface Reply {
  replyTo: number;
  reply: string;
  user: User;
}
enum CommentActionsType {
  ACTION_REPLY,
  ACTION_MORE_OPTIONS,
  ACTION_MARK_COMPLETE,
}

interface INormalizedData {
  replies: {
    byId: Record<string, Comment[]>;
  };
  comments: {
    byId: Record<string, Comment | undefined>;
  };
}

interface AddReplyPayload {
  text: string;
  name?: string;
  in_reply_to_comment_id: number;
  userId?: number;
  isTeamOnly?: boolean;
  attachment?: string | null;
  annotation?: CommentAnnotationData | null;
}
interface EditCommentPayload {
  text: string;
  id: number;
  attachment?: string | null;
  annotation?: CommentAnnotationData | null;
}
interface MarkAsCompletePayload {
  id: number;
  acknowledged?: boolean;
}

interface DeletePayload {
  id: number;
}

interface AddReplyPayloadWithFrame extends AddReplyPayload {
  frameId: number;
}
interface EditCommentPayloadWithFrame extends EditCommentPayload {
  frameId: number;
}
interface MarkAsCompletePayloadWithFrame extends MarkAsCompletePayload {
  frameId: number;
}
interface DeletePayloadWithFrame extends DeletePayload {
  frameId: number;
}
export {
  User,
  Reply,
  CommentActionsType,
  INormalizedData,
  AddReplyPayload,
  AddReplyPayloadWithFrame,
  EditCommentPayload,
  EditCommentPayloadWithFrame,
  MarkAsCompletePayload,
  MarkAsCompletePayloadWithFrame,
  DeletePayloadWithFrame,
  DeletePayload,
};
