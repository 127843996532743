/** @prettier */

import React from 'react';
import classNames from 'classnames';
import RCSlider, { type SliderProps } from 'rc-slider';

interface Props extends Omit<SliderProps, 'styles'> {
  className?: string;
  value: number;
  sliderOnly?: boolean;
  fullWidth?: boolean;
}

const Slider: React.FC<Props> = (props) => {
  const {
    sliderOnly,
    fullWidth,
    disabled,
    onChange,
    min = 0,
    max = 100,
    value = 0,
    step = 1,
  } = props;

  const stepDown = (amount = step ?? 1) =>
    !disabled && value > min && onChange && onChange(value - amount);
  const stepUp = (amount = step ?? 1) =>
    !disabled && value < max && onChange && onChange(value + amount);

  const renderMinus = () => {
    return sliderOnly ? null : (
      <div
        onClick={() => stepDown()}
        className={classNames(
          'flex w-3 h-4 items-center justify-start fill-current cursor-pointer',
          {
            'text-action-primary-disabled cursor-not-allowed':
              disabled || value <= min,
          },
        )}
      >
        <svg width="6" height="3" viewBox="0 0 6 3">
          <path d="M0 2.11421V0.926758H6V2.11421H0Z" />
        </svg>
      </div>
    );
  };

  const renderPlus = () => {
    return sliderOnly ? null : (
      <div
        onClick={() => stepUp()}
        className={classNames(
          'flex w-3 h-4 items-center justify-end fill-current cursor-pointer',
          {
            'text-action-primary-disabled cursor-not-allowed':
              disabled || value >= max,
          },
        )}
      >
        <svg width="6" height="7" viewBox="0 0 6 7">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.59999 0.947754H2.39999V3.32264H0V4.5101H2.39999V6.88504H3.59999V4.5101H6V3.32264H3.59999V0.947754Z"
          />
        </svg>
      </div>
    );
  };

  const sliderClasses = React.useMemo(
    () => ({
      rail: 'h-full absolute w-full',
      track: 'h-full absolute bg-white',
      handle: classNames(
        'absolute z-1 block w-4 h-4 border rounded-full touch-pan-x',
        'focus:border-white focus:outline-blue-tight box-border -top-1.5',
        {
          'bg-action-primary-disabled border-action-primary-disabled cursor-not-allowed':
            disabled,
        },
        {
          'bg-black border-border-dark hover:bg-icon-hover hover:border-icon-hover cursor-pointer':
            !disabled,
        },
      ),
    }),
    [disabled],
  );

  return (
    <div
      className={classNames(
        'flex h-4 flex-nowrap gap-2 items-center select-none shrink-0',
        fullWidth ? 'w-full' : 'w-40 ',
      )}
    >
      {renderMinus()}

      <RCSlider
        {...props}
        className={classNames(
          'touch-none relative w-full border-2 rounded box-border h-2  bg-black',
          {
            'border-action-primary-disabled': disabled,
          },
        )}
        classNames={sliderClasses}
      />

      {renderPlus()}
    </div>
  );
};

export default Slider;
