/** @format */

import React, { useState, useRef } from 'react';
import { getFrameImage } from 'javascripts/helpers/getFrameImage';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { RequestActions } from 'javascripts/flux/actions/request';
import { type frameAspectRatio } from 'javascripts/types/storyboard';
import Img from 'javascripts/components/shared/Img';

interface MagnifiedPreviewProps {
  aspect_ratio: frameAspectRatio;
  thumbnail_url: string;
}

export const MagnifiedPreview: React.FC<MagnifiedPreviewProps> = ({
  thumbnail_url,
  aspect_ratio,
}) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  if (!thumbnail_url || !aspect_ratio) return null;

  const width = BoordsFrameSizeHelper(aspect_ratio).width;
  const height = BoordsFrameSizeHelper(aspect_ratio).height;

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!containerRef.current) return;

    const bounds = containerRef.current.getBoundingClientRect();
    const x = ((e.clientX - bounds.left) / bounds.width) * 100;
    const y = ((e.clientY - bounds.top) / bounds.height) * 100;

    setMousePosition({
      x: Math.max(0, Math.min(100, x)),
      y: Math.max(0, Math.min(100, y)),
    });
  };

  return (
    <div
      ref={containerRef}
      className="relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onMouseMove={handleMouseMove}
    >
      <Img
        onDragStart={(e) => {
          e.stopPropagation();
          e.preventDefault();
          RequestActions.error.defer(`Click 'Insert Image' to add to frame`);
        }}
        src={getFrameImage(thumbnail_url, aspect_ratio)}
        width={width}
        height={height}
        className={isHovering ? 'invisible' : 'visible'}
      />
      {isHovering && (
        <div
          className="absolute inset-0 bg-no-repeat"
          style={{
            backgroundImage: `url(${getFrameImage(
              thumbnail_url,
              aspect_ratio,
            )})`,
            backgroundSize: '200%',
            backgroundPosition: `${mousePosition.x}% ${mousePosition.y}%`,
          }}
        />
      )}
    </div>
  );
};
