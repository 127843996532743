/** @prettier */

import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import TextInput, { TextArea } from '../../form/text-input/TextInput';
import { IconButton } from '../../common/IconButton';
import FaceSmiling from '../../../images/icons/emoji.svg';
import Button from '../../button/Button';
import Popover from '../../common/popover/Popover';
import { EmojiPicker } from '../../menus/EmojiPicker';
import { useStore } from 'javascripts/helpers/useStore';
import type { UserStore } from 'javascripts/flux/stores/user';
import type { UserResponse } from 'javascripts/types/user';
import { commentNameLocalState } from 'javascripts/helpers/local-state';
import { position, type Pos } from 'caret-pos';
import { insertAtSelection } from 'blackbird/components/form/richTextInput/insertAtSelection';
import logger from 'javascripts/helpers/logger';
import { type EventSource } from 'blackbird/helpers/eventContextHelper';
import type {
  CommentAnnotationData,
  PossibleCommentAnnotation,
} from 'javascripts/flux/stores/comment';
import { useTranslation } from 'react-i18next';
import {
  BigPopTransition,
  PopTransition,
} from 'blackbird/components/common/Transitions';
import { AnnotationToolbar } from '../annotations/AnnotationToolbar';
import { isUndefined } from 'underscore';
import type { CommentAnnotationsStore } from 'javascripts/flux/stores/commentAnnotations';
import { CommentAnnotationsActions } from 'javascripts/flux/actions/commentAnnotations';
import { openConfirmDialog } from 'javascripts/helpers/openDialog';
import { DialogContext } from 'blackbird/components/dialog/DialogContext';
import { useRollout } from 'javascripts/helpers/rollout';
import { useStateWithRef } from 'javascripts/helpers/useStateWithRef';
import { AnnotationToggle } from '../annotations/AnnotationToggle';
import { CommentEditorTeamOnlyToggle } from './CommentEditorTeamOnlyToggle';
import { ImagePlus, X } from 'lucide-react';
import { FilestackActions } from 'javascripts/flux/actions/filestack';
import { type PickerFileMetadata } from 'filestack-js';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import { transformFilestackUrl } from 'javascripts/helpers/transformFilestackImage';
import { openLightbox } from 'javascripts/helpers/lightbox';

export interface CommentEditorProps {
  className?: string;
  onSubmit: CommentEditorSubmithandler;
  placeholder?: string;
  value?: string;
  autoFocus?: boolean;
  isEditingTeamOnly?: boolean;
  onClose?: (
    /** Indicates if this close event was caused by an onBlur event, rather than
     * clicking the close button or a form submit. */
    closeWasOnBlur: boolean,
  ) => void;
  disabled?: boolean;
  inputSize?: React.ComponentProps<typeof TextArea>['inputSize'];
  customBorder?: boolean;
  inputRef?: React.MutableRefObject<HTMLDivElement | null>;

  attachment: string | null;
  /** The name of the author of the comment, in case that is not the current user. */
  userOverride?: string;
  /** Should we show the name field if it's necessary? */
  allowNameField: boolean;
  /** Reference to the comment we're editing, or `null` when it's a new one */
  commentId: number | null;
  annotation: PossibleCommentAnnotation;
  /** Can annotations be added using this editor? */
  allowAnnotation: boolean;
}

export type CommentEditorSubmithandler = (props: {
  text: string;
  context?: EventSource;
  /** This will be filled in most of the times */
  name?: string;
  isTeamOnly: boolean;
  attachment: string | null;
  annotation: null | CommentAnnotationData;
}) => void;

const CommentEditor = React.memo<CommentEditorProps>((props) => {
  const { onClose } = props;
  const user = useStore<UserResponse | undefined, UserStore>(
    'userStore',
    (u) => u.user,
  );
  const isAnnotationEditorAvailable = useStore<
    boolean,
    CommentAnnotationsStore
  >('commentAnnotations', (s) => s.isEditorAvailable);
  /** Is this comment editor editing the annotation that is currently visible */
  const isEditingAnnotation = useStore<boolean, CommentAnnotationsStore>(
    'commentAnnotations',
    (s) => {
      const isEditingANewComment =
        s.currentAnnotation?.id === null && props.commentId === null;
      const isEditingTheSameComment =
        s.currentAnnotation?.id?.commentId === props.commentId;
      return (
        s.editorState === 'editing' &&
        (isEditingANewComment || isEditingTheSameComment)
      );
    },
  );
  const dialogContext = React.useContext(DialogContext);

  const needsNameField = props.allowNameField && !user;
  const defaultName =
    props.userOverride ?? (!user && needsNameField)
      ? commentNameLocalState.getValue()
      : undefined;

  const [open, setOpen] = useState(false);
  const [isTeamOnly, setIsTeamOnly] = useState(false);
  const wasOpen = React.useRef(open);
  const [emojiPopoverVisible, setEmojiPopoverVisible, emojiPopoverRef] =
    useStateWithRef(false);
  const [text, setText] = useState(props.value ?? '');
  const [error, setError] = useState<string | undefined>();
  const [attachment, setAttachment] = useState<string | null>(
    !props.attachment ? null : props.attachment,
  );
  const [name, setName] = useState<string | undefined>(defaultName);
  const isClosing = useRef(false);
  const internalRef = useRef<HTMLDivElement | null>(null);
  const inputRef = props.inputRef ?? internalRef;
  const nameFieldRef = useRef<HTMLInputElement | null>(null);
  const hasUserOverride = props.userOverride && props.userOverride.length > 0;
  const caretPosition = React.useRef<Pos | null>();
  const { t } = useTranslation();
  const annotationsRollout = useRollout('Annotations');
  const annotationsStore: CommentAnnotationsStore = (window as any)
    .CommentAnnotationsStore;

  /** In case we want to clear the annotation */
  const [annotation, setAnnotation] = React.useState<PossibleCommentAnnotation>(
    props.annotation,
  );

  // When we're opening the editor on a comment with an annotation attached, we
  // want to open that annotation
  React.useEffect(() => {
    // If we're opening, but weren't open before
    if (open && !wasOpen.current) {
      if (props.commentId && props.annotation) {
        CommentAnnotationsActions.open.defer({
          commentId: props.commentId
            ? { commentId: props.commentId, frameId: null }
            : null,
          interactive: true,
          silent: true,
        });
      } else if (props.allowAnnotation) {
        // If we're opening a comment editor, we want to hide any active
        // annotations to prevent confusion.
        CommentAnnotationsActions.close.defer();
      }
    }

    wasOpen.current = open;
  }, [open, props.commentId, props.annotation, props.allowAnnotation]);

  const handleClose = React.useCallback(
    async (parameter: React.MouseEvent | boolean) => {
      const closeWasOnBlur = parameter === true;
      isClosing.current = true;
      const hasChanges = annotationsStore.getState().engine?.hasChanges;

      if (!isEditingAnnotation) {
        setOpen(false);
        onClose && onClose(closeWasOnBlur);
      } else if (
        !hasChanges ||
        (await openConfirmDialog(
          {
            title: t('annotations.abandon.title'),
            description: t('annotations.abandon.description'),
          },
          dialogContext,
        ))
      ) {
        if (props.commentId) {
          CommentAnnotationsActions.open.defer({
            commentId: { commentId: props.commentId, frameId: null },
            interactive: false,
            silent: true,
          });
        } else {
          CommentAnnotationsActions.close.defer();
        }

        setOpen(false);
        inputRef.current?.blur();
        onClose && onClose(closeWasOnBlur);
      }

      isClosing.current = false;
    },
    [
      annotationsStore,
      isEditingAnnotation,
      t,
      dialogContext,
      onClose,
      props.commentId,
      inputRef,
    ],
  );

  const handleSubmit: React.EventHandler<
    React.KeyboardEvent | React.FormEvent
  > = (e) => {
    const nameToSubmit =
      name && name.length > 0
        ? name
        : hasUserOverride
        ? props.userOverride
        : user?.name;

    e.preventDefault();
    if (!nameToSubmit && needsNameField) {
      setError('You must enter a name');
      return;
    }

    /** Preparing the data we want to send back onSubmit */
    const data: Parameters<typeof props.onSubmit>[0] = {
      name: nameToSubmit,
      text: text.trim(),
      /** If someone edits the comment without touching the annotation, we want
       * to send back the annotation, unless it's been set to null */
      annotation: annotation,
      isTeamOnly: isTeamOnly,
      attachment: attachment,
    };

    const closeAndSubmit = () => {
      setText('');
      handleClose(false);
      setAttachment(null);
      props.onSubmit(data);
    };

    if (isEditingAnnotation) {
      CommentAnnotationsActions.close.defer((annotation) => {
        // We receive undefined here when we don't have any changes to report
        if (!isUndefined(annotation)) {
          data.annotation = annotation ?? null;
        }
        closeAndSubmit();
      });
    } else {
      closeAndSubmit();
    }
  };

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      handleSubmit(e);
    } else if (e.key === 'Escape') {
      handleClose(false);
    }
  };

  const toggleEmojiPopover = React.useCallback(
    () =>
      setEmojiPopoverVisible((isVisible) => {
        const newState = !isVisible;
        const hasSelection = !!window.getSelection()?.rangeCount;
        // Sometimes internalRef.current is undefined, or the position function
        // below will crash when there's no selection, so we avoid those
        if (newState && internalRef.current && hasSelection) {
          caretPosition.current = position(internalRef.current);
        } else if (!internalRef.current) {
          logger.log('internalRef is undefined?');
        }
        return newState;
      }),
    [setEmojiPopoverVisible],
  );

  const handleAnnotationToolToggle =
    React.useCallback<React.MouseEventHandler>(async () => {
      const hasChanges = annotationsStore.getState().engine?.hasChanges;

      if (
        !hasChanges ||
        (await openConfirmDialog(
          {
            title: t('annotations.abandon.title'),
            description: t('annotations.abandon.description'),
          },
          dialogContext,
        ))
      ) {
        CommentAnnotationsActions.toggle.defer({
          commentId: props.commentId
            ? { commentId: props.commentId, frameId: null }
            : null,
          interactive: true,
        });
      }
    }, [dialogContext, props.commentId, t, annotationsStore]);

  const handleEmojiSelect = (emoji: { native: string }) => {
    const node = inputRef.current;
    if (!node) return;

    node.focus();
    setEmojiPopoverVisible(false);
    if (caretPosition.current) {
      position(node, caretPosition.current?.pos);
    } else {
      logger.log('caretPosition can not be found, using end of the text');
      var range = document.createRange();
      range.selectNodeContents(node);
      range.collapse(false);

      var selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);
    }

    insertAtSelection(emoji.native);
  };

  const handleClearAnnotation = React.useCallback(() => {
    setAnnotation(null);
    CommentAnnotationsActions.close.defer();
  }, []);

  // const handleBlur = React.useCallback(() => {
  //   const nameFieldHasFocus = document.activeElement === nameFieldRef.current;

  //   if (
  //     !isEditingAnnotation &&
  //     !emojiPopoverRef.current &&
  //     !nameFieldHasFocus
  //   ) {
  //     handleClose(true);
  //   }
  // }, [handleClose, isEditingAnnotation, emojiPopoverRef]);

  const requiresUpgrade =
    BoordsConfig.Freeloader || BoordsConfig.IsProfessionalFree;

  const isCreatingOrEditingTeamOnly = isTeamOnly || props.isEditingTeamOnly;

  return (
    <form onSubmit={handleSubmit} className="w-full">
      {needsNameField && open && (
        <TextInput
          autoComplete="given-name"
          placeholder="Your name"
          ref={nameFieldRef}
          value={name ?? ''}
          className="mb-2 bg-white"
          inputClassName="text-sm"
          onChange={(e) => setName(e.currentTarget.value)}
          required
          error={error}
        />
      )}
      <div
        className={classNames(
          'relative bg-white w-full rounded-md',
          isCreatingOrEditingTeamOnly && 'border border-trial-300',
          {
            'border border-black':
              !props.customBorder && !isCreatingOrEditingTeamOnly,
          },
          props.className,
        )}
      >
        <TextArea
          disabled={props.disabled}
          ref={inputRef}
          className={classNames(
            'text-sm prose',
            isCreatingOrEditingTeamOnly && 'text-teamonly',
          )}
          placeholder={props.placeholder ?? 'Add new comment'}
          // onBlur={handleBlur}
          onChange={(newValue) => setText(newValue)}
          onFocus={() => setOpen(true)}
          inputSize={props.inputSize}
          onKeyDownCapture={handleKeyDown}
          textAreaClassName={classNames(attachment && '!pr-16')}
          rightComponent={
            open && (
              <div className="flex items-start">
                {attachment ? (
                  <div
                    className="relative w-10 h-10 border rounded-md border-border group/attachment cursor-zoom-in"
                    onClick={() => openLightbox(attachment as string)}
                    style={{
                      backgroundImage: `url(${transformFilestackUrl({
                        url: attachment,
                        width: 80,
                      })})`,
                      backgroundSize: 'cover',
                    }}
                  >
                    <span
                      className="absolute flex items-center justify-center w-4 h-4 border rounded-full opacity-0 cursor-pointer -top-1 -right-1 bg-surface border-border group-hover/attachment:opacity-100"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setAttachment(null);
                      }}
                    >
                      <X className="w-2 h-2" />
                    </span>
                  </div>
                ) : (
                  <Tooltip
                    title={`Add attachment`}
                    placement={`left`}
                    distance={5}
                  >
                    <span
                      className="p-1 cursor-pointer"
                      onClick={() =>
                        FilestackActions.openCommentAttachment({
                          callback: (file: PickerFileMetadata) => {
                            Track.event.defer('comment_attachment_added');
                            setAttachment(`${file.url}`);
                          },
                        })
                      }
                    >
                      <ImagePlus className="w-4 h-4" />
                    </span>
                  </Tooltip>
                )}
                {/* <Popover */}
                {/*   isOpen={emojiPopoverVisible} */}
                {/*   portal */}
                {/*   onClose={() => setEmojiPopoverVisible(false)} */}
                {/*   observeMutations={true} */}
                {/*   distance={5} */}
                {/* > */}
                {/*   <Popover.Button> */}
                {/*     <BigPopTransition show={open} className="delay-75" appear> */}
                {/*       <IconButton */}
                {/*         className="ml-1" */}
                {/*         aria-label="Add an emoji" */}
                {/*         onClick={toggleEmojiPopover} */}
                {/*         icon={<FaceSmiling />} */}
                {/*         color={emojiPopoverVisible ? 'black' : 'subdued'} */}
                {/*       /> */}
                {/*     </BigPopTransition> */}
                {/*   </Popover.Button> */}

                {/*   <Popover.Panel className="overflow-hidden shadow-lg z-sidebar"> */}
                {/*     {({ popperElement }) => ( */}
                {/*       <EmojiPicker */}
                {/*         popperElement={popperElement} */}
                {/*         onSelect={handleEmojiSelect} */}
                {/*       /> */}
                {/*     )} */}
                {/*   </Popover.Panel> */}
                {/* </Popover> */}
              </div>
            )
          }
          value={text}
          aria-label="Comment field"
          noBorder
          maxRows={5}
          autoFocus={props.autoFocus}
          immediate
        />
        {open && (
          <div className="py-2">
            {/* We want to prevent the toolbar from popping up when another comment field starts annotating */}
            <PopTransition show={isEditingAnnotation && props.allowAnnotation}>
              <AnnotationToolbar
                onDelete={handleClearAnnotation}
                compact={!!props.commentId}
              />
            </PopTransition>

            <div className="flex items-center justify-end gap-1.5 pr-3">
              {props.allowAnnotation && annotationsRollout ? (
                <div className="flex items-center flex-auto pl-2 space-x-2">
                  {!props.value && (
                    <CommentEditorTeamOnlyToggle
                      setIsTeamOnly={setIsTeamOnly}
                    />
                  )}
                  <AnnotationToggle
                    color={requiresUpgrade ? 'subdued' : 'black'}
                    disabled={!isAnnotationEditorAvailable}
                    active={isEditingAnnotation}
                    onClick={(e) =>
                      requiresUpgrade
                        ? FlyoverActions.open.defer({ type: 'inlinePricing' })
                        : handleAnnotationToolToggle(e)
                    }
                    label={t(
                      `annotations.buttonLabel.` +
                        (requiresUpgrade
                          ? 'upgradeRequired'
                          : isAnnotationEditorAvailable
                          ? isEditingAnnotation
                            ? 'close'
                            : 'available'
                          : 'unavailable'),
                    )}
                  />
                </div>
              ) : !props.value ? (
                <div className="flex-auto pl-2">
                  <CommentEditorTeamOnlyToggle setIsTeamOnly={setIsTeamOnly} />
                </div>
              ) : null}
              <Button
                type="secondary"
                size="xs"
                onClick={handleClose}
                className={classNames(
                  isCreatingOrEditingTeamOnly &&
                    'bg-trial-100 hover:bg-trial-200',
                )}
              >
                {`Close`}
              </Button>
              <Button
                type="solid"
                size="xs"
                htmlType="submit"
                className={classNames(
                  isCreatingOrEditingTeamOnly &&
                    'bg-trial-700 hover:bg-trial-800',
                )}
                disabled={!text || props.disabled}
              >
                {`Send`}
              </Button>
            </div>
          </div>
        )}
      </div>
    </form>
  );
});

export default CommentEditor;
CommentEditor.displayName = 'CommentEditor';
