/** @format */
import React from 'react';
import {
  type LucideIcon,
  MessageCircle,
  HelpCircle,
  SquareActivity,
  ChevronUp,
  Radio,
  Bell,
  Lightbulb,
} from 'lucide-react';
import { Listbox } from '@headlessui/react';
import Panel from 'blackbird/components/panel/Panel';
import classNames from 'classnames';
import { rollbar as Rollbar } from 'javascripts/helpers/rollbar';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import logger from 'javascripts/helpers/logger';

interface HelpAction {
  label: string;
  icon: LucideIcon;
  href?: string;
  onClick?: () => void;
  hasDivider?: boolean;
  attributes?: Record<string, string>;
}

declare global {
  interface Window {
    Intercom?: (...args: any[]) => void;
    Canny?: (...args: any[]) => void;
  }
}

const ALLOWED_FEATURE_REQUEST_PLANS = [
  'studio',
  'company',
  'standard',
  'business',
  'workflow',
  'group',
  'agency',
  'powerhouse',
  'team',
  'companyplus',
  'plus',
] as const;

const HIDDEN_PATHS = ['/player/', '/editor'] as const;

const shouldShowLauncher = (path: string): boolean => {
  return !HIDDEN_PATHS.some((hiddenPath) => path.includes(hiddenPath));
};

const HelpLauncher: React.FC = () => {
  const [isVisible, setIsVisible] = React.useState(() =>
    shouldShowLauncher(window.location.pathname),
  );
  const [hasPanelbar, setHasPanelbar] = React.useState(() =>
    document.body.classList.contains('has--panelbar'),
  );

  const showFeatureRequests = React.useMemo(() => {
    if (BoordsConfig.StoryboardTeamPlanSlug) {
      return ALLOWED_FEATURE_REQUEST_PLANS.includes(
        BoordsConfig.StoryboardTeamPlanSlug as any,
      );
    } else if (BoordsConfig.PlanSlug) {
      return ALLOWED_FEATURE_REQUEST_PLANS.includes(
        BoordsConfig.PlanSlug as any,
      );
    } else {
      return false;
    }
  }, []);

  React.useEffect(() => {
    // Observer for body class changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          setHasPanelbar(document.body.classList.contains('has--panelbar'));
        }
      });
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class'],
    });

    return () => observer.disconnect();
  }, []);

  React.useEffect(() => {
    const checkVisibility = () => {
      setIsVisible(shouldShowLauncher(window.location.pathname));
    };

    window.addEventListener('popstate', checkVisibility);

    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(this, args);
      checkVisibility();
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(this, args);
      checkVisibility();
    };

    return () => {
      window.removeEventListener('popstate', checkVisibility);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  React.useEffect(() => {
    if (typeof window.Canny !== 'undefined') {
      window.Canny('initChangelog', {
        appID: '628ca0b2df91460b6aab8a6e',
        position: 'top',
        align: 'right',
        theme: 'light',
      });
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  const handleChatSupport = () => {
    if (typeof window.Intercom !== 'undefined') {
      window.Intercom('show');
    } else {
      Rollbar.error(`Chat icon clicked, Intercom not loaded`);
      window.location.href = 'mailto:hello@boords.com';
    }
  };

  const options: HelpAction[] = [
    {
      label: `What's new?`,
      icon: Radio,
      href: 'https://boords.canny.io/changelog',
      attributes: {
        'data-canny-link': '',
      },
      hasDivider: !showFeatureRequests,
    },
    ...(showFeatureRequests
      ? [
          {
            label: 'Feature requests',
            icon: Lightbulb,
            href: 'https://boords.canny.io/feature-requests',
            attributes: {
              'data-canny-link': '',
            },
            hasDivider: true,
          },
        ]
      : []),
    {
      label: 'System status',
      icon: SquareActivity,
      href: 'http://status.boords.com',
    },
    {
      label: 'Help center',
      icon: HelpCircle,
      href: 'https://help.boords.com',
    },
    {
      label: 'Chat with support',
      icon: MessageCircle,
      onClick: handleChatSupport,
    },
  ];

  const handleChange = (action: HelpAction) => {
    if (action.onClick) {
      action.onClick();
    }
  };

  const optionClassName = ({ active }: { active: boolean }) =>
    `cursor-pointer select-none relative pb-2 pt-1.5 px-2 rounded-md flex items-center space-x-2 ${
      active ? 'bg-surface-light' : ''
    }`;

  const renderOptionContent = (option: HelpAction) => (
    <>
      <option.icon className="h-4 w-4 pt-0.5" />
      <span className="text-sm">{option.label}</span>
    </>
  );

  return (
    <div
      className={classNames(
        `fixed bottom-4 z-helpLauncher mr-4 flex justify-end space-x-1 items-end`,
        hasPanelbar ? 'align-right-sidebar' : 'right-0',
      )}
    >
      {/* Canny changelog icon */}
      {Boolean(BoordsConfig.Uid) && (
        <Tooltip placement="left" title={`What's new?`}>
          <div
            className="flex items-center justify-center w-6 h-6 rounded-full bg-white hover:scale-[105%] transition-transform border border-border-mid cursor-pointer"
            data-canny-changelog
            onClick={() => {}}
          >
            <Bell className="w-3 h-3 text-type-subdued" />
          </div>
        </Tooltip>
      )}
      <Listbox
        as="div"
        className="relative group/helplauncher"
        value={null}
        onChange={handleChange}
      >
        {({ open }) => (
          <>
            <Tooltip
              placement="top-end"
              title={`Help & support`}
              disabled={open}
            >
              <Listbox.Button className="flex items-center justify-center w-6 h-6 rounded-full bg-surface-add_frame hover:scale-[105%] transition-transform border border-border-mid">
                {open ? (
                  <span className="">
                    <ChevronUp className="w-3 h-3" />
                  </span>
                ) : (
                  <span className="text-sm leading-[0px] text-type-subdued pb-0.5">{`?`}</span>
                )}
              </Listbox.Button>
            </Tooltip>
            <Listbox.Options
              as={Panel}
              className="absolute right-0 flex-col w-48 mb-2 border shadow-md bottom-full rounded-md border-border focus:outline-none"
            >
              <div className="p-2 space-y-1">
                {options.map((option, index) => (
                  <React.Fragment key={option.label}>
                    <Listbox.Option value={option} as="div">
                      {({ active }) =>
                        option.href ? (
                          <a
                            href={option.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={optionClassName({ active })}
                            {...option.attributes}
                          >
                            {renderOptionContent(option)}
                          </a>
                        ) : (
                          <div className={optionClassName({ active })}>
                            {renderOptionContent(option)}
                          </div>
                        )
                      }
                    </Listbox.Option>
                    {option.hasDivider && (
                      <div className="my-4 border-b border-border" />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};

(window as any).HelpLauncher = HelpLauncher;
