/** @format */
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { fabric } from 'fabric';
import logger from 'javascripts/helpers/logger';
import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import type { DetailedFrame, WidthAndHeight } from 'javascripts/types/frame';
import { type IStoryboard } from 'javascripts/types/storyboard';
import { rollbar } from 'javascripts/helpers/rollbar';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import { FrameActions } from 'javascripts/flux/actions/frame';
import { FabricOriginalEraserBrush } from 'javascripts/components/frame_editor/shapes';
import { GeneratorContext } from '../generator/GeneratorContext';
import { inpaintBrushSizeLocalState } from 'javascripts/helpers/local-state';
const errorHandler = RequestErrorHandler('generator');

type DrawingMode = 'brush' | 'eraser';

// Helper functions remain the same
const getImageDimensions = (
  url: string,
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.onerror = reject;
    img.src = url;
  });
};

const teamId = BoordsConfig.StoryboardTeamId || BoordsConfig.TeamId;

const resizeBase64Image = (
  base64: string,
  targetWidth: number,
  targetHeight: number,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Could not get canvas context'));
        return;
      }
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
      resolve(canvas.toDataURL('image/png'));
    };
    img.onerror = reject;
    img.src = base64;
  });
};

interface InpaintPreview {
  id: string;
  large_url: string;
  thumbnail_url: string;
  created_at: Date;
}

interface InpaintPreviewApiResponse {
  data: {
    attributes: {
      error: string | undefined;
      previews?: InpaintPreview[];
    };
  }[];
}

type InpaintError = undefined | string;

interface InpaintContextProps {
  drawingMode: DrawingMode;
  setDrawingMode: React.Dispatch<React.SetStateAction<DrawingMode>>;
  toggleDrawingMode: () => void;
  isAvailable: boolean;
  setIsAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  isCanvasEmpty: boolean;
  setIsCanvasEmpty: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage: InpaintError;
  setErrorMessage: React.Dispatch<React.SetStateAction<InpaintError>>;
  frame: DetailedFrame | null;
  setFrame: React.Dispatch<React.SetStateAction<DetailedFrame | null>>;
  storyboard: IStoryboard | null;
  setStoryboard: React.Dispatch<React.SetStateAction<IStoryboard | null>>;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  brushSize: number;
  setBrushSize: React.Dispatch<React.SetStateAction<number>>;
  undoStack: string[];
  setUndoStack: React.Dispatch<React.SetStateAction<string[]>>;
  redoStack: string[];
  setRedoStack: React.Dispatch<React.SetStateAction<string[]>>;
  canvas: fabric.Canvas | null;
  initializeCanvas: (
    canvasElement: HTMLCanvasElement,
    dimensions: WidthAndHeight,
  ) => void;
  updateCanvasDimensions: (dimensions: WidthAndHeight) => void;
  updateBrushSize: (size: number) => void;
  disposeCanvas: () => void;
  mask: string | null;
  setMask: React.Dispatch<React.SetStateAction<string | null>>;
  getMask: () => string | null;
  isProcessing: boolean;
  handleInsert: () => Promise<string | undefined>;
  handleBack: () => void;
  handleDiscard: () => void;
  handleCancel: () => Promise<void>;
  startWorker: () => Promise<void>;
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  inpaintPreview: InpaintPreview | undefined;
  setInpaintPreview: React.Dispatch<
    React.SetStateAction<InpaintPreview | undefined>
  >;
  PollInpaint: (parameters?: any) => Promise<void>;
}

const InpaintContext = createContext<InpaintContextProps | undefined>(
  undefined,
);

export const useInpaint = () => {
  const context = useContext(InpaintContext);
  if (context === undefined) {
    throw new Error('useInpaint must be used within an InpaintProvider');
  }
  return context;
};

export const InpaintProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { UseToken } = useContext(GeneratorContext);

  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [frame, setFrame] = useState<DetailedFrame | null>(null);
  const [storyboard, setStoryboard] = useState<IStoryboard | null>(null);
  const [inpaintPreview, setInpaintPreview] = useState<
    InpaintPreview | undefined
  >(undefined);
  const [errorMessage, setErrorMessage] = useState<InpaintError>(undefined);
  const [drawingMode, setDrawingMode] = useState<DrawingMode>('brush');

  const [mask, setMask] = useState<string | null>(null);
  const [prompt, setPrompt] = useState<string>('');
  const [isVisible, setIsVisible] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [brushSize, setBrushSize] = useState(() => {
    const savedSize = inpaintBrushSizeLocalState.getValue();
    return savedSize ?? 40;
  });
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [undoStack, setUndoStack] = useState<string[]>([]);
  const [redoStack, setRedoStack] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const brushRef = useRef<any>(null);
  const eraserRef = useRef<any>(null);

  const canvasRef = useRef<fabric.Canvas | null>(null);

  const updateBrushSettings = useCallback(
    (brush: any) => {
      if (!brush) return;

      brush.width = brushSize;
      if (drawingMode === 'brush') {
        brush.color = '#ff1493';
        // Rounded circle for custom cursor
        // data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxIi8+PC9zdmc+
      }
    },
    [brushSize, drawingMode],
  );
  React.useEffect(() => {
    inpaintBrushSizeLocalState.setValue(brushSize);
  }, [brushSize]);

  const setDrawingModeWithBrush = useCallback(
    (mode: DrawingMode) => {
      if (!canvasRef.current) return;

      // Update the drawing mode state
      setDrawingMode(mode);

      // Switch between brush and eraser based on the mode
      if (mode === 'brush') {
        if (!brushRef.current) {
          brushRef.current = new fabric.PencilBrush(canvasRef.current);
        }
        brushRef.current.width = brushSize; // Set width every time we switch
        canvasRef.current.freeDrawingBrush = brushRef.current;
        canvasRef.current.freeDrawingBrush.color = '#ff1493';
      } else {
        if (!eraserRef.current) {
          eraserRef.current = new FabricOriginalEraserBrush(canvasRef.current);
        }
        eraserRef.current.width = brushSize; // Set width every time we switch
        canvasRef.current.freeDrawingBrush = eraserRef.current;
      }
    },
    [brushSize],
  );

  // Update the toggleDrawingMode to use the new method
  const toggleDrawingMode = useCallback(() => {
    const newMode = drawingMode === 'brush' ? 'eraser' : 'brush';
    setDrawingModeWithBrush(newMode);
  }, [drawingMode, setDrawingModeWithBrush]);

  const initializeCanvas = useCallback(
    (canvasElement: HTMLCanvasElement, dimensions: WidthAndHeight) => {
      // logger.log(`🆕 initializeCanvas`);
      const fabricCanvas = new fabric.Canvas(canvasElement, {
        isDrawingMode: true,
        backgroundColor: 'transparent',
        preserveObjectStacking: true,
        width: dimensions.width,
        height: dimensions.height,
      });

      // Initialize PSBrush
      const brush = new fabric.PencilBrush(fabricCanvas);

      updateBrushSettings(brush);
      fabricCanvas.freeDrawingBrush = brush;
      brushRef.current = brush;

      // Pre-initialize eraser
      const eraser = new FabricOriginalEraserBrush(fabricCanvas);
      updateBrushSettings(eraser);
      eraserRef.current = eraser;

      // Save state after each path is drawn
      fabricCanvas.on('path:created', () => {
        fabricCanvas.renderAll();
        const currentState = JSON.stringify(fabricCanvas.toJSON());
        setUndoStack((prev) => [...prev, currentState]);
        setRedoStack([]); // Clear redo stack when new action is performed
        setIsCanvasEmpty(false);
      });

      // Save initial state
      const initialState = JSON.stringify(fabricCanvas.toJSON());
      setUndoStack([initialState]);

      canvasRef.current = fabricCanvas;
      setCanvas(fabricCanvas);
    },
    [brushSize, isProcessing, inpaintPreview],
  );

  // Update brush when size changes
  React.useEffect(() => {
    if (drawingMode === 'brush' && brushRef.current) {
      updateBrushSettings(brushRef.current);
    } else if (drawingMode === 'eraser' && eraserRef.current) {
      updateBrushSettings(eraserRef.current);
    }
  }, [brushSize]);

  const updateCanvasDimensions = useCallback((dimensions: WidthAndHeight) => {
    if (canvasRef.current) {
      canvasRef.current.setDimensions({
        width: dimensions.width,
        height: dimensions.height,
      });
      canvasRef.current.renderAll();
    }
  }, []);

  const updateBrushSize = useCallback((size: number) => {
    if (canvasRef.current && canvasRef.current.freeDrawingBrush) {
      canvasRef.current.freeDrawingBrush.width = size;
      canvasRef.current.freeDrawingBrush.circleRadius = size / 2;
    }
  }, []);

  const disposeCanvas = useCallback(() => {
    if (canvasRef.current) {
      // logger.log(`🗑️ disposeCanvas`);
      canvasRef.current.dispose();
      canvasRef.current = null;
      setPrompt('');
      setIsCanvasEmpty(true);
      setCanvas(null);
      setMask(null);
    }
  }, []);

  const getMask = useCallback((): string | null => {
    if (!canvasRef.current) return null;

    // Create a temp canvas
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = canvasRef.current.width || 0;
    tempCanvas.height = canvasRef.current.height || 0;
    const tempCtx = tempCanvas.getContext('2d');

    if (!tempCtx) return null;

    // First get the actual Fabric.js canvas content
    const fabricCanvasEl = canvasRef.current.toCanvasElement();

    // Draw the Fabric canvas content onto our temp canvas
    tempCtx.drawImage(fabricCanvasEl, 0, 0);

    // Get the image data
    const imageData = tempCtx.getImageData(
      0,
      0,
      tempCanvas.width,
      tempCanvas.height,
    );
    const data = imageData.data;

    // Convert any color to white with 100% opacity
    for (let i = 0; i < data.length; i += 4) {
      if (data[i + 3] > 0) {
        // If pixel has any opacity at all
        data[i] = 255; // R
        data[i + 1] = 255; // G
        data[i + 2] = 255; // B
        data[i + 3] = 255; // A (full opacity)
      }
    }

    // Put the modified image data back
    tempCtx.putImageData(imageData, 0, 0);

    // Store the original colored mask for the frontend
    const coloredMask = canvasRef.current.toDataURL('image/png');

    setMask(coloredMask);

    // Return the white version for server processing
    return tempCanvas.toDataURL('image/png');
  }, []);

  React.useEffect(() => {
    if (isProcessing) {
      const interval = setInterval(() => {
        PollInpaint({ firstRun: false });
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isProcessing]);

  React.useEffect(() => {
    if (frame && frame.inpaint_processing) {
      setIsProcessing(frame.inpaint_processing);
    }
  }, [frame]);

  const handleBack = useCallback(() => {
    setInpaintPreview(undefined);
  }, []);

  const startWorker = useCallback(async () => {
    if (frame && storyboard) {
      try {
        setIsProcessing(true);

        const maskBase64 = getMask();

        if (!maskBase64) {
          handleError('No mask present');
          return;
        }

        const sourceImage = frame.large_image_url;

        // Get dimensions and resize mask
        const dimensions = await getImageDimensions(sourceImage);
        const resizedMask = await resizeBase64Image(
          maskBase64,
          dimensions.width,
          dimensions.height,
        );

        // Start the inpainting worker
        const request = await apiRequest({
          path: `frames/${frame.id}/inpaint`,
          method: 'post',
          payload: {
            team_id: teamId,
            mask_url: resizedMask,
            image_url: sourceImage,
            prompt: prompt,
            aspect_ratio: storyboard.frame_aspect_ratio,
          },
        });

        if (!request.ok) {
          throw new Error('Failed to start inpaint worker');
        }

        Track.event.defer('inpaint_run', {
          posthogCapture: true,
        });
      } catch (error) {
        handleError(error);
      }
    } else {
      handleError('No frame present!');
    }
  }, [frame, getMask, storyboard, prompt]);

  const handleError = (error: InpaintError | Error) => {
    if (typeof error === 'object') {
      setErrorMessage(error.message);
    } else {
      setErrorMessage(error);
    }

    setIsProcessing(false);
    logger.error(error);
    rollbar.error(error);
  };

  const handleCancel = useCallback(async () => {
    if (frame) {
      try {
        const request = await apiRequest({
          path: `frames/${frame.id}/inpaint_preview/cancel`,
          method: 'delete',
        });
        if (!request.ok) return errorHandler({ method: 'get' })(request);

        setIsProcessing(false);
        setInpaintPreview(undefined);
      } catch (e) {
        handleError(e);
      }
    } else {
      handleError('Missing frame');
    }
  }, [frame]);

  const handleDiscard = useCallback(async () => {
    if (frame) {
      try {
        const request = await apiRequest({
          path: `frames/${frame.id}/inpaint_preview/clear`,
          method: 'delete',
        });

        if (!request.ok) return errorHandler({ method: 'delete' })(request);

        setIsProcessing(false);
        setInpaintPreview(undefined);
      } catch (e) {
        handleError(e);
      }
    } else {
      handleError('Missing frame');
    }
  }, [frame]);

  const handleInsert = useCallback(async () => {
    if (frame && inpaintPreview) {
      try {
        const request = await apiRequest({
          path: `frames/${frame.id}/inpaint_preview/${inpaintPreview.id}`,
          method: 'put',
        });

        if (!request.ok) return errorHandler({ method: 'put' })(request);

        FrameActions.updateFrameImageUrls.defer({
          frame: frame,
          background_image_url: inpaintPreview.large_url,
          large_image_url: inpaintPreview.large_url,
          thumbnail_image_url: inpaintPreview.thumbnail_url,
        });

        // Clear canvas
        if (canvasRef.current) {
          canvasRef.current.clear();
          canvasRef.current.renderAll();

          // Reset canvas state
          const initialState = JSON.stringify(canvasRef.current.toJSON());
          setUndoStack([initialState]);
          setRedoStack([]);
          setIsCanvasEmpty(true);
        }

        UseToken();
        setInpaintPreview(undefined);
        setMask(null);
        setPrompt('');

        Track.event.defer('inpaint_insert', {
          posthogCapture: true,
        });
      } catch (e) {
        handleError(e);
      }
    } else {
      handleError('Missing frame/preview');
    }
  }, [frame, inpaintPreview]);

  const PollInpaint = useCallback(
    async ({ firstRun = false }) => {
      try {
        if (frame) {
          const request = await apiRequest({
            path: `frames/${frame.id}/inpaint_preview`,
            method: 'get',
          });

          const response: InpaintPreviewApiResponse = await request.json();

          const { previews, error } = response.data[0].attributes;

          if (error && !firstRun) {
            handleError(error);
            setInpaintPreview(undefined);
          } else if (previews && previews.length > 0) {
            setInpaintPreview(previews[previews.length - 1]);
            setIsProcessing(false);
          } else {
            setInpaintPreview(undefined);
          }
        }
      } catch (e) {
        handleError(e);
      }
    },
    [frame],
  );

  const value = {
    PollInpaint,
    frame,
    setFrame,
    isVisible,
    setIsVisible,
    brushSize,
    setBrushSize,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
    canvas,
    initializeCanvas,
    updateCanvasDimensions,
    updateBrushSize,
    disposeCanvas,
    getMask,
    isProcessing,
    startWorker,
    storyboard,
    setStoryboard,
    prompt,
    setPrompt,
    inpaintPreview,
    setInpaintPreview,
    errorMessage,
    setErrorMessage,
    handleBack,
    handleCancel,
    handleInsert,
    isCanvasEmpty,
    setIsCanvasEmpty,
    mask,
    setMask,
    drawingMode,
    setDrawingMode: setDrawingModeWithBrush,
    toggleDrawingMode,
    handleDiscard,
    isAvailable,
    setIsAvailable,
  };

  return (
    <InpaintContext.Provider value={value}>{children}</InpaintContext.Provider>
  );
};
