const uploadSources = [
  {
    identifier:  "local_file_system",
    available:   true,
    premium:     false,
    pdf:         true,
    audio:       true,
  },
  {
    identifier:  "dropbox",
    available:   true,
    premium:     false,
    pdf:         true,
    audio:       true,
  },
  {
    identifier:  "googledrive",
    available:   true,
    premium:     false,
    pdf:         true,
    audio:       true,
  },
  {
    identifier:  "picasa",
    premium:     false,
    available:   false,
  },
  {
    identifier:  "facebook",
    premium:     false,
    available:   false,
  },
  {
    identifier:  "instagram",
    premium:     false,
    available:   false,
  },
  {
    identifier:  "webcam",
    premium:     false,
    available:   false,
  },
]

module.exports = () => {
  const _ = require("underscore")
  return {
    filter: (args) => {
      const filters = {
        available: true,
      }

      if (args.freeloader) {
        filters.premium = false;
      }

      if (args.context === "pdf") {
        filters.pdf = true;
      }

      if (args.context === "audio") {
        filters.audio = true;
      }
      return _.pluck(_.where(uploadSources, filters), "identifier");
    }
  }
};
