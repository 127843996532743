/** @prettier */
import * as React from 'react';
import type { IPanelProps } from '../../shared/MultiPanel';
import { useStore } from 'javascripts/helpers/useStore';
import type { IFrame } from 'javascripts/types/frame';
import type { FrameStore } from 'javascripts/flux/stores/frame';
import { isImagePlaceholder } from 'javascripts/helpers/isImagePlaceholder';
import { getAspectRatioNumerical } from 'javascripts/helpers/frame-size-helper';
import type { StoryboardStore } from 'javascripts/flux/stores/storyboard';
import classNames from 'classnames';
import { AssetsActions } from 'javascripts/flux/actions/assets';
import type { AddProps, ReplaceProps } from './ImageSources';
import { FrameActions } from 'javascripts/flux/actions/frame';

type FrameItemClickHandler = (frame: IFrame) => void;

const FrameInStoryboardPanelItem: React.FC<{
  frame: IFrame;
  onClick: FrameItemClickHandler;
}> = (props) => {
  const { frame, onClick } = props;
  const handleClick = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(() => onClick(frame), [onClick, frame]);

  return (
    <button
      type="button"
      className="relative overflow-hidden border pointer rounded-md border-border hover:border-brand-pink"
      onClick={handleClick}
    >
      <img src={frame.thumbnail_image_url} />

      <div
        className="absolute bottom-0.5 right-0.5 p-1 min-w-[18px] rounded-md text-xxs pointer-events-none leading-none bg-black/80 text-white"
        aria-label="Frame number"
      >
        {`Frame ${frame.number}`}
      </div>
    </button>
  );
};

export const FrameInStoryboardPanel: React.FC<
  (ReplaceProps | AddProps) & IPanelProps
> = (props) => {
  const framesWithImages = useStore<IFrame[], FrameStore>(
    'frame',
    ({ frames }) =>
      frames.filter((i) => {
        if (props.replace && i.id === props.currentFrame.id) return false;
        return i.large_image_url && !isImagePlaceholder(i.large_image_url);
      }),
  );

  const columnClass = useStore<string, StoryboardStore>(
    'storyboard',
    ({ storyboard }) => {
      const numeric = getAspectRatioNumerical(storyboard.frame_aspect_ratio);
      return numeric > 2 ? 'grid-cols-1' : 'grid-cols-2';
    },
  );

  const handleClick = React.useCallback<FrameItemClickHandler>(
    async (frame) => {
      let currentFrame;

      if (props.replace && props.currentFrame) {
        currentFrame = props.currentFrame;
      } else {
        currentFrame = await new Promise<IFrame>((resolve) =>
          FrameActions.insertFrame({
            storyboard_id: props.storyboard.id,
            callback: resolve,
          }),
        );
      }

      if (!currentFrame.id) throw new Error('could not find frame to replace');

      AssetsActions.replaceExistingFrame({
        targetFrameId: currentFrame.id,
        assetId: frame.id,
        storyboardId: props.storyboard.id,
        teamId: props.storyboard.project.owner.id,
      });

      HoverOverlayActions.closeAll.defer();
    },
    [props.replace, props.storyboard.id, props.storyboard.project.owner.id],
  );

  return (
    <div
      className={classNames(
        'grid flex-grow items-start gap-2 p-3 pb-4 auto-rows-min bg-surface-light',
        columnClass,
      )}
    >
      {framesWithImages.map((frame) => (
        <FrameInStoryboardPanelItem
          key={frame.id}
          frame={frame}
          onClick={handleClick}
        />
      ))}
    </div>
  );
};
