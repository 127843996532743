/** @prettier */

import React, { useEffect, useState, useRef } from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { RequestActions } from 'javascripts/flux/actions/request';
import { CircleCheck, TriangleAlert, X } from 'lucide-react';

interface AlertProps {
  text?: string;
  type?: 'error' | 'success';
  children?: React.ReactNode;
}

const AlertNew: React.FC<AlertProps> = ({
  text = 'Testing',
  type = 'error',
  children,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const timeoutRef = useRef<any>();

  const TIMEOUT_LENGTH = type === 'error' ? 10000 : 5000;

  const removeAlert = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsActive(false);
    setTimeout(() => {
      RequestActions.complete();
    }, 500);
  };

  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setIsActive(true);
      setIsTimerActive(true);
    }, 10);

    timeoutRef.current = setTimeout(removeAlert, TIMEOUT_LENGTH);

    return () => {
      clearTimeout(showTimeout);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsTimerActive(true);
    timeoutRef.current = setTimeout(removeAlert, TIMEOUT_LENGTH);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [text, type]);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    removeAlert();
  };

  const kind = type === 'error' ? 'error' : 'success';

  return (
    <Transition
      show={isActive}
      enter="ease-in-out duration-500 transition"
      enterFrom="transform translate-y-20"
      enterTo="transform translate-y-0"
    >
      <div className="flex justify-end">
        <div
          className={classNames(
            'pl-3 pr-2 rounded-md rounded-bl-0 relative overflow-hidden',
            kind === 'success' && 'bg-surface-success/40',
            kind === 'error' && 'bg-surface-critical/40',
            text.length > 30 ? 'pt-2 pb-2.5 w-72' : 'pt-1 pb-1.5 w-60',
          )}
        >
          <div className="bg-white absolute inset-0 z-[-1]" />
          <div
            className={classNames(
              'z-0 absolute bottom-0 left-0 w-full h-0.5',
              kind === 'success' && 'bg-surface-success/70',
              kind === 'error' && 'bg-surface-critical/60',
            )}
            style={{
              animation: isTimerActive
                ? `shrinkWidth ${TIMEOUT_LENGTH}ms linear forwards`
                : 'none',
            }}
          />
          <div className="relative z-10 flex items-center">
            <div className="flex-shrink-0 pt-0.5 pr-2">
              {kind === 'error' && (
                <TriangleAlert className="w-4 h-4 text-brand-pink" />
              )}
              {kind === 'success' && (
                <CircleCheck className="w-4 h-4 text-type-green" />
              )}
            </div>
            <div
              className={classNames(
                'flex-auto text-xs border-r pr-2',
                kind === 'error' && 'border-surface-critical',
                kind === 'success' && 'border-surface-success',
              )}
            >
              <div className="">{text}</div>
              {children}
            </div>
            <div className="flex-shrink-0 pb-0.5 pl-1">
              <span
                className={classNames(
                  'inline-flex items-center justify-center w-6 h-6 opacity-100 cursor-pointer hover:bg-white/50 rounded-md',
                  kind === 'success' && 'text-type-green/70',
                  kind === 'error' && 'text-brand-pink/70',
                )}
                onClick={handleClick}
              >
                <X className="w-3 h-3" />
              </span>
            </div>
          </div>
        </div>
        <style>{`
          @keyframes shrinkWidth {
            from { width: 100%; }
            to { width: 0%; }
          }
        `}</style>
      </div>
    </Transition>
  );
};

export default AlertNew;

(window as any).AlertNew = AlertNew;
