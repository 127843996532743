/** @prettier */

import * as React from 'react';
import { useDrag } from 'react-dnd';
import { usePreview } from 'react-dnd-multi-backend';
import { aspectRatioNameToAspectRatio } from '../../../helpers/frame-size-helper';
import { frameAspectRatioNormalizer } from '../../../helpers/frame-size-helper';
import type { imageLibraryDragItem } from '../../shared/dragAndDropUtils';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'blackbird/components/common/IconButton';
import DeleteIcon from 'blackbird/images/icons/delete-20.svg';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import Img from 'javascripts/components/shared/Img';
import classNames from 'classnames';
import { getErrorFrameImageURL } from 'javascripts/helpers/getMissingFrameImageURL';
import { Trash2 } from 'lucide-react';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';

// TODO: check this
/**
 * At the moment, this is only shown for touch users, this is a feature of the
 * dnd-multi-backend. This could be fixed by making a custom backend
 * @see https://github.com/LouisBrunner/dnd-multi-backend/tree/main/packages/react-dnd-multi-backend#previewa
 * */
export const PanelbarFrameAssetDragPreview: React.FC = () => {
  const [aspectRatio, setAspectRatio] = React.useState('1x1');
  const { display, item, style } = usePreview() as any;
  const timeout = React.useRef<any>();

  React.useEffect(() => {
    if (display) {
      timeout.current = setTimeout(() => {
        const targetAspectRatio =
          StoryboardStore.getState().storyboard.frame_aspect_ratio;
        const cssAspectRatio = aspectRatioNameToAspectRatio(
          frameAspectRatioNormalizer(targetAspectRatio)!,
        );
        setAspectRatio(cssAspectRatio);
      }, 400);
    } else {
      setAspectRatio('1x1');
    }

    return () => clearTimeout(timeout.current);
  }, [display, item]);

  if (!display) {
    return null;
  }

  return (
    <div style={{ ...style, zIndex: 999 }}>
      <div
        className={`t-p-bounce aspect-ratio--${aspectRatio} cover w-100 cursor-move bg-center`}
        style={{
          width: 174,
          backgroundImage: `url("${item.image}")`,
        }}
      />
    </div>
  );
};

export const PanelbarFrameAssetItem: React.FC<{
  imageUrl: string;
  dragType: string;
  id: string | number;
  canDelete?: boolean;
  onDelete?: () => void;
  isUploading: boolean;
  canDrag?: boolean;
}> = ({
  imageUrl,
  dragType,
  id,
  canDelete = true,
  canDrag = true,
  ...props
}) => {
  const { t } = useTranslation();
  const [{ isDragging }, ref] = useDrag(() => ({
    type: dragType,
    item: {
      id: id,
      image: imageUrl,
    } as imageLibraryDragItem,
    options: {
      dropEffect: 'copy',
    },
    canDrag: () => canDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div
      ref={ref}
      className={`group relative aspect-square ${
        canDrag ? 'cursor-grab' : 'cursor-not-allowed cursor'
      }`}
    >
      <Img
        className={classNames(
          'object-cover bg-center rounded-sm w-full h-full transition-opacity',
          isDragging ? 'opacity-60' : 'opacity-100',
        )}
        containerClasses="w-full h-full"
        src={imageUrl}
        placeholder={getErrorFrameImageURL('square')}
        small
        log
      />

      {props.onDelete && !props.isUploading ? (
        <Tooltip
          placement="left"
          title={
            canDelete
              ? t('imageLibrary.deleteAsset')
              : t('imageLibrary.cannotDeleteAsset')
          }
        >
          <div
            className={classNames(
              'absolute invisible p-1 top-1 right-1 rounded-md bg-white/50 group-hover:visible',
              canDelete ? 'cursor-pointer' : 'cursor-not-allowed',
            )}
            onClick={(e) => {
              if (canDelete && props.onDelete) {
                props.onDelete();
              }
            }}
          >
            <Trash2 className="w-3 h-3" />
          </div>
        </Tooltip>
      ) : null}

      <LoadingIndicator fill bg show={props.isUploading} text="Uploading…" />
    </div>
  );
};
