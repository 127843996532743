/** @prettier */
/* eslint  react-perf/jsx-no-new-object-as-prop:0 */
import * as React from 'react';
import { OverlayListItemNew as OverlayListItem } from 'javascripts/components/shared/OverlayListItemNew';
import { type IPanelProps } from '../../shared/MultiPanel';
import {
  Clapperboard,
  FileSpreadsheet,
  FileText,
  FileType,
  Image,
  Images,
  LayoutGrid,
  LayoutList,
  MonitorPlay,
  Presentation,
} from 'lucide-react';
type IProps = IPanelProps;

const listItemProps = {
  showBorder: false,
  center: false,
  showArrow: true,
  padding: 'px-1',
  height: 'h-[42px]',
  // size: 'larger',
  animateIconOpacity: true,
};

const handlePDFExportClick = () => {
  FlyoverActions.open({ type: 'storyboardExport' });
};

const handlePresentationExportClick = () => {
  FlyoverActions.open({ type: 'presentationExport' });
};

export const ExportOptions = React.memo<IProps>((props) => (
  <>
    <div className="p-2 my-0">
      <OverlayListItem
        {...listItemProps}
        onClick={handlePDFExportClick}
        text="PDF"
        lucideIcon={LayoutList}
        showArrow={false}
        showPopupIndicator
      />

      <OverlayListItem
        {...listItemProps}
        onClick={props.goToPanelF('images')}
        lucideIcon={Images}
        text="Images"
        showArrow={true}
      />

      <div className="my-2 border-b border-border" />

      <OverlayListItem
        {...listItemProps}
        onClick={props.goToPanelF('script')}
        lucideIcon={FileType}
        text="Script"
        showArrow={true}
      />

      <OverlayListItem
        {...listItemProps}
        onClick={props.goToPanelF('spreadsheet')}
        lucideIcon={FileSpreadsheet}
        text="Google Sheets"
        showArrow={true}
      />

      <OverlayListItem
        {...listItemProps}
        onClick={handlePresentationExportClick}
        text="Google Slides"
        lucideIcon={Presentation}
        showArrow={false}
        showPopupIndicator
      />

      <div className="my-2 border-b border-border" />

      <OverlayListItem
        {...listItemProps}
        onClick={props.goToPanelF('animatic')}
        lucideIcon={MonitorPlay}
        text="Animatic"
        showArrow={true}
      />

      <OverlayListItem
        {...listItemProps}
        onClick={props.goToPanelF('afterEffects')}
        text="After Effects"
        lucideIcon={Clapperboard}
        showArrow={true}
      />
    </div>
  </>
));

ExportOptions.displayName = 'StoryboardExportOptions';
