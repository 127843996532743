const createReactClass = require('create-react-class')
window.FlyoverStatic = createReactClass({

  componentDidMount: function() {

    // Lock window
    document.getElementsByClassName('container')[0].style.top = -window.scrollY + 'px';
    document.body.classList.add('locked');
    document.body.classList.add('has--flyover');
  },

  _animateOut: function() {

    document.body.classList.remove('locked');
    document.body.classList.remove('has--flyover');

    document.getElementsByClassName('container')[0].style.top = '';

    var flyoverBg = ReactDOM.findDOMNode(this);
    var flyover = document.getElementsByClassName('flyover static')[0];

    TweenLite.to([flyoverBg, flyover], 0.5, {
      opacity: 0,
      onComplete: function() {
        flyover.classList.remove('active');
      }
    });

  },

  _onBgClick: function(e) {
    e.preventDefault();
    this._animateOut();
  },

  render: function() {

    return <div className="flyover__bg" onClick={this._onBgClick} />;

  }

});

