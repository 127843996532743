/** @prettier */
import React, { useRef } from 'react';
import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Checkbox from 'blackbird/components/form/checkbox/Checkbox';
import { type OnboardingProps, OnboardingType } from './Onboarding';
import { useTranslation } from 'react-i18next';
import { type SubmitHandler, useForm } from 'react-hook-form';
import LogoGoogle from 'blackbird/images/logo/google-vector.svg';
import { OnboardingFormWrapper } from './OnboardingFormWrapper';
import { FolderTree } from 'lucide-react';
interface TextDividerProps {
  text: string;
}
const TextDivider: React.FC<TextDividerProps> = (props) => {
  const { text } = props;
  return (
    <div className="flex items-center gap-3">
      <div className="flex-grow h-px bg-border"></div>
      <span className="text-type-disabled">{text}</span>
      <div className="flex-grow h-px bg-border"></div>
    </div>
  );
};
interface SignInInput {
  'user[email]': string;
  'user[password]': string;
  'user[remember_me]'?: boolean;
}
const SignInScreen: React.FC<OnboardingProps> = (props) => {
  const [isBusy, setBusy] = React.useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const {
    register,
    formState,
    handleSubmit: handleValidation,
  } = useForm<SignInInput>();
  // We have to use any type here. This is because
  // the functionality doesn't work properly with the
  // nested fields like error['user[email]'].
  // It changes it to errors.user.email on runtime
  // but typescript will show errors if we use that
  const errors = (formState.errors as any)?.user;
  const toggleToForgetPassword = () => {
    props.onToggleScreen && props.onToggleScreen(OnboardingType.RESET_PASSWORD);
  };

  const handleSubmit: SubmitHandler<SignInInput> = (data) => {
    if (props.onSignIn) {
      props.onSignIn({
        email: (data as any).user.email,
        // Ideally it should be data['user[email]'], but there's a bug with library
        // that parses the nested objects
        password: (data as any).user.password,
      });
      return;
    } else if (!props.authenticityToken || !props.formAction) {
      throw new Error(
        'An authenticity token and formAction prop need to be supplied',
      );
    }
    setBusy(true);
    formRef.current?.submit();
  };

  const { t } = useTranslation();
  return (
    <OnboardingFormWrapper
      title={t('login.heading')}
      bottomLink={
        <div className="flex items-center hidden pt-4 pb-5 pl-5 pr-8 mt-8 rounded-lg text-premium-700 bg-premium-100">
          <div className="flex-shrink-0 pt-0.5 mr-3">
            <FolderTree
              className="w-8 h-8 text-premium-400"
              strokeWidth={1.5}
            />
          </div>
          <div className="flex-auto">
            <span className="mr-1">{`Introducing Workflow, a new Boords experience for fast-moving creative teams.`}</span>
            <a
              className="underline hover:no-underline text-premium-800"
              target="_blank"
              rel="noopener noreferrer"
              href={'https://boords.com/workflow?rel=login'}
            >{`Learn more ↗`}</a>
          </div>
        </div>
      }
      subtitle={
        <div className="flex items-center text-type-subdued">
          <p className="mr-1">{`New to Boords?`}</p>
          <a
            className="underline hover:no-underline text-brand-pink"
            href={'/signup'}
          >{`Sign up here`}</a>
        </div>
      }
    >
      <form
        ref={formRef}
        onSubmit={handleValidation(handleSubmit)}
        className="flex items-center justify-center flex-grow"
        method="post"
        action={props.formAction}
      >
        <input name="utf8" type="hidden" value="✓" />
        {props.authenticityToken && (
          <input
            type="hidden"
            name="authenticity_token"
            value={props.authenticityToken}
          />
        )}

        <fieldset className="flex flex-col flex-grow max-w-md space-y-8">
          <div className="flex flex-col gap-6">
            <div className="space-y-2">
              <TextInput
                {...register('user[email]', {
                  required: 'Email is required.',
                })}
                label="Email"
                className="flex-grow-0"
                type="email"
                error={errors?.email?.message}
              />

              <TextInput
                {...register('user[password]', {
                  required: 'Password is required.',
                })}
                label="Password"
                type="password"
                className="flex-grow-0"
                autoComplete="current-password"
                error={errors?.password?.message}
              />

              <div className="flex items-center justify-between">
                <Checkbox
                  {...register('user[remember_me]')}
                  label={
                    <span className="text-sm block -mt-0.5 -ml-1">
                      {t('login.rememberMe')}
                    </span>
                  }
                  controlled={false}
                />
                <Button
                  type="text"
                  onClick={toggleToForgetPassword}
                  className="-mt-1 text-sm font-normal underline cursor-pointer hover:no-underline no-padding"
                >
                  {t('login.forgotPassword')}
                </Button>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <Button
                disabled={isBusy || formState.isSubmitting}
                htmlType="submit"
                rounded
                size="lg"
              >
                {t('login.button')}
              </Button>
              <TextDivider text={`or`} />
              <Button
                link={props.googleSigninPath}
                size="lg"
                type="outline"
                rounded
              >
                <div className="relative w-full">
                  <div className="absolute -left-1 top-[0.25rem]">
                    <LogoGoogle
                      width="1.2rem"
                      height="1.2rem"
                      alt="Google Logo"
                    />
                  </div>
                  <div className="pl-2 text-center">{`Sign in with Google`}</div>
                </div>
              </Button>
            </div>
          </div>
        </fieldset>
      </form>
    </OnboardingFormWrapper>
  );
};

export default SignInScreen;
