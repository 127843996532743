/** @format */
import classNames from 'classnames';
import * as React from 'react';
import type { StoryboardZoomStore } from 'javascripts/flux/stores/storyboardZoom';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import { useStore } from 'javascripts/helpers/useStore';
import type { frameAspectRatio } from 'javascripts/types/storyboard';
import { useContainerQuery } from 'react-container-query';

interface Props {
  children: (props: FrameRenderProps) => React.ReactNode;
  className?: string;
  frameAspectRatio: frameAspectRatio;
}

export type GridSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface FrameRenderProps {
  isMinimal: boolean;
  size: GridSize;
}

const query = {
  minimal: {
    maxWidth: 120,
  },
  xs: {
    maxWidth: 200,
  },
  sm: {
    minWidth: 201,
    maxWidth: 230,
  },
  md: {
    minWidth: 231,
    maxWidth: 300,
  },
  lg: {
    minWidth: 301,
    maxWidth: 350,
  },
  xl: {
    minWidth: 351,
  },
};

export const FrameGrid = React.memo<Props>((props) => {
  const columns = useStore<number, StoryboardZoomStore>(
    'storyboardZoom',
    (s) => s.zoomSettings.columns,
  );

  const aspectRatio = BoordsFrameSizeHelper(props.frameAspectRatio);
  const roughEstimatedWidth = (window.innerWidth * 0.9) / columns;
  const roughEstimatedDimensions = {
    width: roughEstimatedWidth,
    height: roughEstimatedWidth / (aspectRatio.width / aspectRatio.height),
  };

  const [params, containerRef] = useContainerQuery(
    query,
    roughEstimatedDimensions,
  );

  // Determine the current size based on params
  const getCurrentSize = (): GridSize => {
    if (params.xs) return 'xs';
    if (params.sm) return 'sm';
    if (params.md) return 'md';
    if (params.lg) return 'lg';
    return 'xl';
  };

  const renderProps: FrameRenderProps = {
    isMinimal: params.minimal,
    size: getCurrentSize(),
  };

  return (
    <div
      className={classNames(
        'grid grid-flow-row auto-rows-max -m-3',
        props.className,
      )}
      style={{
        gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
      }}
    >
      {props.children(renderProps)}
      {/* Measurer */}
      <div
        ref={containerRef}
        style={{ aspectRatio: `${aspectRatio.width / aspectRatio.height}` }}
      />
    </div>
  );
});

FrameGrid.displayName = 'FrameGrid';
