/** @prettier */
import Dialog from 'blackbird/components/dialog/Dialog';
import {
  BillingContext,
  type CheckoutUiMode,
  type IBillingPlanSlug,
  type IBilllingPlanInterval,
} from 'blackbird/helpers/contexts/BillingContext';
import type { UserResponse } from 'javascripts/types/user';
import type { UserStore } from 'javascripts/flux/stores/user';
import React, { useContext, useEffect, useState } from 'react';
import PaymentInlinePlanChangeConfirmation from './PaymentInlinePlanChangeConfirmation';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';

import StripeLogo from 'blackbird/images/logo/stripe.svg';
import BoordsLogo from 'blackbird/images/logo/logomark.svg';
import BoordsLogoType from 'blackbird/images/logo/logotype.svg';
import classNames from 'classnames';
import { PaymentUpdateSuccess } from './PaymentUpdateSuccess';
import { PaymentError } from './PaymentError';
import { useStore } from 'javascripts/helpers/useStore';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import {
  type FeatureName,
  FeatureGift,
  FeatureInline,
  FeatureStar,
} from 'blackbird/components/features/Feature';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { PricingTableUserCount } from 'blackbird/components/pricing/PricingTableFeatures';

interface CheckoutProps {
  plan_name: IBillingPlanSlug;
  interval: IBilllingPlanInterval;
}

interface StripeCheckoutLoaderProps {
  children: React.ReactElement;
}

const StripeCheckoutLoader: React.FC<StripeCheckoutLoaderProps> = ({
  children,
}) => {
  const { stripe, clientSecret, loadStripeCheckoutSession, newPlan } =
    useContext(BillingContext);

  const [uiMode] = React.useState<CheckoutUiMode | undefined>('hosted');

  useEffect(() => {
    if (uiMode) {
      loadStripeCheckoutSession(uiMode);
    }
  }, [uiMode]);

  const [hasAddonCredits, setHasAddonCredits] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const addon = queryParams.get('addon');

    if (addon === '1000-ai-credits') {
      setHasAddonCredits(true);
    }
  }, []);

  if (uiMode === 'embedded' && clientSecret && stripe) {
    const options = {
      clientSecret: clientSecret,
    };
    return !newPlan ? null : (
      <div className="min-w-full">
        <div className="grid grid-cols-2">
          <div className="pt-10">
            <div className="flex flex-col min-h-full pt-10 pl-8 bg-surface-light rounded-xl">
              <div className="pb-20">
                <Tooltip title={`Back to Pricing`} placement="right">
                  <span
                    className="inline-block cursor-pointer"
                    onClick={() =>
                      FlyoverActions.open.defer({
                        type: 'inlinePricing',
                      })
                    }
                  >
                    <BoordsLogoType className="w-[10rem]" />
                  </span>
                </Tooltip>
              </div>

              <div className="flex items-center flex-auto pb-[8rem]">
                <div>
                  <div className="mb-4 text-xs tracking-widest uppercase text-type-disabled">{`Your order summary`}</div>
                  <div className="mb-4 text-xl font-semibold">{`${newPlan.name} Plan`}</div>
                  <div className="space-y-2">
                    <PricingTableUserCount plan={newPlan} hideSingleUser />
                    {newPlan.summaryFeatures &&
                      newPlan.summaryFeatures.map((f: FeatureName) => (
                        <FeatureInline name={f} key={`feature-${f}`} />
                      ))}

                    {hasAddonCredits && (
                      <FeatureGift
                        label={`+1,000 Free AI Credits`}
                        tooltip={
                          'Get an extra 1,000 free AI credits to use anytime.'
                        }
                      />
                    )}
                    <FeatureStar
                      label={`Cancel at any time`}
                      tooltip={'Easy one-click cancelation from your account.'}
                    />
                    <FeatureStar
                      label={`30 day money-back guarantee`}
                      tooltip={`If you're not happy with your purchase you can contact us within 30 days for a no-questions-asked refund.`}
                    />
                  </div>
                </div>
              </div>

              <div className="pb-10 text-sm text-black/50">
                <div className="flex items-center">
                  <LockClosedIcon className="w-4 text-type-disabled" />
                  <div className="ml-1.5 text-sm text-type-subdued">{`Secure & encrypted payment`}</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <EmbeddedCheckoutProvider stripe={stripe} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        </div>
      </div>
    );
  } else {
    return <>{children}</>;
  }
};

const PaymentCheckoutContainer: React.FC<CheckoutProps> = ({
  plan_name,
  interval,
}) => {
  const { t } = useTranslation('billing');
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);

  const {
    handleClose,
    dialogTitle,
    setDialogTitle,
    dialogSubtitle,
    setDialogSubtitle,
    newPlan,
    newPlanSlug,
    newPlanInterval,
    fetchInvoicePreview,
    fetchNewPlan,
    setNewPlanSlug,
    setNewPlanInterval,
    hasValidCard,
    invoicePreview,
    customer,
    errorType,
    setErrorType,
    complete,
    user,
    setUser,
    hasActiveSubscription,
    fetchCustomer,
    newCustomerProceed,
    clientSecret,
  } = useContext(BillingContext);

  const userResponse = useStore<UserResponse | undefined, UserStore>(
    'userStore',
    (u) => u.user,
  );

  // Set the initial plan name parameters for
  // the API request
  useEffect(() => {
    if (['monthly', 'yearly'].includes(interval)) {
      setNewPlanSlug(plan_name);
      setNewPlanInterval(interval);
    } else {
      setErrorType('invalid_plan');
    }
  }, [plan_name, interval]);

  // Set the user in the context
  useEffect(() => {
    if (userResponse && !user) {
      setUser(userResponse);
    }
  }, [userResponse, user]);

  // Load the customer
  useEffect(() => {
    if (user && !customer && !errorType && newPlanSlug && newPlanInterval) {
      fetchCustomer();
    }
  }, [customer, errorType, newPlanSlug, newPlanInterval, user]);

  // Load the new plan
  useEffect(() => {
    if (
      !newPlan &&
      newPlanSlug &&
      newPlanInterval &&
      customer &&
      !errorType &&
      typeof hasActiveSubscription !== 'undefined'
    ) {
      fetchNewPlan();
    }
  }, [
    newPlan,
    newPlanSlug,
    newPlanInterval,
    customer,
    errorType,
    hasActiveSubscription,
  ]);

  // Fetch the preview invoice
  useEffect(() => {
    if (newPlan && !invoicePreview && !errorType) {
      fetchInvoicePreview();
    }
  }, [newPlan, invoicePreview, errorType]);

  // Set the loaded state
  useEffect(() => {
    if ((newPlan && invoicePreview && customer) || errorType) {
      setLoaded(true);
    }
  }, [customer, newPlan, invoicePreview, errorType]);

  // Show the icon
  useEffect(() => {
    if ((loaded && hasValidCard) || errorType) {
      setShowIcon(true);
    }
  }, [loaded, hasValidCard, errorType]);

  useEffect(() => {
    if (newCustomerProceed) {
      setShowIcon(false);
      setDialogTitle('');
      setDialogSubtitle('');
    }
  }, [newCustomerProceed]);

  return (
    <Dialog
      isColorBurst
      title={dialogTitle}
      subtitle={dialogSubtitle}
      hideClose={(!loaded || complete || !hasValidCard) && !errorType}
      onCloseBtnClick={handleClose}
      containerClasses={clientSecret && '!pr-0 !pt-0'}
      // onEscapeOrOutsideClick={handleClose}
      keepOpen={true}
      icon={showIcon && <BoordsLogo className="w-12" />}
      isOpen
      hideActions
      size={clientSecret ? 'xl' : 'slim'}
    >
      <div
        className={classNames(
          'flex flex-col',
          (!loaded || !hasValidCard) && !errorType && 'min-h-[20rem]',
        )}
      >
        <div className="flex items-center justify-center flex-auto">
          {errorType ? (
            <PaymentError />
          ) : !loaded ? (
            <LoadingIndicator secondary text={t('notices.loadingPlan')} />
          ) : hasValidCard ? (
            complete ? (
              <PaymentUpdateSuccess />
            ) : (
              <PaymentInlinePlanChangeConfirmation />
            )
          ) : (
            <div className="min-w-full">
              <StripeCheckoutLoader>
                <LoadingIndicator
                  secondary
                  appear={false}
                  text={t('notices.loadingCheckout')}
                />
              </StripeCheckoutLoader>
            </div>
          )}
        </div>
        {!complete && !clientSecret && (
          <div className="flex justify-center mt-6">
            <StripeLogo className="w-28 opacity-40" />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default PaymentCheckoutContainer;
