/** @format */
import { usePersistedBoolean } from 'blackbird/helpers/hooks/useBoolean';
import { X } from 'lucide-react';
import React from 'react';
import Tooltip from '../feedback/tooltip/Tooltip';

const HIDDEN_KEY = 'inpaintHelpClosed';

export const InpaintHelp: React.FC = () => {
  const [isHidden, setIsHidden] = usePersistedBoolean(HIDDEN_KEY, false);

  return isHidden ? null : (
    <div className="px-4 pt-3 pb-4 text-sm rounded-lg bg-surface-warning group/inpaintHelp">
      <div className="flex items-center mb-2 space-x-1.5 ">
        <div className="flex-auto font-semibold">{`Magic Fill Quick Start`}</div>
        <Tooltip placement="left" title={`Dismiss`}>
          <div
            className="opacity-0 cursor-pointer group-hover/inpaintHelp:opacity-50 hover:opacity-100"
            onClick={setIsHidden.on}
          >
            <X className="w-4 h-4" />
          </div>
        </Tooltip>
      </div>
      <ol className="space-y-1.5 list-decimal ml-4">
        <li className="">{`Draw the area you want to change`}</li>
        <li className="">{`Describe what you want to see`}</li>
        <li className="">{`Click "Modify Image" to make changes`}</li>
      </ol>
      <div className="mt-2 text-xs opacity-70">{`Pro tip: Describe what you want to create, not what to remove. The more specific, the better!`}</div>
    </div>
  );
};
