/** @prettier */
import parseMilliseconds from 'parse-ms';

export const toSec = (ms: number) =>
  (Math.floor((ms / 1000) * 2) / 2).toFixed(1) + 's';

const pad = (n, amount = 2) => ('000000' + n).slice(-amount);

export const toMins = (s = 0, withMilliseconds = true) => {
  // from: https://stackoverflow.com/a/9763769/847689

  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;

  let output = +pad(mins) + ':' + pad(secs);
  if (withMilliseconds) output += '.' + pad(ms);
  return output;
};

const trimTrailingZeros = (number = 0) =>
  String(number).replace(/([1-9])0+$/, '$1');

export const autoFormatTime = (
  ms: number,
  detail: boolean,
  minutePadAmount = 2,
) => {
  const time = parseMilliseconds(ms);
  const fps = 24;

  let str = `${pad(time.minutes, minutePadAmount)}:${pad(time.seconds)}`;

  // const frames = detail
  //   ? Math.floor(time.milliseconds / (1000 / fps))
  //   : null;

  // if (detail) str += `:${pad(time.milliseconds, 3)}`;
  if (detail) str += `:${trimTrailingZeros(time.milliseconds)}`;
  // if (detail) str += `.${frames}`;
  return str;
};
