import { retryWhile } from 'javascripts/helpers/retry-while';
import logger from '../../helpers/logger';

const _ = require('underscore');
require('../actions/intercom');
const { onLoadAfterBackButton } = require('../../helpers/onLoadAfterBackButton')
const { idleTimeout } = require('../../helpers/idle-timeout');

(() => {
  class IntercomStore {
    constructor() {
      // We default this to undefined, so we know when we don't have a value yet
      this.isVisible = undefined;
      this.canShowIntercom = false;

      // Add kill switch - can be controlled via config or environment
      this.isIntercomEnabled = false

      // keep a list of current 'contexts' in which we don't want to show the
      // intercom overlay. As long as one context is active, we will hide this.
      // Normally I'd put this in a generic UI store, but we don't have that.
      this.currentHiddenContexts = []

      this.bindListeners({
        handleMessage:     IntercomActions.MESSAGE,
        enterContext:      IntercomActions.ENTER_CONTEXT,
        leaveContext:      IntercomActions.LEAVE_CONTEXT,
      });

      const checkForIntercomLoad = () => {
        // Check both the kill switch and original conditions
        const isEnabled = this.isIntercomEnabled &&
                         typeof Intercom !== 'undefined' &&
                         !BoordsConfig.shouldHideIntercom;
        this.canShowIntercom = isEnabled;
      }

      window.addEventListener('load', idleTimeout(() => {
        checkForIntercomLoad()
        this.evaluateHideOrShow()

        retryWhile(() => typeof Intercom !== 'undefined', () => {
          checkForIntercomLoad()
          this.evaluateHideOrShow()
          if (!this.canShowIntercom) {
            const reason = !this.isIntercomEnabled ? 'Intercom is disabled via configuration' :
                          'Intercom is disabled for this context or user, or it could not be found';
            logger.info(reason);
          }
        }, { maxTries: 20 })

        this.emitChange()
      }))

      onLoadAfterBackButton(() => this.evaluateHideOrShow())
    }

    handleMessage(options) {
      // Early return if Intercom is disabled
      if (!this.isIntercomEnabled) return;

      if (this.isVisible) {
        ajaxHelper.ajax({
          method: "post",
          url: "/intercom_message.json",
          data: {
            message_type: options.type,
          },
          success: function (response) {
            if (!_.isNull(response)) {
              setTimeout(function() {
                Intercom("update", {last_request_at: parseInt((new Date()).getTime()/1000)})
              }, 2000);
            }
          }.bind(this),
          error: function(response) {
            logger.error(response);
          }.bind(this),
        });
      }
    }

    evaluateHideOrShow() {
      // Early return if Intercom is disabled
      if (!this.isIntercomEnabled || typeof Intercom === 'undefined') return;

      const shouldShow = this.currentHiddenContexts.length === 0
        && this.canShowIntercom

      if (shouldShow && this.isVisible === false) {
        idleTimeout(() => {
          Intercom("update", { hide_default_launcher: false })
        }, 500)
      } else if (!shouldShow) {
        // Even if we're not currently visible, we send intercom the hide
        // command again, because in some cases (when the page loads in
        // the editor) this is necessary
        setTimeout(() => Intercom("update", { hide_default_launcher: true }),0)
      }

      this.isVisible = shouldShow
    }

    enterContext(context) {
      // Early return if Intercom is disabled
      if (!this.isIntercomEnabled) return;
      this.toggleVisibility(true)
    }

    leaveContext(context) {
      // Early return if Intercom is disabled
      if (!this.isIntercomEnabled) return;
      this.toggleVisibility(false)
    }

    toggleVisibility(hide) {
      // Early return if Intercom is disabled
      if (!this.isIntercomEnabled) return;

      if (typeof window.Intercom === 'undefined') {
        // Set up a listener to check for Intercom availability
        const checkIntercom = setInterval(() => {
          if (typeof window.Intercom !== 'undefined') {
            clearInterval(checkIntercom);
            this.runToggle(hide)
          }
        }, 50);

        // Set a timeout to stop checking after a certain period
        setTimeout(() => {
          clearInterval(checkIntercom);
        }, 10000);
      } else {
        this.runToggle(hide)
      }
    }

    runToggle(hide) {
      // Early return if Intercom is disabled
      if (!this.isIntercomEnabled) return;

      const targetClass = 'intercom-lightweight-app';

      const applyStyles = (element) => {
        element.style.display = hide ? 'none' : 'block';
      };

      // Function to run when the element is found
      const onElementFound = (element) => {
        applyStyles(element);
        window.Intercom("update", { hide_default_launcher: hide });
      };

      // Check if the element already exists
      let targetElement = document.querySelector(`.${targetClass}`);
      if (targetElement) {
        onElementFound(targetElement);
        return;
      }

      // If the element doesn't exist, set up a MutationObserver
      const observer = new MutationObserver((mutations) => {
        for (let mutation of mutations) {
          if (mutation.type === 'childList') {
            targetElement = document.querySelector(`.${targetClass}`);
            if (targetElement) {
              observer.disconnect(); // Stop observing once we've found the element
              onElementFound(targetElement);
              break;
            }
          }
        }
      });

      // Start observing the document with the configured parameters
      observer.observe(document.body, { childList: true, subtree: true });

      // Optional: Disconnect the observer after a timeout (e.g., 10 seconds)
      setTimeout(() => {
        if (observer) {
          observer.disconnect();
        }
      }, 10000);
    }
  }

  window.IntercomStore = alt.createStore(IntercomStore, 'IntercomStore');

})();
