/** @format */
import * as React from 'react';
import { StarIcon } from '@heroicons/react/24/solid';
import { GeneratorContext } from '../generator/GeneratorContext';
import { OfferContext, OfferProvider } from './OfferContext';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';
import classNames from 'classnames';

const Stars: React.FC = () => (
  <div className="flex items-center mb-1.5 mt-0">
    <span className="mr-1 text-xs">4.9/5</span>
    {[1, 2, 3, 4, 5].map((f) => (
      <StarIcon className="w-4 text-brand-yellow" key={`star-${f}`} />
    ))}
    <span className="ml-1 text-xs">{`Rated by customers`}</span>
  </div>
);

interface OfferInlineProps {
  children?: React.ReactNode;
  isPanelbar: boolean;
}

const OfferInlineComponent: React.FC<OfferInlineProps> = ({
  children,
  isPanelbar,
}) => {
  const {
    offerExpiryDate,
    flyoverTitle,
    flyoverPreHeader,
    flyoverSignupCount,
    flyoverContents,
    flyoverButton,
    flyoverPrice,
    setIsAi,
    setIsOfferView,
  } = React.useContext(OfferContext);

  React.useEffect(() => {
    setIsAi(true);
    setIsOfferView(true);
  }, []);

  if (!offerExpiryDate) {
    return <>{children}</>;
  }

  return (
    <>
      {isPanelbar && <div className="xl:hidden">{children}</div>}
      <div
        className={classNames(
          'relative max-w-sm overflow-x-hidden bg-white border shadow-md rounded-xl border-border ',
          isPanelbar && 'hidden xl:block',
        )}
      >
        {flyoverPreHeader}
        <div className="px-6 mt-3 mb-4">
          {flyoverTitle}
          {/* <Stars /> */}
          {flyoverPrice}
          {flyoverSignupCount}
        </div>
        <div className="flex flex-col px-6 pb-4 border-t border-border">
          {flyoverContents}
          {flyoverButton}
        </div>
      </div>
    </>
  );
};

export const OfferInline: React.FC<OfferInlineProps> = (props) => (
  <DefaultStoreContextProvider>
    <OfferProvider>
      <OfferInlineComponent {...props} />
    </OfferProvider>
  </DefaultStoreContextProvider>
);
