/** @prettier */
import { NewCommentForm } from 'blackbird/components/comments/editor/NewCommentForm';
import Popover from 'blackbird/components/common/popover/Popover';
import { ConditionalTooltip } from 'blackbird/components/feedback/tooltip/Tooltip';
import { CommentActions } from 'javascripts/flux/actions/comment';
import { eachFrameFieldMap } from 'javascripts/helpers/eachFrameFieldMap';
import * as navigateToRoute from '../../../helpers/router/navigate-to-route';
import { useStore } from 'javascripts/helpers/useStore';
import type { DetailedFrame } from 'javascripts/types/frame';
import type {
  FrameField,
  IStoryboardStore,
} from 'javascripts/types/storyboard';
import * as React from 'react';
import { pick } from 'underscore';
import type { StoryboardStore } from 'javascripts/flux/stores/storyboard';
import FrameNotes from '../FrameNotes';
import { useRollout } from 'javascripts/helpers/rollout';
import { type GridSize } from '../FrameGrid';
import classNames from 'classnames';

interface Props {
  isCommenting: boolean;
  isInteractive: boolean;
  frame: DetailedFrame;
  isMinimal: boolean;
  gridSize: GridSize;
  frame_fields: FrameField[];
  update_all_frames?: boolean;
  index: string | number;
  storyboardSlug: string;
  storyboardId: number;
}

export const FrameInfo = React.memo<Props>((props) => {
  const isInteractive = props.isInteractive ?? true;
  const storyboardDurationEnabled = useRollout('Storyboardduration');
  const showDuration = useStore<boolean, IStoryboardStore>(
    'StoryboardStore',
    (s) =>
      storyboardDurationEnabled &&
      (s.storyboard.preferences?.show_storyboard_duration ?? true),
  );

  const storyboardProps = useStore<any, IStoryboardStore>(
    'StoryboardStore',
    (store) =>
      pick(store.storyboard, [
        'include_icons_in_pdf',
        'include_frame_number_in_pdf',
        'include_label_text_in_output',
      ]),
  );

  const handleDurationClick = React.useCallback(() => {
    navigateToRoute('player', {
      slug: props.storyboardSlug,
      frameIndex: props.index,
    });
  }, [props.storyboardSlug, props.index]);

  const noteProps = {
    frame: props.frame,
    update_all_frames: props.update_all_frames,
    storyboardId: props.storyboardId,
    indexLength: String(props.index).length,
    isInteractive: !props.isMinimal && isInteractive,
    gridSize: props.gridSize,
    isMinimal: props.isMinimal,
    // Passing these booleans directly, so they actually cause rerenders when
    // they are changed
    showIcons: storyboardProps.include_icons_in_pdf,
    showFrameNumber: storyboardProps.include_frame_number_in_pdf,
  };

  // This belongs as a separate prop, because it can change independently
  // of the storyboard object
  const frame_fields = props.frame_fields;

  const referenceIsEnabled = useStore<boolean, StoryboardStore>(
    'storyboard',
    (s) => s.storyboard.preferences?.include_label_text_in_edit_view ?? true,
  );

  if (!referenceIsEnabled && props.isMinimal) return null;

  return (
    <div
      className={classNames(
        `flex-grow bg-white frame__info`,
        props.gridSize === 'xs' && 'rounded-md px-1.5',
        props.gridSize === 'sm' && 'rounded-md px-2',
        props.gridSize === 'md' && 'rounded-md px-2',
        props.gridSize === 'lg' && 'rounded-lg px-3',
        props.gridSize === 'xl' && 'rounded-xl px-3',
      )}
    >
      {referenceIsEnabled && (
        <ConditionalTooltip
          title={props.isMinimal ? 'Zoom in to edit' : undefined}
        >
          <FrameNotes
            {...noteProps}
            field_id="reference"
            icon={String(props.index)}
            placeholder={
              !storyboardProps.include_label_text_in_output
                ? 'Label (hidden from output)'
                : 'Label'
            }
          />
        </ConditionalTooltip>
      )}

      {!props.isMinimal ? (
        <>
          {eachFrameFieldMap(frame_fields, (field, fieldName, i, label) =>
            fieldName === 'reference' ? null : (
              <FrameNotes
                key={fieldName}
                {...noteProps}
                field_id={fieldName}
                icon={field.icon}
                placeholder={label}
              />
            ),
          )}
          {showDuration && (
            <FrameNotes
              key={'duration'}
              {...noteProps}
              field_id={'duration'}
              icon={'clock'}
              placeholder="Duration"
              isInteractive={false}
              onClick={handleDurationClick}
            />
          )}
        </>
      ) : null}
      {props.isCommenting ? (
        <Popover placement="bottom" isOpen distance={10} portal>
          <Popover.Button className="absolute left-1/2"></Popover.Button>
          <Popover.Panel className="w-80">
            <NewCommentForm
              context="grid"
              allowNameField={false}
              attachment={null}
              frameId={props.frame.id}
              storyboardSlug={props.storyboardSlug}
              onClose={CommentActions.closeFrameCommentBox}
              autoFocus
            />
          </Popover.Panel>
        </Popover>
      ) : null}
    </div>
  );
});

FrameInfo.displayName = 'FrameInfo';
