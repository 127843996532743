/** @format */
import classNames from 'classnames';
import BoordsFrameSizeHelper from 'javascripts/helpers/frame-size-helper';
import type { frameAspectRatio } from 'javascripts/types/storyboard';
import * as React from 'react';
import * as fitLib from 'fit.js';
import { isEqual, isFunction, throttle } from 'underscore';
import type { WidthAndHeight } from 'javascripts/types/frame';
import { useStateWithRef } from 'javascripts/helpers/useStateWithRef';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
const errorHandler = RequestErrorHandler('autoSizedFrame');

interface Props {
  children?:
    | React.ReactNode
    | ((dimensions: WidthAndHeight) => React.ReactNode);
  frameAspectRatio: frameAspectRatio;
  throttle?: number;
  className?: string;
  onResize?: (dimensions: WidthAndHeight) => void;
  border?: boolean;
}

const initialStyles = { width: '100%', height: '100%' };

type positioning = WidthAndHeight & { top: number; left: number };

export const AutoSizedFrame = React.memo<Props>((props) => {
  const { onResize, frameAspectRatio } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const [childSizes, setChildSizes, currentSizes] =
    useStateWithRef<WidthAndHeight | null>(null);
  const [style, setStyle] = React.useState<React.CSSProperties>();
  const throttleAmount = props.throttle ?? 1000 / 60;
  const observer = React.useRef<ResizeObserver>();
  const borderAdjustments = props.border ? 2 : 0;

  const determineSize = React.useCallback(
    (aspectRatio: frameAspectRatio): positioning & { top: number } => {
      const imageSize = BoordsFrameSizeHelper(aspectRatio);
      const container = ref.current!.getBoundingClientRect();

      if (container.height === 0) {
        errorHandler({ messageKey: null, severity: 'warn' })(
          new Error(
            'Element height is 0, this might lead to unexpected results',
          ),
        );
      }

      const { width, height, x, y } = fitLib(
        { ...imageSize, x: 0, y: 0 },
        { width: container.width, height: container.height, x: 0, y: 0 },
        {
          apply: false,
          hAlign: fitLib.CENTER,
          vAlign: fitLib.CENTER,
        },
      );

      const output = { width, height, top: y, left: x };
      return output;
    },
    [],
  );

  /** On first render, calculate the size */
  React.useLayoutEffect(() => {
    if (!ref.current) return;
    const { width, height, top, left } = determineSize(frameAspectRatio);
    const newChildSizes = {
      width: width - borderAdjustments,
      height: height - borderAdjustments,
    };
    setStyle({ width, height, top, left });
    setChildSizes(newChildSizes);
    onResize?.(newChildSizes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    if (!ref.current) return;
    const observeTarget = ref.current;

    const callback = throttle(() => {
      if (!ref.current) return;
      const targetSize = determineSize(frameAspectRatio);
      const sizes = currentSizes.current;

      if (!sizes || !isEqual(sizes, targetSize)) {
        const newChildSizes = {
          width: targetSize.width - borderAdjustments,
          height: targetSize.height - borderAdjustments,
        };
        setStyle({
          width: targetSize.width,
          height: targetSize.height,
          position: 'absolute',
          top: targetSize.top,
          left: targetSize.left,
        });
        setChildSizes(newChildSizes);
        // Components might be waiting on the initial size event to happen
        onResize?.(newChildSizes);
      }
    }, throttleAmount);

    observer.current = new ResizeObserver(callback);
    observer.current.observe(observeTarget);
    return () => {
      callback.cancel();
      observer.current?.disconnect();
      observer.current?.unobserve(observeTarget);
    };
  }, [
    borderAdjustments,
    currentSizes,
    determineSize,
    frameAspectRatio,
    onResize,
    setChildSizes,
    throttleAmount,
  ]);

  return (
    <div className="w-full h-full relative" ref={ref}>
      <div
        className={classNames(
          'absolute',
          props.border && 'border border-border-image',
          props.className,
        )}
        style={style ?? initialStyles}
      >
        {childSizes
          ? isFunction(props.children)
            ? props.children(childSizes)
            : props.children
          : null}
      </div>
    </div>
  );
});

AutoSizedFrame.displayName = 'AutoSizedFrame';
