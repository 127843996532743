/** @format */
import Button, { type ButtonProps } from 'blackbird/components/button/Button';
import classNames from 'classnames';
import * as React from 'react';
import { omit } from 'underscore';
import { NativeTypes } from 'react-dnd-html5-backend';
import { PopTransition } from 'blackbird/components/common/Transitions';

export const BoordsDropzoneContext =
  React.createContext<HTMLInputElement | null>(null);

export interface BoordsDropZoneProps
  extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  error?: string;
  accept: string[] | null;
  /** is the user dragging a file over the dropzone */
  isOver: boolean;
  /** indicate to the user that the item being dragged will not be valid */
  isInvalid?: boolean;
  border?: boolean;
  /** can the user open a file dialog by clicking the dropzone itself */
  acceptClick?: boolean;
  onFileUpload?: (files: File[]) => void;
  hasItem: boolean;
  multiple: boolean;
  children?: React.ReactNode;
  /** Adds basis styles like padding and centering */
  box?: boolean;
  rounded?: boolean;
  /** should the element have a background? */
  bg?: boolean;
  showInvalidDropMessage?: boolean;
  showInvalidDropOverlay?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
}

/** some default styles and abstractions */
export const BoordsDropzone = React.forwardRef<
  HTMLDivElement,
  BoordsDropZoneProps
>(
  (
    {
      border = true,
      box = true,
      rounded = true,
      bg = true,
      showInvalidDropOverlay = true,
      ...props
    },
    ref,
  ) => {
    const [fileRef, setFileRef] = React.useState<HTMLInputElement | null>(null);
    const acceptsFiles =
      props.accept &&
      (props.accept.includes(NativeTypes.FILE) ||
        props.accept.some((i) => i.indexOf('/') > 0));

    const acceptClick = props.acceptClick ?? acceptsFiles;

    if (acceptClick && acceptsFiles && !props.onFileUpload) {
      throw new Error(
        "this dropzone accepts files, but doesn't have an onFileUpload prop set",
      );
    }
    if (acceptClick && props.onClick) {
      throw new Error(
        `props.onClick was defined, but acceptClick is true, this is conflicting behaviour`,
      );
    }

    return (
      <BoordsDropzoneContext.Provider value={fileRef}>
        <div
          // prettier-ignore
          {...omit(props, ['isInvalid', 'hasItem', 'isOver', 'acceptClick', 'showInvalidDropMessage', 'onFileUpload','accept','showInvalidDropOverlay'])}
          className={classNames(
            props.className,
            'transition-opacity select-none',
            box && 'p-4 justify-center items-center flex',
            bg && 'bg-surface-frame',
            rounded && 'rounded-lg',
            props.isOver &&
              props.isInvalid &&
              showInvalidDropOverlay &&
              'relative',
            props.isOver
              ? props.isInvalid
                ? 'outline-dashed outline-2 outline-action-destructive'
                : 'text-brand-pink ring-2 ring-brand-pink'
              : border
              ? acceptClick
                ? 'hover:ring-border-image'
                : 'ring-1 ring-border-mid'
              : undefined,
            props.disabled ? 'opacity-40' : undefined,
            props.error ? '!text-form-error' : 'border-border',
            (acceptClick || props.onClick) && 'cursor-pointer',
          )}
          onClick={
            acceptClick
              ? () => {
                  fileRef?.click();
                }
              : props.onClick
          }
          style={props.style}
          ref={ref}
        >
          {props.children}
          {acceptsFiles && (
            <input
              ref={(r) => setFileRef(r)}
              onChange={
                props.onFileUpload
                  ? (f) =>
                      f.target.files
                        ? props.onFileUpload!(Array.from(f.target.files))
                        : undefined
                  : undefined
              }
              type="file"
              multiple={props.multiple}
              accept={props.accept?.join(',')}
              hidden
            />
          )}

          {props.isInvalid && (
            <div
              className={classNames(
                'pointer-events-none absolute inset-0 z-20 flex justify-center items-end p-4',
                showInvalidDropOverlay && props.isOver && 'bg-brand-pink/20',
              )}
            >
              <PopTransition
                className="rounded-md bg-black p-2 z-20 text-white"
                show={props.showInvalidDropMessage ?? false}
                appear
              >
                This file can't be dropped here
              </PopTransition>
            </div>
          )}
        </div>
      </BoordsDropzoneContext.Provider>
    );
  },
);

BoordsDropzone.displayName = 'BoordsDropzone';

export const BoordsDropzoneUploadButton: React.FC<
  Omit<Partial<ButtonProps>, 'onClick'>
> = (props) => {
  const context = React.useContext(BoordsDropzoneContext);

  return (
    <Button type="outline" onClick={context?.click} {...props}>
      {props.children ?? 'Pick a file'}
    </Button>
  );
};
