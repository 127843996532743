/** @format */
import React from 'react';
import classNames from 'classnames';
import { FrameActions } from 'javascripts/flux/actions/frame';
import {
  FrameStatus,
  FRAME_COLORS,
} from 'blackbird/components/menus/constants';
import type { DetailedFrame } from 'javascripts/types/frame';

interface StatusInStoryboardPanelProps {
  currentFrame: DetailedFrame;
}

export const StatusInStoryboardPanel: React.FC<
  StatusInStoryboardPanelProps
> = ({ currentFrame }) => {
  const allStatuses = Object.values(FrameStatus);

  const handleStatusClick = (status: FrameStatus) => {
    FrameActions.setFramesStatus({
      status,
      frames: [currentFrame],
    });
    Track.event.defer('frame_status_change');
  };

  return (
    <div className="p-2 space-y-2">
      {allStatuses.map((status) => (
        <div
          key={status}
          onClick={() => handleStatusClick(status)}
          className="flex items-center px-2 py-2 cursor-pointer rounded-md space-x-3 hover:bg-surface-light"
        >
          <div
            className={classNames(
              'border border-icon-subdued w-3 h-3 rounded-full',
              FRAME_COLORS[status],
            )}
          />
          <span className="text-sm pb-0.5">{status}</span>
        </div>
      ))}
    </div>
  );
};
