/**
 * @prettier
 */
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const { getFrameImage } = require('../../../helpers/getFrameImage');
const navigateToRoute = require('../../../helpers/router/navigate-to-route');
const Img = require('../../shared/Img').default;
const FrameImageDropZone = require('./FrameImageDropZone').default;
const {
  ImageSourcesMultiPanelDefaultWidth,
  ImageSourcesMultiPanel,
} = require('../AddImageMultipanel/ImageSourcesMultiPanel');
const { default: Button } = require('blackbird/components/button/Button');
const {
  default: Popover,
} = require('blackbird/components/common/popover/Popover');
const { Watermark } = require('blackbird/components/watermark/Watermark');
const { FrameButtonContent } = require('./FrameButtonContent');
const {
  getErrorFrameImageURL,
} = require('javascripts/helpers/getMissingFrameImageURL');

import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import { GeneratorButton } from 'blackbird/components/generator/GeneratorButton';
import { TourHintable } from 'javascripts/components/tours/TourHintable';
import {
  imageEditorSidebarModeLocalState,
  panelbarFocusLocalState,
} from 'javascripts/helpers/local-state';
import {
  EllipsisVertical,
  Fullscreen,
  MessageCirclePlus,
  MessageSquare,
  Plus,
  Sparkles,
} from 'lucide-react';
import { FrameCommentCount } from '../FrameCommentCount.react';

const { default: classNames } = require('classnames');

window.FrameImage = createReactClass({
  propTypes: {
    frame: PropTypes.object.isRequired,
    isMinimal: PropTypes.bool,
    commentCount: PropTypes.number,
    frameIsOutsideFrameLimit: PropTypes.bool,
    storyboard: PropTypes.object.isRequired,
    gridSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  },

  getInitialState() {
    return {
      isOpen: false,
      hasDrop: false,
      showTour: false,
    };
  },

  _lastClick: null,

  componentWillUnmount() {
    clearTimeout(this.timeout);
  },

  componentDidMount() {
    if (
      ToursStore.state.currentSteps.length > 0 &&
      ToursStore.state.currentSteps[0].name === 'generatorOpen' &&
      this.props.frame.sort_order === 1
    ) {
      this._showButtons();
      this.setState({ showTour: true });
    }
  },

  timeout: null,
  lastClickTime: null,

  /**
   * Manually track click/doubleclick actions, because the native doubleclick
   * event does not fire on touch devices and there's no dblTap or something…
   */
  handleClick(e) {
    if (this.lastClickTime && e.timeStamp - this.lastClickTime < 300) {
      if (this.props.frameIsOutsideFrameLimit) return;
      e.preventDefault();
      navigateToRoute('frameFocus', {
        slug: this.props.storyboard.slug,
        frameIndex: this.props.frame.sort_order,
      });
    }

    // Even if we detect a double click, we still want to execute the single
    // click stuff again, because the first click might have deselected
    if (window.BoordsConfig.TeamRole !== 'guest') {
      e.preventDefault();

      if (e.shiftKey) {
        FrameActions.selectMultiple(this.props.frame.id);
      } else if (e.currentTarget.contains(e.target)) {
        FrameActions.selectFrame(this.props.frame.id);
      }

      CommentActions.closeFrameCommentBox.defer();
    }

    this.lastClickTime = e.timeStamp;
  },

  _comment(e) {
    e.stopPropagation();
    e.preventDefault();
    CommentActions.toggleFrameCommentBox.defer({
      frame: this.props.frame,
    });
  },

  _editWithTab(e, tab) {
    panelbarFocusLocalState.setValue('images');
    imageEditorSidebarModeLocalState.setValue(tab);
    this._edit(e);
  },

  _commentFullscreen(e) {
    panelbarFocusLocalState.setValue('frameInfo');
    this._edit(e);
  },

  _edit(e) {
    e.stopPropagation();
    e.preventDefault();
    navigateToRoute('frameEditor', {
      slug: this.props.storyboard.slug,
      frameIndex: this.props.frame.sort_order,
    });
  },

  _showButtons() {
    this.timeout = setTimeout(
      function () {
        if (this.state.hasDrop) return;
        this.setState({ isOpen: true });
      }.bind(this),
      150,
    );
  },

  _hideButtons() {
    clearTimeout(this.timeout);
    this.setState({ isOpen: false });
  },
  stopPropagation: function (e) {
    e.stopPropagation();
  },
  handleDropParamsChange({ isOver, canDrop }) {
    this.setState({
      hasDrop: isOver && canDrop,
    });
  },
  render: function () {
    const aspectRatio = this.props.storyboard.frame_aspect_ratio;
    const thumbnail = getFrameImage(
      this.props.frame.thumbnail_image_url,
      aspectRatio,
    );

    const hasAIEnabled = BoordsConfig.ShowGeneratorUI;

    const { height, width } = BoordsFrameSizeHelper(aspectRatio);
    const hintableSteps = ['generatorOpen'];

    const spacingClass = [`xs`, `sm`].includes(this.props.gridSize)
      ? 'gap-1'
      : 'gap-1.5';

    const roundingClass =
      this.props.gridSize === 'xs'
        ? 'rounded-sm'
        : this.props.gridSize === 'sm'
        ? 'rounded-sm'
        : 'rounded-md';

    return (
      <div
        className={classNames('relative pointer frameSortableHandle')}
        onMouseEnter={this._showButtons}
        onMouseLeave={this._hideButtons}
        onClick={this.handleClick}
      >
        {this.state.isOpen && !this.props.simpleNewFrameButton ? (
          <div className="absolute inset-0 z-10">
            <div className="absolute inset-0 ">
              <div
                className={classNames(
                  'absolute inset-0 z-10 bg-black/25',
                  roundingClass,
                )}
              />
              <div
                className={classNames(
                  'absolute z-20 flex top-2 left-2',
                  this.props.gridSize === 'xs'
                    ? 'flex-col items-start'
                    : 'items-center',
                  spacingClass,
                )}
              >
                {[`lite`].includes(BoordsConfig.StoryboardTeamPlanSlug) ? (
                  <Tooltip
                    placement="bottom-start"
                    title={`Please upgrade to enable commenting`}
                  >
                    <div
                      className="flex items-center overflow-hidden cursor-pointer bg-black/80 rounded-md "
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        FlyoverActions.open.defer({
                          type: 'inlinePricing',
                        });
                      }}
                    >
                      <div className="border-r border-white/10">
                        <FrameCommentCount
                          count={this.props.commentCount}
                          allowZero
                          className="rounded-r-none !bg-transparent !pr-2 opacity-50"
                        />
                      </div>
                      <div className="flex items-center justify-center pl-1.5 pr-2 text-white opacity-50 h-7 rounded-r-md ring-none">
                        <Plus className="w-3 h-3" />
                      </div>
                    </div>
                  </Tooltip>
                ) : this.props.storyboard.has_comments_enabled ? (
                  <div className="flex items-center overflow-hidden bg-black/80 rounded-md">
                    <Tooltip
                      placement="bottom-start"
                      title={`Open comment view`}
                    >
                      <div
                        className="border-r cursor-pointer border-white/10"
                        onClick={this._commentFullscreen}
                      >
                        <FrameCommentCount
                          count={this.props.commentCount}
                          allowZero
                          className="rounded-r-none !bg-transparent !pr-2"
                        />
                      </div>
                    </Tooltip>
                    <Tooltip placement="bottom-start" title={`Quick comment`}>
                      <div
                        onClick={this._comment}
                        className="flex items-center justify-center pl-1.5 pr-2 text-white opacity-50 cursor-pointer h-7 rounded-r-md ring-none hover:opacity-100"
                      >
                        <Plus className="w-3 h-3" />
                      </div>
                    </Tooltip>
                  </div>
                ) : null}
              </div>
              <div
                className={classNames(
                  'absolute top-2 right-2 z-20 flex items-center',
                  spacingClass,
                )}
              >
                {hasAIEnabled && (
                  <TourHintable
                    step={hintableSteps}
                    canShow={this.state.showTour}
                    overlayPosition="bottom"
                  >
                    <GeneratorButton
                      storyboard={this.props.storyboard}
                      frame={this.props.frame}
                      disabled={this.props.frameIsOutsideFrameLimit}
                    >
                      <FrameButtonContent
                        buttonClassName={`bg-gradient-to-r from-brand-pink to-brand-yellow`}
                        disabled={this.props.frameIsOutsideFrameLimit}
                        placement="bottom-start"
                        text={`Quick generate`}
                        icon={Sparkles}
                      />
                    </GeneratorButton>
                  </TourHintable>
                )}

                <div
                  disabled={this.props.frameIsOutsideFrameLimit}
                  onClick={(e) => this._editWithTab(e, 'ai')}
                >
                  <FrameButtonContent
                    disabled={this.props.frameIsOutsideFrameLimit}
                    text={`Launch editor`}
                    icon={Fullscreen}
                  />
                </div>

                {/* Need to capture event on this div and stop from propagating
                any further as there is no way to override HeadlessUI's
                onClick method and stop propagation to parent element*/}
                <div onClick={this.stopPropagation}>
                  <Popover
                    portal
                    placement="right-start"
                    distance={7}
                    offset={-8}
                  >
                    <Popover.Button as="div">
                      <FrameButtonContent
                        disabled={this.props.frameIsOutsideFrameLimit}
                        icon={EllipsisVertical}
                      />
                    </Popover.Button>
                    <Popover.Panel
                      className="z-40"
                      onMouseEnter={this.stopPropagation}
                      onMouseLeave={this.stopPropagation}
                    >
                      <ImageSourcesMultiPanel
                        width={ImageSourcesMultiPanelDefaultWidth}
                        storyboard={this.props.storyboard}
                        currentFrame={this.props.frame}
                        replace
                      />
                    </Popover.Panel>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <Watermark>
          <Img
            className={roundingClass}
            src={thumbnail}
            height={height}
            width={width}
            placeholder={getErrorFrameImageURL(aspectRatio)}
            log
          />
        </Watermark>

        <FrameImageDropZone
          frame={this.props.frame}
          team_id={this.props.storyboard.project.owner.id}
          onDropParamsChange={this.handleDropParamsChange}
          className="z-[2]"
        />
      </div>
    );
  },
});
