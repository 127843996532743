/** @prettier */
import * as React from 'react';
import { type IStoryboard } from '../../types/storyboard';
import { type IFrame } from '../../types/frame';
import { FramePanelBarNavigator } from '../shared/FramePanelBarNavigator';
import { FramePanelBar } from '../shared/FramePanelBar';
import { usePersistedState } from '../../helpers/usePersistedState';
import { PanelbarImageLibrary } from './PanelbarImageLibrary';
import ImageIcon from 'blackbird/images/icons/image-sm.svg';
import CommentIcon from 'blackbird/images/icons/comment-sm.svg';
import { eventSource } from 'blackbird/helpers/eventContextHelper';
import classNames from 'classnames';
import { type IconTabItem, IconTabBar } from '../shared/IconTabBar';

const LS_KEY = 'panelbarFocusState';

export type panelView = 'images' | 'frameInfo';

export const FrameFocusPanelbar: React.FC<{
  storyboard: IStoryboard;
  frames: IFrame[];
  restrictWidth?: boolean;
  canManage?: boolean;
  activeIndex: number;
  scroll?: boolean;
  showFrameStatus?: boolean;
  focusType: 'player' | 'frameEditor';
  onSetFrameIndex: (newIndex: number) => void;
}> = (props) => {
  const [panel, setPanel] = usePersistedState<panelView>(LS_KEY, 'images');

  const handleSetPanelType = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setPanel(e.currentTarget.name as any);
    },
    [setPanel],
  );

  const newVersion = true;

  React.useEffect(() => {
    if (panel === 'frameInfo') {
      Track.event.defer(`panelbar_open`, {
        panelType: 'frameInfo',
        context: eventSource(undefined),
        category: 'Product',
      });
    }
  }, [panel]);

  React.useLayoutEffect(() => {
    document.documentElement.classList.remove('overflow-y-scroll');
    return () => {
      document.documentElement.classList.add('overflow-y-scroll');
    };
  }, []);

  const IconTabs: IconTabItem[] = [
    {
      icon: <ImageIcon />,
      name: 'images',
      title: 'Show Frame Builder',
      active: panel === 'images',
      onClick: handleSetPanelType,
    },
    {
      icon: <CommentIcon className="w-4 h-4" />,
      name: 'frameInfo',
      title: 'Show Comments',
      iconClassName: '!w-[1.2rem]',
      active: panel === 'frameInfo',
      onClick: handleSetPanelType,
    },
  ];

  interface IFrameTextProps {
    hideComments?: boolean;
  }

  const FrameFieldsAndComments: React.FC<IFrameTextProps> = ({
    hideComments,
  }) => (
    <FramePanelBar
      storyboard={props.storyboard}
      frames={props.frames}
      activeIndex={props.activeIndex}
      canManage={props.canManage}
      showFrameStatus={props.showFrameStatus}
      hideComments={hideComments}
      restrictWidth
    />
  );

  return (
    <div className="flex flex-col flex-auto overflow-y-scroll bottom-banner-spacing">
      <div className="sticky top-0 z-50 p-6 pr-4 bg-white border-b border-border">
        <FramePanelBarNavigator
          frames={props.frames}
          onSetIndex={props.onSetFrameIndex}
          activeIndex={props.activeIndex}
          showFrameStatus={props.showFrameStatus ?? false}
        >
          {props.focusType !== 'player' && (
            <div className="flex items-center justify-end flex-auto ">
              <IconTabBar icons={IconTabs} />
            </div>
          )}
        </FramePanelBarNavigator>
      </div>

      <div className="flex flex-col flex-auto pt-8">
        {panel === 'frameInfo' || props.focusType === 'player' ? (
          <FrameFieldsAndComments />
        ) : (
          <div
            className={classNames(
              'flex flex-col flex-auto',
              newVersion ? ' -mt-8' : '-mt-3',
            )}
          >
            {/* PanelbarImageLibrary contains tab which has p-3 padding.
             Therefore, adding negative margin to offset extra space here. */}
            <PanelbarImageLibrary
              frameInformation={<FrameFieldsAndComments hideComments />}
              frames={props.frames}
              newVersion={newVersion}
              storyboard={props.storyboard}
              hasFrameFocus={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};
