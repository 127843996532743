/** @prettier */

import type { FC } from 'react';
import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import type * as PopperJS from '@popperjs/core';
import { usePopper } from 'react-popper';
import maxSize from 'popper-max-size-modifier';
import type { ModifierArguments } from '@popperjs/core';
import { Popover as HeadlessPopover } from '@headlessui/react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import useBoolean from 'blackbird/helpers/hooks/useBoolean';
import { InsideDialogContext } from 'blackbird/components/dialog/Dialog';
import { FlyoverContext } from 'javascripts/components/flyover/flyoverContext';
import { popperObserveMutationsModifier } from 'javascripts/helpers/popperObserveMutations';
import { SuspenseLoader } from 'javascripts/components/shared/SuspenseLoader';
import { ErrorBoundary } from 'javascripts/components/shared/ErrorBoundary';

type PopoverProps = {
  className?: string;
  portal?: boolean;
  placement?: PopperJS.Placement;
  offset?: number;
  maxHeight?: number;
  distance?: number;
  btnRef?: (ref) => any;
  Button?: React.ReactElement;
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  onFirstOpen?: () => void;
  toggleOnHover?: boolean;
  disableMaxHeightModifier?: boolean;
  observeMutations?: boolean;
};
type PopoverContextProps = {
  isOpen?: boolean;
  setPopperElement: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  popperElement: HTMLDivElement | null;
  /**
   * styles generated by usePopper
   */
  styles: Record<string, React.CSSProperties>;
  /**
   * attributes generated by usePopper (data-popover-placement etc.)
   */
  attributes: Record<string, Record<string, string> | undefined>;
  setParentRef: React.Dispatch<
    React.SetStateAction<HTMLElement | undefined | null>
  >;
  /**
   * Render dropdown relative to the body
   */
  portal?: boolean;
  update: null | (() => Promise<any>);
};
type ButtonProps = Parameters<typeof HeadlessPopover['Button']>[0];
type PopoverRenderProps = {
  setParentRef?: React.Dispatch<
    React.SetStateAction<HTMLElement | null | undefined>
  >;
};
type ChildPropsPopover = {
  children?: React.ReactNode | ((props: PopoverRenderProps) => React.ReactNode);
};

export const PopoverContext = React.createContext<PopoverContextProps | null>(
  null,
);
const Popover: FC<PopoverProps> & {
  Button: React.FC<ButtonProps & ChildPropsPopover>;
  Panel: typeof PopoverPanel;
} = (props) => {
  const {
    children,
    placement,
    offset = 0,
    maxHeight,
    distance = 0,
    isOpen,
    onClose,
    onOpen,
    onFirstOpen,
    className,
    portal,
    toggleOnHover,
    disableMaxHeightModifier,
  } = props;
  const firstOpen = useRef(true);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [parentRef, setParentRef] = useState<HTMLElement>();
  // This needs to me memoized, or there will be infinite rerenders
  const applyMaxSize = React.useCallback(
    ({ state }: ModifierArguments<Record<string, unknown>>) => {
      // The `maxSize` modifier provides this data
      const maxSize = state.modifiersData.maxSize;
      const height = Math.min(maxSize.height, maxHeight ?? Infinity);

      state.styles.popper.maxHeight = `${Math.max(200, height)}px`;
    },
    [maxHeight],
  );

  const { styles, attributes, update } = usePopper(parentRef, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [offset, distance],
        },
      },
      { name: 'flip', enabled: true },
      {
        ...maxSize,
        options: {
          padding: 20,
        },
      },
      {
        name: 'applyMaxSize',
        enabled: !disableMaxHeightModifier,
        phase: 'beforeWrite',
        requires: ['maxSize'],
        fn: applyMaxSize,
      },
      {
        ...popperObserveMutationsModifier,
        enabled: props.observeMutations ?? false,
      },
    ],
  });

  const [isHovered, setHover] = useBoolean(false);

  const contextValue: PopoverContextProps = {
    isOpen: toggleOnHover ? isHovered : props.isOpen,
    setPopperElement,
    attributes,
    styles,
    setParentRef,
    portal: portal,
    popperElement,
    update,
  };

  useEffect(() => {
    if (parentRef && toggleOnHover) {
      parentRef.addEventListener('mouseenter', setHover.on);
      parentRef.addEventListener('mouseleave', setHover.off);
      parentRef.addEventListener('click', setHover.off);
    }

    return () => {
      if (parentRef && toggleOnHover) {
        parentRef.removeEventListener('mouseenter', setHover.on);
        parentRef.removeEventListener('mouseleave', setHover.off);
        parentRef.removeEventListener('click', setHover.off);
      }
    };
  }, [parentRef, setHover, toggleOnHover]);

  const handleOpen = () => {
    if (firstOpen.current) {
      onFirstOpen?.();
      firstOpen.current = false;
    }
    onOpen?.();
  };

  return (
    <PopoverContext.Provider value={contextValue}>
      <HeadlessPopover className={classNames(className)}>
        {({ open, close }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            //Uncontrolled
            if (isOpen === undefined) {
              open ? handleOpen() : onClose?.();
              return;
            }

            if (isOpen) {
              //Controlled component
              open ? handleOpen() : onClose?.();
            } else {
              close();
            }
          }, [open, close]);
          return (
            <>
              {React.Children.map(children, (child: JSX.Element) => {
                if (!child) return;
                if (
                  // The `name` attribute of these children is only available
                  // in development. These values would always return false on
                  // production due to minification, causing this map to always
                  // return null. It's fine to not test for this in production.
                  process.env.NODE_ENV !== 'development' ||
                  child.type?.name === 'PopoverPanel' ||
                  child.type?.name === 'PopoverButton'
                ) {
                  return child;
                }
                return null;
              })}
            </>
          );
        }}
      </HeadlessPopover>
    </PopoverContext.Provider>
  );
};

const PopoverButton = (props: ButtonProps & ChildPropsPopover) => {
  const contextValue = useContext(PopoverContext);
  const { children, className, as = 'div', ...rest } = props;

  const hasDisplayClasses = useMemo(
    () =>
      ['flex', 'block', 'grid', 'inline-block'].some((item) =>
        className?.includes(item),
      ),
    [],
  );
  if (contextValue === null) return <></>;
  const { setParentRef } = contextValue;
  const refProps =
    typeof children !== 'function'
      ? {
          ref: setParentRef,
        }
      : {};
  return (
    <HeadlessPopover.Button
      className={classNames({ 'inline-flex': !hasDisplayClasses }, className)}
      as={as}
      {...refProps}
      {...rest}
    >
      {typeof children === 'function' ? children({ setParentRef }) : children}
    </HeadlessPopover.Button>
  );
};

const PopoverPanel: React.FC<
  React.HTMLAttributes<unknown> & { className?: string; static?: boolean }
> = (props) => {
  const contextValue = useContext(PopoverContext);
  const insideDialog = useContext(InsideDialogContext);
  const flyoverRef = useContext(FlyoverContext);

  if (contextValue === null) return <></>;
  const {
    attributes,
    styles,
    setPopperElement,
    isOpen,
    portal,
    popperElement,
  } = contextValue;
  const { children, className, style: panelStyles, onClick, ...rest } = props;
  const allStyles = { ...panelStyles, ...styles.popper };
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClick?.(e);
  };
  const Panel = (
    <HeadlessPopover.Panel
      {...rest}
      ref={setPopperElement}
      style={allStyles}
      {...attributes.popper}
      className={classNames(insideDialog ? 'z-modalChild' : 'z-10', className)}
      static={isOpen}
      onClick={handleClick}
    >
      <ErrorBoundary size="small">
        <SuspenseLoader>
          {typeof children === 'function'
            ? children({ styles, attributes, popperElement })
            : children}
        </SuspenseLoader>
      </ErrorBoundary>
    </HeadlessPopover.Panel>
  );

  return (
    <>
      {portal
        ? ReactDOM.createPortal(
            Panel,
            insideDialog
              ? document.querySelector('#headlessui-portal-root')!
              : flyoverRef?.current && !insideDialog
              ? flyoverRef?.current
              : document.querySelector('body')!,
          )
        : Panel}
    </>
  );
};

export default Popover;

Popover.Button = PopoverButton;
Popover.Panel = PopoverPanel;
