/** @prettier */
import { FilestackStore } from '../stores/filestack';
import { createTypedActions } from '../stores/AltStore';

class FilestackActions {
  /**
   * @deprecated See openDialog in the FileStackStore to read why
   */
  openDialog: FilestackStore['handleOpenDialog'] = function (options) {
    this.dispatch(options);
  };
  openCropUI: FilestackStore['handleCropUI'] = function (options) {
    this.dispatch(options);
  };
  openCommentAttachment: FilestackStore['handleOpenCommentAttachment'] =
    function (options) {
      this.dispatch(options);
    };
  openPDF: FilestackStore['handleOpenPDF'] = function (options) {
    this.dispatch(options);
  };
  openPdfFooterLogo: FilestackStore['handleOpenPdfFooterLogo'] = function (
    options,
  ) {
    this.dispatch(options);
  };
  openPdfCoverLogo: FilestackStore['handleOpenPdfCoverLogo'] = function (
    options,
  ) {
    this.dispatch(options);
  };
  openAvatar: FilestackStore['handleOpenAvatar'] = function (options) {
    this.dispatch(options);
  };
  openEditorDialog: FilestackStore['handleOpenEditorDialog'] = function (
    options,
  ) {
    this.dispatch(options);
  };
  openWithFile: FilestackStore['handleOpenWithFile'] = function (options) {
    this.dispatch(options);
  };
  openPicker: FilestackStore['handleOpenPicker'] = function (options) {
    this.dispatch(options);
  };
  addResponseAsFrames: FilestackStore['handleAddResponseAsFrames'] = function (
    options,
  ) {
    this.dispatch(options);
  };
  uploadFile: FilestackStore['handleUploadFile'] = function (options) {
    this.dispatch(options);
  };
  uploadFrameFile: FilestackStore['handleUploadFrameFile'] = function (
    options,
  ) {
    this.dispatch(options);
  };
  storeURL: FilestackStore['handleStoreURL'] = function (options) {
    this.dispatch(options);
  };
}
const actions = createTypedActions<FilestackActions>(FilestackActions);
export { actions as FilestackActions };

(window as any).FilestackActions = actions;
