/** @format */
import { ButtonProps } from 'blackbird/components/button/Button';
import Dialog from 'blackbird/components/dialog/Dialog';
import { DialogContext } from 'blackbird/components/dialog/DialogContext';
import type { DetailedFrame } from 'javascripts/types/frame';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SuspenseLoader } from '../shared/SuspenseLoader';

const VideoImportDropZone = React.lazy(() => import('./videoImportDropZone'));

export const VideoImportFlyover: React.FC<{
  frame: DetailedFrame;
  props: { replace: boolean };
}> = (props) => {
  const dialogContext = React.useContext(DialogContext);
  const { t } = useTranslation();

  const handleClose = () => {
    FlyoverActions.close.defer();
  };

  const cancelBtnProps: ButtonProps = {
    type: 'secondary',
  };

  return (
    <Dialog
      title={t('videoImport.title')}
      subtitle={t('videoImport.subtitle')}
      confirmText={t('videoImport.buttons.trim')}
      cancelBtnProps={cancelBtnProps}
      wrapperClasses="!pb-0"
      size="sm"
      {...dialogContext.dialogState}
      onClose={handleClose}
      hideActionDivider
      isDark
      isOpen
    >
      <SuspenseLoader
        wrapperClassName="min-h-[50svh] flex items-center justify-center"
        pad
        fill={false}
      >
        <VideoImportDropZone
          targetFrame={props.frame}
          replace={props.props.replace}
        />
      </SuspenseLoader>
    </Dialog>
  );
};
