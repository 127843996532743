import classNames from 'classnames';

const { Transition } = require('@headlessui/react');
const { default: Toast } = require('blackbird/components/feedback/toast/Toast');
const createReactClass = require('create-react-class')
window.Alert = createReactClass({

  getInitialState: function() {
    return {
      isActive: false
    };
  },

  getDefaultProps() {
    return {
      text: "Testing",
      type: "error",
    };
  },

  componentDidMount: function() {

    setTimeout(function() {
      this.setState({
        isActive: true
      })
    }.bind(this), 10);

    this.timeout = setTimeout(function () {
      this._removeAlert();
    }.bind(this), 5000);
  },

  componentDidUpdate: function() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      this._removeAlert();
    }.bind(this), 5000);
  },

  _removeAlert: function() {
    clearTimeout(this.timeout);
    // this.setState({
    //   isActive: false
    // });
    setTimeout(function() {
      RequestActions.complete();
    }, 500);
  },

  componentWillUnmount: function() {
    this._removeAlert();
  },

  _onClick: function(e) {
    e.preventDefault();
    this._removeAlert();
  },

  render: function() {
    const kind = this.props.type === "error" ? "error" : "success";
    return(
      <Transition enterTo='transform translate-y-0' enterFrom="transform translate-y-20" enter="ease-in-out duration-500 transition" show={this.state.isActive} className="bottom-5 right-5">
        <div className={classNames('px-4 pt-3 pb-2.5 cursor-pointer bg-white shadow-md rounded-lg w-60 relative overflow-hidden')}
          onClick={this._onClick}
        >
          <div className='absolute top-0 left-0 right-0 h-2'>
            <div className='w-full h-2 bg-type-green'/>
          </div>
          <span className='text-sm'>{this.props.text}</span>
          <>{this.props.children}</>
        </div>
      </Transition>
    );
  }
});

